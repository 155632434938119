<template>
    <OModal ref="modal" :title="$t('auth.confirm-password')">
        <p>{{ $t('auth.confirm-password-text') }}</p>
        <OForm id="confirmPasswordForm" @submit="confirmPassword()">
            <OAlert v-if="failed" type="danger">
                <strong>{{ $t('auth.confirm-password-failed') }}</strong><br>
                {{ $t('auth.confirm-password-failed-text') }}
            </OAlert>

            <OInput 
                :placeholder="$t('user.password')" 
                name="current-password" 
                v-model="password" 
                type="password" 
                autocomplete="current-password" 
                autofocus
                />
        </OForm>

        <template #footer>
            <OButton 
                type="primary" 
                form="confirmPasswordForm"
                submit 
                icon="check"
                :disabled="password.length === 0">{{ $t('auth.confirm-password') }}</OButton>
        </template>
    </OModal>
</template>
<script>
export default {
    props: ['$t', 'app', 'validUntil'],

    data() {
        return {
            failed: false,
            password: '',
        }
    },

    mounted() {
        const timestampNow = Math.floor(new Date().getTime() / 1000)

        if (this.validUntil && this.validUntil > timestampNow) {
            this.$emit('confirm')
        }
    },

    methods: {
        confirmPassword() {
            this.failed = false

            this.app.$axios.$post('../user/confirm-password', { password: this.password })
                .then(() => {
                    this.$emit('confirm')
                })
                .catch(error => {
                    this.failed = true
                })
        },
    }
}
</script>