<template>
    <OModal
        :title="$t('bank_transaction.match-manually')"
        size="lg"
        icon="search"
        allow-overflow
        @confirm="() => $emit('confirm', updatedTransaction)"
        >
        <div class="d-flex flex-wrap flex-lg-nowrap gap-3">
            <dl>
                <dt>{{ $t('bank_transaction.booking_date') }}</dt>
                <dd class="text-nowrap">{{ $formatDate(transaction.booking_date) }}</dd>
            </dl>
            <dl>
                <dt>{{ $t('crud.description') }}</dt>
                <dd>{{ transaction.text }}</dd>
            </dl>
            <dl class="text-lg-right ml-lg-auto text-nowrap">
                <dt>{{ $t('bank_transaction.amount') }}</dt>
                <dd class="font-weight-semibold">{{ transaction.currency }} {{ $formatCurrency(transaction.amount) }}</dd>
            </dl>
        </div>

        <hr class="mx-n3">

        <div class="bg-ultralight p-3 m-n3">
            <OTabs
                :tabs="[
                    { name: 'invoice', label: $t('invoice.invoices'), icon: 'file-invoice-dollar', show: transaction.amount >= 0 },
                    { name: 'manual', label: $t('accounting.manual-entry'), icon: 'book', show: $org.settings.accountingEnabled && $org.accounts_count > 0 && bankAccount?.account },
                    { name: 'expense_payment', label: $t('expense.type-invoice'), icon: 'file-invoice-dollar', show: transaction.amount <= 0 },
                    { name: 'expense_receipt', label: $t('expense.type-receipt'), icon: 'receipt', show: transaction.amount <= 0 },
                    { name: 'customer_transaction', label: $t('customer_transaction.customer_transaction'), icon: 'users' },
                ]"
                >
                <template #invoice>
                    <CrudListData
                        resource="invoice"
                        :filters="{ status: 'pending' }"
                        v-slot="{ items: invoices }"
                        >
                        <OSearch 
                            :placeholder="$t('crud.search') + ' ...'"
                            :options="invoices.map(invoice => ({
                                label: `${invoice.number} / ${invoice.company?.name}`,
                                value: invoice.id,
                                invoice,
                            }))"
                            :value="updatedTransaction.matchable_id"
                            @input="event => {
                                updatedTransaction.matchable_id = event
                                updatedTransaction.matchable_type = 'Modules\\Salesforce\\Invoice'
                            }"
                            class="mb-0"
                            >
                            <template #default="{ option }">
                                <div class="d-flex gap-3">
                                    <div>{{ option.label }}</div>
                                    <div class="text-right ml-auto tabular-nums text-muted">
                                        {{ $org.country.currency_code }} {{ $formatCurrency(option.invoice.total_open) }}
                                    </div>
                                </div>
                            </template>
                        </OSearch>

                        <OAlert 
                            v-if="updatedTransaction.matchable_id"
                            type="warning" 
                            class="mt-2 mb-0"
                            >
                            <PipeThrough 
                                :value="invoices.find(invoice => invoice.id === updatedTransaction.matchable_id).total_open"
                                v-slot="{ value }"
                                >
                                <div v-if="transaction.amount > value">{{ $t('reconciliation.amount-larger') }}: {{ $org.country.currency_code }} {{ $formatCurrency(transaction.amount - value) }}</div>
                                <div v-else-if="transaction.amount < value">{{ $t('reconciliation.amount-lower') }}: {{ $org.country.currency_code }} {{ $formatCurrency(value - transaction.amount) }}</div>
                            </PipeThrough>
                        </OAlert>
                    </CrudListData>
                </template>
                <template #expense_payment>
                    <CrudListData
                        resource="expense_payment"
                        :filters="{ status: 'pending' }"
                        :include="['expense']"
                        v-slot="{ items: expensePayments }"
                        >
                        <OSearch 
                            :placeholder="$t('crud.search') + ' ...'"
                            :options="expensePayments.map(payment => ({
                                label: `${payment.expense?.number} / ${payment.expense?.name}`,
                                value: payment.id,
                                payment,
                            }))"
                            :value="updatedTransaction.matchable_id"
                            @input="event => {
                                updatedTransaction.matchable_id = event
                                updatedTransaction.matchable_type = 'App\\ExpensePayment'
                            }"
                            class="mb-0"
                            >
                            <template #default="{ option }">
                                <div class="d-flex gap-3">
                                    <div>
                                        <div>{{ option.label }}</div>
                                        <div class="text-muted">{{ $t(`expense.bank-payment-types.${option.payment.bank_payment_type}`) }}</div>
                                    </div>
                                    <div class="text-right ml-auto tabular-nums text-muted">
                                        {{ $org.country.currency_code }} {{ $formatCurrency(option.payment.amount) }}
                                    </div>
                                </div>
                            </template>
                        </OSearch>

                        <OAlert 
                            v-if="updatedTransaction.matchable_id"
                            type="warning" 
                            class="mt-2 mb-0"
                            >
                            <PipeThrough 
                                :value="expensePayments.find(payment => payment.id === updatedTransaction.matchable_id).amount"
                                v-slot="{ value }"
                                >
                                <div v-if="Math.abs(transaction.amount) > value">{{ $t('reconciliation.amount-larger') }}: {{ $org.country.currency_code }} {{ $formatCurrency(Math.abs(transaction.amount) - value) }}</div>
                                <div v-else>{{ $t('reconciliation.amount-lower') }}: {{ $org.country.currency_code }} {{ $formatCurrency(value - Math.abs(transaction.amount)) }}</div>
                            </PipeThrough>
                        </OAlert>
                    </CrudListData>
                </template>
                <template #expense_receipt>
                    <CrudListData
                        resource="expense"
                        :filters="{ type: 'receipt', total_range: Math.abs(transaction.amount) + ',' + Math.abs(transaction.amount) }"
                        v-slot="{ items: expenses }"
                        >
                        <OSearch 
                            :placeholder="$t('crud.search') + ' ...'"
                            :options="expenses.map(expense => ({
                                label: `${expense.number} / ${expense.name}`,
                                value: expense.id,
                                expense,
                            }))"
                            :value="updatedTransaction.matchable_id"
                            @input="event => {
                                updatedTransaction.matchable_id = event
                                updatedTransaction.matchable_type = 'Modules\\Base\\Expense'
                            }"
                            class="mb-0"
                            >
                            <template #default="{ option }">
                                <div class="d-flex gap-3">
                                    <div>
                                        <div>{{ option.label }}</div>
                                        <div class="text-muted">{{ option.expense.number }}</div>
                                    </div>
                                    <div class="text-right ml-auto tabular-nums text-muted">
                                        {{ $org.country.currency_code }} {{ $formatCurrency(option.expense.total_with_vat) }}
                                    </div>
                                </div>
                            </template>
                        </OSearch>
                    </CrudListData>
                </template>
                <template #manual>
                    <div class="row mb-n3">
                        <div class="col-6">
                            <AccountSelect 
                                :label="$t('account.account')"
                                v-model="updatedTransaction.account_id"
                                :type="transaction.amount >= 0 ? 'revenue' : 'expense'"
                                :hidden-type="['active', 'passive', 'revenue', 'expense']"
                                use-id
                                select-first
                                autofocus
                                />
                        </div>
                        <div class="col-6">
                            <VatSelect 
                                :label="$t('vat.vat')"
                                v-model="updatedTransaction.vat_rate_id"
                                />
                        </div>
                        <div class="col-12">
                            <OInput 
                                :label="$t('crud.description')"
                                v-model="updatedTransaction.text"
                                />
                        </div>
                    </div>
                </template>
                <template #customer_transaction>
                    <div class="row mb-n3">
                        <div class="col-6">
                            <CompanySelect 
                                :label="$t('company.company')"
                                :value="updatedTransaction.matchable_id"
                                @input="event => {
                                    updatedTransaction.matchable_id = event
                                    updatedTransaction.matchable_type = 'Modules\\Client\\Company'
                                }"
                                />
                        </div>
                        <div class="col-6">
                            <OInput 
                                :label="$t('crud.description')"
                                v-model="updatedTransaction.text"
                                />
                        </div>
                    </div>
                </template>
            </OTabs>
        </div>
    </OModal>
</template>
<script>
import CrudListData from '~/components/crud/list-data'
import AccountSelect from '~/components/account/select'
import VatSelect from '~/components/vat/select'
import CompanySelect from '~/components/company/select'
import PipeThrough from '~/components/util/pipe'

export default {
    components: {
        CrudListData,
        AccountSelect,
        CompanySelect,
        VatSelect,
        PipeThrough,
    },

    props: ['transaction', 'bankAccount', '$t'],

    data() {
        return {
            updatedTransaction: {
                matchable_type: this.transaction.matchable_type,
                matchable_id: this.transaction.matchable_id,
                text: this.transaction.name,
                vat_rate_id: null,
            }
        }
    }
}
</script>