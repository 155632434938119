import Vue from 'vue'
import Mousetrap from 'mousetrap'
import { track as analyticsTrack } from '~/plugins/analytics'

export default ({ store, query, route }) => {
    // Global Keybindings
    Mousetrap.bind(['command+g', 'ctrl+g'], () => {
        if (store.state.auth.loggedIn) {
            store.dispatch('search/toggleOverlay')
            return false
        }
    })

    Mousetrap.bind(['command+k', 'ctrl+k'], () => {
        if (store.state.auth.loggedIn) {
            store.dispatch('command/toggleOverlay')
            return false
        }
    })

    Mousetrap.bind(['option+s'], () => {
        store.dispatch('toggleSidebar')
    })

    Mousetrap.bind(['option'], () => document.querySelectorAll('.shortcut:not(.shortcut-show)').forEach(e => e.removeAttribute('hidden')), 'keydown')
    Mousetrap.bind(['option'], () => document.querySelectorAll('.shortcut:not(.shortcut-show)').forEach(e => e.setAttribute('hidden', true)), 'keyup')

    if (query.search || route.hash === '#search') {
        store.dispatch('search/toggleOverlay')
    }

    Vue.mixin({
        data() {
            return {
                keyboardBindings: []
            }
        },
    
        methods: {
            $bindKeyboard(config) {
                const keys = Object.keys(config || {})
                keys
                    .filter(key => !this.keyboardBindings.includes(key))
                    .forEach(key => {
                        this.keyboardBindings.push(key)
                        Mousetrap.bind(key, (e) => {
                            config[key](e)

                            analyticsTrack('Keyboard shortcut used', {
                                key,
                            })
                        })
                    })
            },
        },
    
        deactivated() {
            (this.keyboardBindings || []).forEach(key => {
                Mousetrap.unbind(key)
            })

            this.keyboardBindings = []
        },

        beforeDestroy() {
            (this.keyboardBindings || []).forEach(key => {
                Mousetrap.unbind(key)
            })

            this.keyboardBindings = []
        }
    })
}