<template>
    <div class="search">
        <div class="search-backdrop overlay-backdrop" @click="close()"></div>
        <OContainer narrow>
            <div class="search-container overlay-container">
                <input 
                    ref="searchInput" 
                    type="search" 
                    :placeholder="$t('command.do-anything') + ' ...'" 
                    class="search-input pl-5"
                    v-model="commandQuery"
                    @keydown.up.prevent="prev()"
                    @keydown.down.prevent="next()"
                    @keydown.shift.tab.prevent="prev()"
                    @keydown.exact.tab.prevent="next()"
                    @keyup.enter="enterHandler()"
                    @keyup.esc="close()"
                    autofocus
                    inputmode="search"
                    :disabled="loading"
                    >

                <OIcon 
                    :icon="['fal', 'terminal']" 
                    size="lg"
                    class="position-absolute text-muted"
                    style="top: 1rem; left: 1rem; z-index: 1;"
                />

                <OButton 
                    v-if="commandQuery.length > 0" 
                    icon="times"
                    @click="clearInput()" 
                    class="position-absolute right-0" style="z-index: 1; top: 0.5rem;">
                </OButton>

                <OLoading :loading="loading" class="d-block w-100"></OLoading>

                <div v-if="filteredCommands.length === 0 && noResults" class="p-3 text-muted">
                    {{ $t('search.no-results') }}
                </div>

                <div v-if="filteredCommands.length > 1" class="d-none text-muted d-xl-block px-3 pt-3 pb-1">
                    <span v-html="$t('search.navigate-through')"></span>
                </div>

                <div ref="results" v-if="filteredCommands.length > 0" class="search-results">
                    <div 
                        v-for="command in filteredCommands" 
                        :key="command.id"
                        :ref="command.id"
                        class="search-result cursor-pointer"
                        :class="{ 
                            'selected': command == selected,
                        }"
                        @click="runCommand(command)"
                        >
                        <OIcon
                            v-if="!command.icon || typeof command.icon === 'string'"
                            :icon="[command.iconPrefix || 'far', command.icon || 'angle-right']" 
                            fixed-width
                            class="mr-3 flex-shrink-0" 
                            :class="command.active ? 'text-real-primary' : 'text-muted'"
                            />
                        <AvatarComponent 
                            v-else
                            :image="command.icon" 
                            size="sm" 
                            class="border bg-white-real" 
                            style="padding: 1px; margin-right: 0.8rem; margin-left: -0.2rem;" 
                            />
                        <div>
                            <h6 class="search-result-title mb-0">
                                <span v-if="command.group" :class="{ 'text-real-primary': command.contextual }">
                                    {{ command.group }} <OIcon :icon="['far', 'angle-right']" class="mx-1 text-muted" />
                                </span>
                                <span>{{ command.name }}</span>
                            </h6>
                            <div v-if="command.description" class="opacity-75">{{ command.description }}</div>
                        </div>
                        <div class="search-result-keyhint d-none d-md-block">
                            <div class="badge badge-dark">⏎ {{ $t(commandHasAction(command) ? 'command.run' : 'command.open') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </OContainer>
    </div>
</template>
<script>
import Fuse from 'fuse.js'
import AvatarComponent from '~/components/avatar.vue'

export default {
    components: {
        AvatarComponent
    },

    data() {
        return {
            commandQuery: this.$route.query.search || '',
            selected: null,
            loading: false,
            commands: []
        }
    },

    created() {
        this.commands = [
            {
                id: 'time/create',
                name: this.$t('time.create'),
                icon: 'user-clock',
                run: '/time/create',
                permission: 'create time',
                plan: 'pro|business'
            },
            {
                id: 'absence/create',
                name: this.$t('absence.create'),
                tags: [this.$t('absence.vacation_days')],
                icon: 'island-tropical',
                run: '/absence/create',
                permission: 'create absence',
                plan: 'business'
            },
            {
                id: 'project/create',
                name: this.$t('project.create'),
                icon: 'cubes',
                run: '/project/create',
                permission: 'create project',
                plan: 'pro|business'
            },
            {
                id: 'company/create',
                name: this.$t('company.create'),
                tags: [this.$t('contact.contact')],
                icon: 'address-card',
                run: '/company/create',
                permission: 'create company'
            },
            {
                id: 'offer/create',
                name: this.$t('offer.create'),
                icon: 'quote-left',
                run: '/offer/create',
                permission: 'create offer'
            },
            {
                id: 'invoice/create',
                name: this.$t('invoice.create'),
                icon: 'file-invoice',
                run: '/invoice/create',
                permission: 'create invoice'
            },
            {
                id: 'expense/create',
                group: this.$t('expense.expenses'),
                name: this.$t('expense.create-manually'),
                tags: [this.$t('expense.type-invoice')],
                icon: 'receipt',
                run: '/expense/create',
                permission: 'create expense',
                plan: 'pro|business',
            },
            {
                id: 'expense/create-upload',
                group: this.$t('expense.expenses'),
                name: this.$t('expense.create-upload'),
                tags: [this.$t('expense.type-allowance'), this.$t('expense.type-invoice')],
                icon: 'file-upload',
                run: () => {
                    this.$modal(null, 'expense/create-from-receipt').then((expense) => {
                        this.$router.push(`/expense/${expense.id}/edit`);
                    })
                },
                permission: 'create expense',
                plan: 'pro|business',
            },
            {
                id: 'product/create',
                name: this.$t('product.create'),
                icon: 'tags',
                run: '/product/create',
                permission: 'create product'
            },
            {
                id: 'rate/create',
                name: this.$t('rate.create'),
                icon: 'heart-rate',
                run: '/rate/create',
                permission: 'create rate'
            },

            {
                id: 'dashboard',
                name: this.$t('app.dashboard'),
                tags: ['home', 'start'],
                icon: 'chart-pie',
                run: '/',
            },
            {
                id: 'time/list',
                name: this.$t('time.times'),
                icon: 'user-clock',
                run: '/time',
                permission: 'show time',
                plan: 'pro|business',
            },
            {
                id: 'absence/list',
                name: this.$t('absence.absences'),
                icon: 'island-tropical',
                run: '/absence',
                permission: 'show absence',
                plan: 'business',
            },
            {
                id: 'company/list',
                name: this.$t('company.companies'),
                icon: 'address-card',
                run: '/company',
                permission: 'show company'
            },
            {
                id: 'company/import',
                group: this.$t('company.companies'),
                name: this.$t('crud.import'),
                icon: 'address-card',
                run: '/company/import',
                permission: 'create company'
            },
            {
                id: 'project/list',
                name: this.$t('project.projects'),
                icon: 'cubes',
                run: '/project',
                permission: 'show project',
                plan: 'pro|business',
            },
            {
                id: 'invoice/list',
                name: this.$t('invoice.invoices'),
                icon: 'file-invoice-dollar',
                run: '/invoice',
                permission: 'show invoice'
            },
            {
                id: 'offer/list',
                name: this.$t('offer.offers'),
                icon: 'quote-left',
                run: '/offer',
                permission: 'show offer'
            },
            {
                id: 'expense/list',
                name: this.$t('expense.expenses'),
                icon: 'receipt',
                run: '/expense',
                permission: 'show expense',
                plan: 'pro|business',
            },
            {
                id: 'product/list',
                name: this.$t('product.products'),
                icon: 'tags',
                run: '/product',
                permission: 'show product'
            },
            {
                id: 'rate/list',
                name: this.$t('rate.rates'),
                icon: 'heart-rate',
                run: '/rate',
                permission: 'show rate'
            },
            {
                id: 'organization/outgoing-mail',
                name: this.$t('app.outgoing-mail'),
                tags: ['mail'],
                icon: 'mail-bulk',
                run: '/outgoing-mail',
                permission: 'show outgoing_mail'
            },
            {
                id: 'accounting/overview',
                name: this.$t('accounting.accounting'),
                tags: [this.$t('accounting.accounting')],
                icon: 'calculator',
                run: '/accounting',
                permission: 'manage accounting'
            },
            {
                id: 'accounting/journal',
                name: this.$t('accounting.journal'),
                group: this.$t('accounting.accounting'),
                tags: [this.$t('accounting.entry')],
                icon: 'book',
                run: '/accounting/journal',
                permission: 'manage accounting'
            },
            {
                id: 'accounting/account-sheet',
                name: this.$t('account.sheet'),
                group: this.$t('accounting.accounting'),
                tags: [this.$t('account.account')],
                icon: 'folder-tree',
                run: '/accounting/account',
                permission: 'manage accounting'
            },
            {
                id: 'accounting/balance-sheet',
                name: this.$t('accounting.balance-sheet'),
                group: this.$t('accounting.accounting'),
                icon: 'balance-scale',
                run: '/accounting/reports/balance-sheet',
                permission: 'manage accounting'
            },
            {
                id: 'accounting/income-statement',
                name: this.$t('accounting.income-statement'),
                group: this.$t('accounting.accounting'),
                icon: 'chart-line',
                run: '/accounting/reports/income-statement',
                permission: 'manage accounting'
            },
            {
                id: 'accounting/export',
                name: this.$t('accounting.export'),
                group: this.$t('accounting.accounting'),
                icon: 'file-export',
                run: '/accounting/export',
                permission: 'manage accounting'
            },
            {
                id: 'banking',
                name: this.$t('app.banking'),
                icon: 'university',
                run: '/banking',
                permission: 'show bank_account'
            },
            {
                id: 'banking/reconciliation',
                name: this.$t('reconciliation.payment-reconciliation'),
                tags: ['camt'],
                icon: 'sync-alt',
                run: '/banking/reconciliation',
                permission: 'edit invoice'
            },
            {
                id: 'banking/payment-initiation',
                name: this.$t('expense.payment-initiation'),
                tags: ['pain'],
                icon: 'money-check-edit',
                run: '/banking/payment-initiation',
                permission: 'supervise expense',
            },
            {
                id: 'time/team',
                name: this.$t('time.time') + ': ' + this.$t('time.team'),
                icon: 'users',
                run: '/time/team',
                permission: 'supervise time',
                plan: 'pro|business',
            },
            {
                id: 'user/feedback',
                name: this.$t('feedback.modal-title'),
                icon: 'comment-lines',
                run: () => this.$modal(this.$auth.user, 'user-feedback'),
            },
            {
                id: 'dunning/toggle-auto-sending',
                name: this.$t(this.$org.settings.autoSendInvoiceReminders
                    ? 'dunning.disable-auto-sending'
                    : 'dunning.enable-auto-sending'),
                description: this.$t(this.$org.settings.autoSendInvoiceReminders
                    ? 'dunning.auto-sending-enabled'
                    : 'dunning.auto-sending-disabled'),
                icon: 'alarm-exclamation',
                run: async () => {
                    await this.$store.dispatch('organization/updateSettings', {
                        autoSendInvoiceReminders: !this.$org.settings.autoSendInvoiceReminders
                    })

                    await this.$auth.fetchUser()
                },
                notify: true,
            },
            {
                id: 'auth/logout',
                name: this.$t('auth.sign-out'),
                tags: ['logout', 'sign-out'],
                icon: 'sign-in',
                run: () => this.$auth.logout(),
                needsConfirmation: true,
            },
            {
                id: 'toggle-money-values',
                name: this.$t(this.$store.state.hideMoneyValues ? 'app.show-money-values' : 'app.hide-money-values'),
                description: this.$t('app.hide-money-values-text'),
                icon: 'eye-slash',
                run: () => this.$store.commit('setHideMoneyValues', !this.$store.state.hideMoneyValues),
                active: this.$store.state.hideMoneyValues,
            },
            {
                id: 'toggle-screencast-mode',
                name: this.$t(this.$store.state.screencastMode ? 'app.disable-screencast-mode' : 'app.enable-screencast-mode'),
                description: this.$t('app.screencast-mode-text'),
                icon: this.$store.state.screencastMode ? 'video-slash' : 'video',
                run: () => this.$store.commit('setScreencastMode', !this.$store.state.screencastMode),
                active: this.$store.state.screencastMode,
            },
            {
                id: 'app.reload',
                name: this.$t('app.reload'),
                tags: ['reload', 'refresh', 'update'],
                icon: 'sync-alt',
                run: () => window.location.reload(),
            },
            {
                id: 'user.profile',
                group: this.$t('user.account'),
                name: this.$t('user.profile'),
                description: [
                    this.$t('user.details'),
                    this.$t('user.change-password'),
                ].join(', '),
                icon: 'user-cog',
                run: '/user/profile'
            },
            {
                id: 'user.settings',
                group: this.$t('user.account'),
                name: this.$t('user.settings'),
                description: [
                    this.$t('notification.notifications'),
                    this.$t('auth.login-services'),
                    this.$t('2fa.2fa'),
                ].join(', '),
                tags: ['apple', 'google'],
                icon: 'user-cog',
                run: '/user/settings'
            },
            {
                id: 'billing.client',
                group: this.$t('billing.billing'),
                name: this.$t('billing.client'),
                icon: 'file-invoice-dollar',
                run: '/billing/client',
            },
            {
                id: 'billing.project',
                group: this.$t('billing.billing'),
                name: this.$t('billing.project'),
                icon: 'file-invoice-dollar',
                run: '/billing/project',
                plan: 'pro|business',
            },
            {
                id: 'billing.offer',
                group: this.$t('billing.billing'),
                name: this.$t('billing.offer'),
                icon: 'file-invoice-dollar',
                run: '/billing/offer',
            },
            {
                id: 'theme.toggle',
                name: this.$t('theme.toggle') + ': ' + (this.$getActualTheme() == 'light' ? this.$t('theme.dark') : this.$t('theme.light')),
                tags: ['theme', 'design', 'light', 'dark', this.$getActualTheme() == 'light' ? this.$t('theme.dark') : this.$t('theme.light')],
                icon: this.$getActualTheme() == 'light' ? 'moon' : 'sun',
                run: () => {
                    const theme = this.$getActualTheme()
                    this.$theme(theme == 'light' ? 'dark' : 'light')
                },
            },
            {
                id: 'app.changelog',
                name: this.$t('app.changelog'),
                description: this.$t('app.changelog-text'),
                tags: ['changelog', 'update'],
                icon: 'sparkles',
                run: '/changelog',
            },

            // Settings
            {
                id: 'organization/settings',
                group: this.$t('organization.organization'),
                name: this.$t('organization.settings'),
                tags: [this.$org.name],
                icon: 'cogs',
                run: '/organization/settings',
                permission: 'edit organization'
            },
            {
                id: 'organization/billing',
                group: this.$t('organization.organization'),
                name: this.$t('organization.plan-and-payment'),
                description: this.$t('organization.plan-and-payment-text'),
                tags: [this.$org.name, this.$t('addon.addons'), 'addons', this.$t('payment.payment-methods')],
                icon: 'credit-card',
                run: '/organization/billing',
                permission: 'edit organization'
            },
            {
                id: 'settings/users',
                group: this.$t('organization.settings'),
                name: this.$t('organization.members'),
                tags: [this.$t('invitation.invite-new-member')],
                icon: 'cog',
                run: '/organization/settings/members',
                permission: 'edit organization'
            },
            {
                id: 'settings/staff',
                group: this.$t('organization.settings'),
                name: this.$t('organization.setting.staff'),
                description: [this.$t('absence.absences'), this.$t('absence.vacation_days'), this.$t('holiday.holidays')].join(', '),
                icon: 'cog',
                run: '/organization/settings/staff',
                permission: 'manage employment info'
            },
            {
                id: 'settings/pdf',
                group: this.$t('organization.settings'),
                name: this.$t('organization.setting.document'),
                icon: 'cog',
                run: '/organization/settings/pdf',
                permission: 'edit organization'
            },
            {
                id: 'settings/invoice',
                group: this.$t('organization.settings'),
                name: this.$t('organization.setting.invoice'),
                tags: [this.$t('invoice.qrbill')],
                icon: 'cog',
                run: '/organization/settings/invoice',
                permission: 'edit organization'
            },
            {
                id: 'settings/expense',
                group: this.$t('organization.settings'),
                name: this.$t('organization.setting.expense'),
                icon: 'cog',
                run: '/organization/settings/expense',
                permission: 'edit organization',
                plan: 'pro|business',
            },
            {
                id: 'settings/document-no',
                group: this.$t('organization.settings'),
                name: this.$t('organization.setting.document-no'),
                icon: 'cog',
                run: '/organization/settings/number-range',
                permission: 'edit organization'
            },
            {
                id: 'settings/mail',
                group: this.$t('organization.settings'),
                name: this.$t('organization.setting.mail'),
                icon: 'cog',
                run: '/organization/settings/mail',
                permission: 'edit organization'
            },
            {
                id: 'settings/tag',
                group: this.$t('organization.settings'),
                name: this.$t('tag.tags'),
                icon: 'cog',
                run: '/organization/settings/tag',
                permission: 'edit organization'
            },
            {
                id: 'settings/custom_field',
                group: this.$t('organization.settings'),
                name: this.$t('custom_field.custom_fields'),
                tags: ['custom', 'field'],
                icon: 'cog',
                run: '/organization/settings/custom_field',
                permission: 'edit organization',
                plan: 'business',
                addon: 'custom_fields',
            },
            {
                id: 'settings/dunning',
                group: this.$t('organization.settings'),
                name: this.$t('dunning.dunnings'),
                icon: 'cog',
                run: '/organization/settings/dunning',
                permission: 'edit organization'
            },
            {
                id: 'settings/banking',
                group: this.$t('organization.settings'),
                name: this.$t('bank_account.bank_accounts'),
                icon: 'university',
                run: '/organization/settings/banking',
                permission: 'edit bank_account'
            },
            {
                id: 'settings/vat_rate',
                group: this.$t('organization.settings'),
                name: this.$t('vat_rate.vat-rates'),
                tags: ['mwst', 'vat'],
                icon: 'cog',
                run: '/organization/settings/vat_rate',
                permission: 'edit organization'
            },
            {
                id: 'settings/unit',
                group: this.$t('organization.settings'),
                name: this.$t('unit.units'),
                tags: ['unit'],
                icon: 'cog',
                run: '/organization/settings/unit',
                permission: 'edit organization'
            },
            {
                id: 'settings/accounting',
                group: this.$t('organization.settings'),
                name: this.$t('accounting.accounting'),
                tags: ['accounting'],
                icon: 'cog',
                run: '/organization/settings/accounting',
                permission: 'edit organization'
            },
            {
                id: 'settings/integration',
                group: this.$t('organization.settings'),
                name: this.$t('webhook.webhooks'),
                tags: ['integration', 'webhook'],
                description: this.$t('webhook.explainer'),
                icon: 'code',
                run: '/organization/settings/integrations',
                permission: 'edit organization'
            },
        ]

        this.$auth.user.organizations
            .filter(org => org.id != this.$org.id)
            .forEach(org => {
                this.commands.push({
                    id: 'change-organization/' + org.id,
                    group: this.$t('organization.change'),
                    name: org.name,
                    icon: org.logo,
                    run: () => this.$store.dispatch('user/changeOrganization', org),
                })
        })

        if (this.$can('list journal_transaction_template')) {
            this.$store.dispatch('journal_transaction_template/fetchAll').then(({ data: templates }) => {
                templates.forEach(template => {
                    this.commands.push({
                        id: 'journal_transaction_template/' + template.id,
                        group: this.$t('accounting.entry-template'),
                        name: template.name,
                        description: template.description,
                        run: async () => {
                            const data = await this.$modal({ template }, 'journal-transaction/apply-template')
                            return this.$axios.$post(`/journal_transaction_template/${template.id}/apply`, data)
                                .then((transaction) => {
                                    this.$toast(this.$t('accounting.entry-posted') + ` (${transaction.number})`, 'success', {
                                        message: template.name,
                                    })
                                })
                        },
                    })
                })
            })
        }

        if (this.$route.name.startsWith('offer-id')) {
            const offer = this.$store.getters['offer/getOne'](this.$route.params.id)

            if (offer) {
                this.commands.unshift({
                    contextual: true,
                    id: 'offer/id/send',
                    icon: 'envelope',
                    group: this.$t('offer.offer') + ' ' + offer.number,
                    name: this.$t('crud.send'),
                    permission: 'show offer',
                    run: async () => {
                        const { recipients, message } = await this.$modal({ offer }, 'offer/send-to-client')
                        this.$store.dispatch('offer/sendToClient', { offer, email: recipients, message })
                    }
                })
            }
        }

        if (this.$route.name.startsWith('invoice-id')) {
            const invoice = this.$store.getters['invoice/getOne'](this.$route.params.id)
            
            if (invoice) {
                this.commands.unshift({
                    contextual: true,
                    id: 'invoice/id/send',
                    icon: 'envelope',
                    group: this.$t('invoice.invoice') + ' ' + invoice.number,
                    name: this.$t('crud.send'),
                    permission: 'show invoice',
                    run: async () => {
                        const { recipients, increaseDunningLevel, ccToOwner, adjustDates } = await this.$modal({ invoice }, 'invoice/send-to-client')
                        this.$store.dispatch('invoice/sendToClient', { invoice, recipients, increaseDunningLevel, ccToOwner, adjustDates })
                    }
                })
            }
        }
    },

    mounted() {
        this.$bindKeyboard({
            'esc': () => this.close()
        })

        this.$nextTick(() => this.$refs.searchInput.focus())
        this.$nextTick(() => this.$refs.searchInput.select())

        document.body.classList.add('modal-open')
    },

    destroyed() {
        document.body.classList.remove('modal-open')
    },

    computed: {
        allCommands() {
            return this.commands.filter(command => {
                return (!command.permission || this.$can(command.permission))
                    && (
                        (!command.plan || this.$onPlan(command.plan))
                        || (command.addon && this.$org.addons.includes(command.addon))
                    )
            })
        },

        filteredCommands() {
            if (this.commandQuery.length === 0) {
                return this.allCommands.slice(0, 5)
            }

            const fuse = new Fuse(this.allCommands, {
                includeScore: true,
                threshold: 0.4,
                keys: [
                    { name: 'name', weight: 2 },
                    { name: 'tags', weight: 1 },
                    { name: 'group', weight: 1 },
                    { name: 'description', weight: 0.5 },
                ],
            })

            const commands = fuse.search(this.commandQuery).map(result => result.item)

            commands.push({
                id: 'search',
                name: this.$t('search.search-for', { query: this.commandQuery }) + ' ...',
                description: this.$t('search.find-anything'),
                icon: 'search',
                run: ({ query }) => {
                    this.$nextTick(() => this.$store.dispatch('search/toggleOverlay', query))
                },
            })

            return commands
        },

        noResults() {
            return this.commandQuery.length > 0 && this.filteredCommands.length === 0
        }
    },

    methods: {
        close() {
            this.$store.dispatch('command/toggleOverlay')
        },

        select(command) {
            this.selected = command
        },

        next() {
            const index = this.filteredCommands.indexOf(this.selected) || 0
            this.selected = this.filteredCommands[index + 1] || this.filteredCommands[0]
        },
        
        prev() {
            const index = this.filteredCommands.indexOf(this.selected) || 0
            this.selected = this.filteredCommands[index - 1] || this.filteredCommands[this.filteredCommands.length - 1]
        },

        enterHandler() {
            if (!this.selected) {
                this.search(true)
                return
            }

            this.runCommand(this.selected)
        },

        async runCommand(command) {
            if (!command.run) {
                console.error('Command has no run method', command);
                return
            }

            if (typeof command.run === 'function') {
                if (command.needsConfirmation) {
                    this.close()
                    await this.$modal({ 
                        title: command.name,
                        message: this.$t('command.confirm', { name: command.name }),
                        type: 'info',
                        confirmText: this.$t('command.run'),
                    })
                }

                const result = command.run({
                    query: this.commandQuery,
                })

                if (result && result.then && command.notify) {
                    this.$toast(command.name, 'primary', {
                        message: this.$t('command.running'),
                        icon: command.icon || 'terminal',
                        until: result,
                        untilSuccess: () => ({
                            message: this.$t('command.success'),
                            type: 'success',
                        })
                    })
                }
            } else if (typeof command.run === 'string') {
                this.$router.push(command.run)
            }

            this.close()
        },

        clearInput() {
            this.commandQuery = ''
            this.$nextTick(() => this.$refs.searchInput.focus())
        },

        commandHasAction(command) {
            return command.run && typeof command.run === 'function'
        },
    },

    watch: {
        '$route'() {
            this.close()
        },

        filteredCommands: {
            handler(commands) {
                if (commands.length === 0) {
                    this.selected = null
                } else {
                    this.selected = commands[0]
                }
            },
            immediate: true,
        },

        async selected(selected) {
            await this.$nextTick()
            if (selected) {
                const resultElement = this.$refs[selected.id][0]
                if (resultElement) {
                    this.$refs.results.scrollTop = resultElement.offsetTop - this.$refs.results.clientHeight
                }
            }
        }
    }
}
</script>