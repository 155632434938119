<template>
    <div>
        <transition name="fade">
            <div v-if="open" class="sidebar-backdrop" @click="$emit('toggle')"></div>
        </transition>
        <aside class="sidebar" :class="{ 'open': open }">
            <div class="sidebar-row sidebar-branding">
                <div class="sidebar-icon">
                    <slot name="icon">B</slot>
                </div>
                <div class="sidebar-label w-100 mw-0">
                    <div v-if="title" class="text-truncate"><strong>{{ title }}</strong></div>
                    <div v-if="app" class="text-muted font-weight-normal">{{ app }}</div>
                </div>
            </div>

            <slot />
        </aside>
    </div>
</template>
<script>
export default {
    props: ['title', 'app', 'open'],
}
</script>
