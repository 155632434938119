var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CrudListData',{attrs:{"resource":"vat_rate"},scopedSlots:_vm._u([{key:"default",fn:function({ items: vat_rates }){return [_c('OSearch',{attrs:{"name":_vm.name,"label":_vm.hideLabel ? null : (_vm.label || _vm.$t('vat_rate.vat-rate')),"value":_vm.value,"select-first":_vm.selectFirst,"selected-label":option => `${option.rate}% – ${option.name}`,"options":vat_rates.filter(_vm.typeFilter).filter(_vm.validFilter).map(i => ({ 
            ...i,
            label: `${i.name} (${i.rate}%)`, 
            value: i.id, 
        })),"disabled":_vm.disabled || vat_rates.length == 0,"required":_vm.required},on:{"input":event => {
            _vm.$emit('input', event)
            _vm.$emit('selected', vat_rates.find(c => c.id == event))
        }},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return [_c('div',{staticClass:"d-flex align-items-center gap-3"},[_c('div',[_c('div',[_vm._v(_vm._s(option.name))]),_vm._v(" "),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(option.description))])]),_vm._v(" "),_c('div',{staticClass:"ml-auto my-0 font-weight-semibold text-right tabular-nums"},[_vm._v(_vm._s(option.rate)+"%")])])]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }