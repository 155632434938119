<template>
    <div class="tabs">
        <ul class="nav nav-tabs nav-tabs-borderless" :class="{ 'nav-tabs-spread': spreadTabs }" role="tablist">
            <li v-for="tab in tabs.filter(t => t.show === undefined || !!t.show)" :key="`tab-nav-${tab.name || tab}`" class="nav-item">
                <a href="javascript:;" 
                    class="nav-link" 
                    :class="{ 'active': (tab.name || tab) == active, 'disabled': tab.disabled }" 
                    @click="changeTab(tab)"
                    role="tab"
                    :aria-controls="tab.name || tab"
                    :aria-selected="((tab.name || tab) == active).toString()"
                    >
                        <OIcon v-if="tab.icon" 
                            :icon="['far', tab.icon]" 
                            class="mr-1" 
                            :class="`text-${(tab.name || tab) == active ? 'reset' : 'muted'}`">
                        </OIcon>
                        {{ tab.label || tab.name || tab }}

                        <span v-if="tab.badge" class="badge badge-pill ml-1 py-05" :class="`badge-${tab.badgeType || 'primary'}`">{{ tab.badge }}</span>
                </a>
            </li>
            <div class="ml-auto d-flex">
                <slot name="actions"></slot>
            </div>
        </ul>
        <div class="tab-content" :class="{ 'pb-1': noPaddingBottom }">
            <transition name="tabs" mode="out-in">
                <div 
                    v-if="active" 
                    :key="`tab-${active.name || active}`" role="tabpanel" 
                    :aria-labelledby="active.name || active"
                    class="tab-pane show active" 
                    >
                    <slot :name="active.name || active"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        noPaddingBottom: {
            type: Boolean,
            default: false,
        },
        spreadTabs: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            active: null,
        }
    },

    created() {
        let first = this.tabs.find((t => {
            return (t.disabled === undefined || !t.disabled)
                && (t.show === undefined || !!t.show)
        }));

        this.active = first.name || first || null;
    },

    methods: {
        changeTab(tab) {
            this.active = tab.name || tab
            this.$emit('changed', this.active)
        },
    }
}
</script>
