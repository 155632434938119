<template functional>
    <div class="loading-container" :class="props.loading ? [
            data.class, 
            data.staticClass,
            `loading-${props.type}`
        ] : []"
        :style="props.loading ? [
            data.style, 
            data.staticStyle
        ] : []">
        <slot />
        <transition name="fade">
            <div v-show="props.loading" class="loading-inner">
                <div class="loading-bar">
                    <div v-if="props.message" class="mb-1">
                        <OIcon v-if="props.icon" :icon="['far', props.icon]" fixed-width />
                        <span>{{ props.message }}</span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        
        message: {
            type: String,
            default: null
        }, 

        type: {
            type: String,
            default: 'primary'
        },

        icon: {
            type: String,
            default: null
        }
    }
}
</script>