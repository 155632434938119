let uuid = 0

export default {
    data() {
        return {
            uuid: null,
        }
    },

    created() {
        this.uuid = 'uuid-' + uuid.toString()
        uuid += 1
    }
}
