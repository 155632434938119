<template>
  <div class="dropdown d-inline-block" v-click-outside="close" role="menu">
    <div class="dropdown-button" ref="button" @click="toggle()">
        <slot name="toggle" :toggle="toggle" :uuid="uuid" :isOpen="isOpen" :disabled="disabled">
            <OButton v-if="label" :id="uuid" :type="buttonType" :size="buttonSize" :aria-expanded="isOpen.toString()" :disabled="disabled">{{ label }} <OIcon :icon="['far', buttonIcon]" class="ml-2"></OIcon></OButton>
            <OButton v-else :id="uuid" :type="buttonType" :size="buttonSize" class="btn-icon" :aria-expanded="isOpen.toString()" :disabled="disabled"><OIcon :icon="['far', buttonIcon]" fixed-width></OIcon></OButton>
        </slot>
    </div>
    <transition name="dropdown">
        <div 
            ref="menu"
            class="dropdown-menu" 
            :class="[isOpen ? menuClass : null, {
                'show': isOpen,
                'dropdown-menu-limit': limit,
                'mt-2': align === 'bottom-start' || align === 'bottom-end',
                'mb-2': align === 'top-start' || align === 'top-end',
                'ml-2': align === 'right-start' || align === 'right-end',
                'mr-2': align === 'left-start' || align === 'left-end',
            }]" 
            :aria-labelledby="uuid"
            @click="dropdownItemClicked">
            <slot :toggle="toggle" :isOpen="isOpen" :close="close"></slot>
        </div>
    </transition>
    <transition name="dropdown-backdrop">
        <div v-if="isOpen" class="dropdown-backdrop" @click="close()"></div>
    </transition>
  </div>
</template>
<script>
import OButton from './Button.vue'
import { createPopper } from '@popperjs/core'
import Uuid from '~/mixins/uuid'

export default {
    mixins: [Uuid],
    props: {
        align: {
            type: String,
            default: 'bottom-start'
        },

        limit: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        buttonType: {
            type: String,
            default: 'light'
        },
        buttonSize: {
            type: String,
            default: null,
        },
        buttonIcon: {
            type: String,
            default: 'ellipsis-v',
        },
        menuClass: {
            type: String,
            default: ''
        },
    },

    data() {
        return {
            isOpen: false,
            popper: null,
        }
    },

    components: {
        OButton,
    },

    methods: {
        toggle() {
            if (this.disabled) {
                return
            }

            this.isOpen = !this.isOpen

            if (this.isOpen) {
                this.$emit('opened')
            } else {
                this.$emit('closed')
            }
        },

        open() {
            if (this.disabled) {
                return
            }

            this.isOpen = true
        },

        close() {

            this.isOpen = false
        },

        dropdownItemClicked(event) {
            if (event.target.closest('.dropdown-item')) {
                this.close()
            }
        }
    },

    watch: {
        isOpen(open) {
            if (!this.$breakpoint('md')) {
                return
            }

            this.$nextTick(() => {
                if (open) {
                    this.popper = createPopper(this.$refs.button, this.$refs.menu, {
                        placement: this.align
                    })
                } else if (this.popper) {
                    this.popper.destroy()
                    this.popper = null
                }
            })
        }
    }
}
</script>
