import crudStoreFactory from '~/util/crud/storeFactory'
import queryBuilder from '~/util/crud/queryBuilder'

const crudStore = new crudStoreFactory('time', {
    include: ['member.user.media', 'rate', 'project.company.media']
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    export({ dispatch }, { fileType, filters, sort }) {
        if (sort) {
            sort = sort.key ? (!sort.ascending ? '-' : '') + sort.key : null
        }

        dispatch('toast/push', {
            title: this.app.i18n.t('crud.export'),
            message: this.app.i18n.t('time.exporting'),
            type: 'primary',
            icon: 'file-export',
        }, { root: true })

        const query = { 
            filters, 
            sort,
            perPage: null,
        }

        window.open(`${this.$axios.defaults.baseURL}time/export/${fileType}${queryBuilder(query)}`, '_blank')

        return
    }

}