<template>
    <OLoading :loading="loadingState.default" :message="loadingMessage || $t('document.pdf-loading')">
        <div
            v-if="paginate && totalPages > 1"
            class="position-absolute bg-card rounded d-flex gap-2 align-items-center tabular-nums top-0 right-0 m-2 p-1"
            >
            <OButton
                type="light bg-transparent"
                size="sm"
                icon="arrow-left"
                @click="navigate(-1)"
                :disabled="currentPage === 1"
                />
            <!-- <span>{{ currentPage }}/{{ totalPages }}</span> -->
            <OButton
                type="light bg-transparent"
                size="sm"
                icon="arrow-right"
                @click="navigate(1)"
                :disabled="currentPage >= totalPages"
                />
        </div>
        <div v-bind="$attrs" ref="container"></div>
    </OLoading>
</template>
<script>
import HasLoadingState from '~/mixins/hasLoadingState'
import pdfjsLib from 'pdfjs-dist/webpack'
export default {
    inheritAttrs: false,
    mixins: [HasLoadingState],

    emits: ['loaded', 'page-changed'],

    props: {
        url: {
            type: String,
            required: true
        },

        canvasClass: {
            type: String,
            default: 'img-fluid'
        },

        scale: {
            type: Number,
            default: 1
        },

        initPage: {
            type: Number,
            default: 1
        },

        paginate: {
            type: Boolean,
            default: true
        },

        http: {
            type: Function,
            default: null
        },

        loadingMessage: {
            type: String,
            default: null
        },

        useAxios: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            pdf: null,
            error: null,
            currentPage: this.initPage,
            totalPages: 1,
        }
    },

    mounted() {
        this.load()
    },

    methods: {
        async load() {
            this.$startLoading()

            try {
                let loadPdfTask;
                if (this.useAxios) {
                    const data = await (this.http || this.$axios).$get(this.url, { responseType: 'arraybuffer' })
                    loadPdfTask = pdfjsLib.getDocument(new Uint8Array(data))
                } else {
                    loadPdfTask = pdfjsLib.getDocument(this.url)
                }

                this.pdf = await loadPdfTask.promise
                this.totalPages = this.pdf.numPages

                this.$emit('loaded', this.pdf)

                await this.$nextTick()

                if (!this.paginate) {
                    for (let pageNumber = 1; pageNumber <= this.totalPages; pageNumber++) {
                        await this.renderPage(pageNumber)
                    }
                } else {
                    await this.renderPage(this.currentPage)
                }
            } catch (err) {
                this.$stopLoading()
                this.error = err
                return
            }

            this.$stopLoading()
        },

        async renderPage(pageNumber) {
            const page = await this.pdf.getPage(pageNumber)
            const container = this.$refs.container
            const viewport = page.getViewport({ scale: this.scale })
            const pageContainer = document.createElement('div')
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')

            const textContent = await page.getTextContent();
            const svg = this.buildTextContentSvg(viewport, textContent)

            pageContainer.classList.add('position-relative')
            svg.classList.add('d-block', 'position-absolute', 'top-0', 'left-0', 'w-100')
            svg.style.fill = 'transparent'

            canvas.height = viewport.height
            canvas.width = viewport.width
            canvas.classList.add('d-block', this.canvasClass)

            if (this.paginate) {
                container.innerHTML = ''
            } else {
                container.classList.add('p-1', 'd-flex', 'flex-column', 'gap-1')
                pageContainer.classList.add('d-block', 'border', 'rounded-lg', 'shadow', 'overflow-hidden')
            }

            pageContainer.appendChild(canvas)
            container.appendChild(pageContainer)
            pageContainer.appendChild(svg)

            const renderContext = {
                canvasContext: context,
                viewport: viewport
            }

            const renderTask = page.render(renderContext);
            await renderTask.promise

            page.cleanup()
        },

        async navigate(pageCount) {
            const newPage = this.currentPage + pageCount
            if (newPage < 1 || newPage > this.totalPages) {
                return
            }

            this.currentPage = newPage
            this.$emit('page-changed', this.currentPage)
            await this.renderPage(this.currentPage)
        },

        buildTextContentSvg(viewport, textContent) {
            // Building SVG with size of the viewport (for simplicity)
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg:svg')
            svg.setAttribute("viewBox", "0 0 " + viewport.width + " " + viewport.height)
            // items are transformed to have 1px font size
            svg.setAttribute("font-size", 1)

            // processing all items
            textContent.items.forEach(function (textItem) {
                // we have to take in account viewport transform, which includes scale,
                // rotation and Y-axis flip, and not forgetting to flip text.
                const tx = pdfjsLib.Util.transform(
                    pdfjsLib.Util.transform(viewport.transform, textItem.transform),
                    [1, 0, 0, -1, 0, 0]
                )
                const style = textContent.styles[textItem.fontName]
                // adding text element
                const text = document.createElementNS('http://www.w3.org/2000/svg', 'svg:text')
                text.setAttribute("transform", "matrix(" + tx.join(" ") + ")")
                text.setAttribute("font-family", style.fontFamily)
                text.textContent = textItem.str
                svg.append(text)
            })

            return svg
        }
    }
}
</script>
