<template>
    <Transition name="portal" appear>
        <div 
            v-if="isOpen" 
            ref="portal" 
            class="portal"
            :class="`portal-${size}`"
            >
            <div class="portal-backdrop" @click="close()"></div>
            <div ref="content" class="portal-content d-flex flex-column">
                <div class="pt-3 px-3 pt-lg-4 px-lg-4">
                    <div class="d-flex align-items-center gap-3">
                        <div>
                            <div v-if="prehead" class="prehead">{{ prehead }}</div>
                            <h3 v-if="title" class="portal-title mb-0">{{ title }}</h3>
                        </div>
                        <OBadge v-if="badge" :type="badgeType">{{ badge }}</OBadge>
                    </div>
                    <hr v-if="title" class="mx-n3" :class="{ 'opacity-0': hideDivider }">
                </div>

                <div class="portal-close d-flex gap-4">
                    <NuxtLink v-if="openExternalLink" :to="openExternalLink" @click.native="close()" v-tooltip="$t('crud.open')">
                        <OIcon :icon="['far', 'external-link']" />
                    </NuxtLink>

                    <button type="button" class="btn-reset" @click="close()" v-tooltip="$t('crud.close')">
                        <OIcon :icon="['far', 'times']" size="lg" />
                    </button>
                </div>

                <slot 
                    :is-open="isOpen" 
                    :close="close" 
                    :open="open" 
                    />
            </div>
        </div>
    </Transition>
</template>
<script>
export default {
    props: {
        initOpen: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null,
        },
        prehead: {
            type: String,
            default: null,
        },
        openExternalLink: {
            type: String,
            default: null,
        },
        badge: {
            type: String,
            default: null,
        },
        badgeType: {
            type: String,
            default: 'info',
        },
        hideDivider: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
        },
    },

    data() {
        return {
            isOpen: this.initOpen
        }
    },

    mounted() {
        document.body.append(this.$refs.portal)
        document.addEventListener('keydown', this.handleEscape)
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.handleEscape)
    },

    methods: {
        open() {
            this.isOpen = true
            this.$emit('open')
        },

        close() {
            this.isOpen = false
            this.$nextTick(() => this.$emit('close'))
        },

        handleEscape(e) {
            if (e.key === 'Escape') {
                this.close()
            }
        }
    },
}
</script>