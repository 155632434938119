<template>
    <OModal
        :title="title"
        :subtitle="subtitle"
        :type="type"
        :icon="icon"
        :confirmText="confirmText"
        :cancelText="cancelText"
        :confirmIcon="confirmIcon"
        @close="$emit('close')"
        @confirm="$emit('confirm')"
        >
        <div v-html="message"></div>
    </OModal>
</template>
<script>
export default {
    props: ['title', 'subtitle', 'message', 'type', 'icon', 'confirmText', 'cancelText', 'confirmIcon']
}
</script>