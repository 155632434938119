<template>
    <div class="position-fixed inset-0 h-100 bg-blur" style="z-index: 1050; background-color: rgba(0,0,0,0.8);">
        <div class="container px-0 h-100 text-center">
            <video
                ref="qrScannerVideoStream"
                class="rounded shadow w-100 h-100"
                @canplaythrough="$stopLoading()"
                style="object-fit: contain; object-position: center;"
                autoplay playsinline muted>
            </video>
            <transition name="fade">
                <div v-if="loadingState.default" key="loading" class="position-absolute-center text-white">
                    {{ $t('qr-scanner.loading-camera') }} ...
                </div>
                <div v-else-if="isScannerReady" key="ready" class="position-absolute-center rounded-lg opacity-50" style="width: 16rem; height: 16rem; border: 2px solid white;">
                    <o-icon :icon="['far', 'qrcode']" class="position-absolute top-0 right-0 m-2 text-white" />
                </div>
            </transition>
            <div v-if="error" key="error" class="position-absolute-center text-white opacity-75">
                {{ error }}
            </div>
            <div class="position-absolute p-4 d-flex justify-content-center" style="bottom: 0; left: 0; right: 0;">
                <OButton icon="sync-alt" :disabled="cameras.length < 2" type="glass" class="text-white mr-3" v-tooltip:top="$t('qr-scanner.toggle-camera')" @click="toggleCamera()"></OButton>
                <OButton :icon="isFlashOn ? 'lightbulb-on' : 'lightbulb-slash'" :disabled="!flashAvailable" type="glass" class="text-white mr-3" v-tooltip:top="$t('qr-scanner.toggle-flash')" @click="toggleFlash()"></OButton>
                <OButton icon="times" type="glass" class="text-white" v-tooltip:top="$t('crud.close')" @click="$emit('close')"></OButton>
            </div>
        </div>
    </div>
</template>
<script>
import { track as analyticsTrack } from '~/plugins/analytics'
import HasLoadingState from '~/mixins/hasLoadingState'
import QrScanner from 'qr-scanner'
import QrScannerWorkerPath from '!!file-loader!qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default {
    mixins: [HasLoadingState],

    props: {
        '$t': {
            type: Function
        }
    },

    data() {
        return {
            scanner: null,
            isScannerReady: false,
            camera: 'environment',
            cameras: [],
            flashAvailable: false,
            isFlashOn: false,
            error: null,
        }
    },

    created() {
        this.$startLoading()
    },

    async mounted() {
        document.addEventListener('keyup', this.globalKeyDownHandler)

        await this.$nextTick()

        if (!await QrScanner.hasCamera()) {
            this.$stopLoading()
            this.error = this.$t('qr-scanner.no-cameras')
            return
        }

        this.scanner = new QrScanner(
            this.$refs.qrScannerVideoStream, 
            (result) => this.$emit('confirm', result),
            (error) => this.error = error,
        );

        await QrScanner.listCameras(true)
            .then(cameras => this.cameras = cameras)
            .catch(error => alert(error))

        if (!this.scanner) {
            return
        }

        await this.scanner.start()

        if (!this.scanner) {
            return
        }
        
        await this.scanner.setCamera(this.camera)

        this.isScannerReady = true

        this.flashAvailable = await this.scanner.hasFlash()
        this.isFlashOn = await this.scanner.isFlashOn()

        analyticsTrack('Expense QR scanner opened')
    },

    beforeDestroy() {
        if (this.scanner) {
            this.scanner.destroy()
            this.scanner = null
        }
        
        this.isScannerReady = false
    },

    destroyed() {
        document.removeEventListener('keyup', this.globalKeyDownHandler)
    },
    
    methods: {
        async toggleCamera() {
            this.camera = this.camera === 'environment'
                ? 'user'
                : 'environment'

            if (!this.scanner) {
                return
            }

            await this.scanner.setCamera(this.camera)
            
            this.isScannerReady = true
            this.flashAvailable = await this.scanner.hasFlash()
        },

        async toggleFlash() {
            if (this.isFlashOn) {
                await this.scanner.turnFlashOff()
            } else {
                await this.scanner.turnFlashOn()
            }
        },

        globalKeyDownHandler(ev) {
            if (ev.keyCode === 27) {
                ev.preventDefault()
                this.$emit('close')
            }
        }
    }
}
</script>