<template>
    <OModal
        :title="`${$t('expense.expense')} · ${expense.name}`"
        @close="$emit('close')"
        @confirm="$emit('close')">

        <OLoading :loading="loadingState.default">
            <table v-if="expenseLocal" class="table">
                <tbody>
                    <!-- <tr>
                        <th>{{ $t('expense.total') }}</th>
                        <td class="tabular-nums">{{ $org.country.currency_code }} {{ $formatCurrency(expense.total_with_vat) }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('vat.rate') }}</th>
                        <td>{{ expense.vat_rate }}%</td>
                    </tr> -->
                    <tr>
                        <th>{{ $t('expense.number') }}</th>
                        <td>{{ expense.number }}</td>
                    </tr>
                    <!-- <tr>
                        <th>{{ $t('expense.description') }}</th>
                        <td>{{ expense.description }}</td>
                    </tr> -->
                    <tr>
                        <th>{{ $t('expense.billed-to-client') }}</th>
                        <td v-if="expenseLocal && expenseLocal.invoice">
                            <a :href="`/invoice/${expenseLocal.invoice.id}`">
                                {{ expenseLocal.invoice.number }} • {{ expenseLocal.invoice.name }}
                            </a>
                        </td>
                        <td v-else>-</td>
                    </tr>
                    <tr>
                        <th style="vertical-align: top;">{{ $t('expense.payment-information') }}</th>
                        <td>
                            <div v-if="(expense.type === 'receipt' && expense.payment_method)">{{ expense.payment_method.name }}</div>
                            <div v-else>
                                <div v-html="expenseLocal.biller_address_html" class="text-truncate"></div>
                                <label class="label mt-2 mb-0">IBAN</label>
                                <div>{{ expenseLocal.biller_iban || 'n/a' }}</div>
                                <label class="label mt-2 mb-0">{{ $t('expense.reference') }}</label>
                                <div>{{ expenseLocal.reference || 'n/a' }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $t('expense.approved_at') }}</th>
                        <td v-if="expenseLocal.approved_at">{{ $formatDateTime(expenseLocal.approved_at) }}</td>
                        <td v-else>-</td>
                    </tr>
                    <tr>
                        <th>{{ $t('expense.paid_at') }}</th>
                        <td v-if="expenseLocal.paid_at" class="text-success">{{ $formatDateTime(expenseLocal.paid_at) }}</td>
                        <td v-else>-</td>
                    </tr>
                    <tr v-if="expenseLocal.member">
                        <th>{{ $t('crud.assigned-to') }}</th>
                        <td class="text-nowrap">{{ expenseLocal.member.name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('accounting.is-booked') }}</th>
                        <td class="text-nowrap">{{ expenseLocal.is_exported ? $t('status.yes') : $t('status.no') }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('crud.updated-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(expenseLocal.updated_at) }} <span v-if="expenseLocal.updated_by"> / {{ expenseLocal.updated_by.name }}</span></td>
                    </tr>
                    <tr>
                        <th>{{ $t('crud.created-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(expenseLocal.created_at) }} <span v-if="expenseLocal.created_by"> / {{ expenseLocal.created_by.name }}</span></td>
                    </tr>
                    <tr v-if="expenseLocal.deleted_at" class="text-danger">
                        <th>{{ $t('crud.deleted-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(expenseLocal.deleted_at) }} <span v-if="expenseLocal.updated_by"> / {{ expenseLocal.updated_by.name }}</span></td>
                    </tr>
                </tbody>
            </table>
        </OLoading>
    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import HasLoadingState from '~/mixins/hasLoadingState'

export default {
    mixins: [HasLoadingState],

    components: {
        AvatarComponent
    },

    props: ['expense', '$t'],

    data() {
        return {
            expenseLocal: this.expense
        }
    },

    async created() {
        this.$startLoading()
        this.expenseLocal = await this.$store.dispatch('expense/fetchOne', {
            id: this.expense.id,
            query: {
                include: ['member.user', 'createdBy.user', 'updatedBy.user', 'invoice']
            }
        })

        this.$stopLoading()
    }
}
</script>