<template functional>
    <div class="progress-wrapper">
        <slot></slot>
        <div class="progress" :class="[data.class, data.staticClass, `progress-${props.type}`]" :style="[data.style, data.staticStyle]">
            <div class="progress-bar"
                :class="[`progress-bar-${props.type}`]"
                :style="{ 
                    width: `${100 / props.maxValue * props.value}%`,
                    marginLeft: props.reverse ? `${100 / props.maxValue * (props.maxValue - props.value)}%` : null,
                }"  
                role="progressbar" 
                :aria-valuenow="100 / props.maxValue * props.value" 
                :aria-valuemin="minValue" 
                :aria-valuemax="maxValue"
                >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        minValue: {
            type: Number,
            default: 0,
        },
        maxValue: {
            type: Number,
            default: 100,
        },
        type: {
            type: String,
            default: 'default',
        },
        reverse: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
