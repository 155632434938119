<template>
    <OModal
        :title="`${$t('webhook.webhook')} · ${$formatDateTime(webhookLog.created_at)}`"
        @close="$emit('close')"
        @confirm="$emit('close')"
        size="xl">
        
        <table class="table mb-3">
            <tbody>
                <tr>
                    <th>{{ $t('webhook.event') }}</th>
                    <td>{{ webhookLog.event }}</td>
                </tr>
                <tr v-if="webhookLog.subscription">
                    <th>{{ $t('webhook.url') }}</th>
                    <td class="monospace">{{ webhookLog.subscription.url }}</td>
                </tr>
                <tr>
                    <th>{{ $t('webhook.response-code') }}</th>
                    <td>
                        <OBadge
                            :type="webhookLog.response_code >= 400 ? 'danger' : 'success'"
                            >
                            {{ webhookLog.response_code }} {{ webhookLog.response_status }}
                        </OBadge>    
                    </td>
                </tr>
                <tr v-if="webhookLog.response_message">
                    <th>{{ $t('webhook.response-message') }}</th>
                    <td><pre class="mb-0">{{ webhookLog.response_message }}</pre></td>
                </tr>
            </tbody>
        </table>
        
        <h6>{{ $t('webhook.payload') }}</h6>
        <div class="border rounded-lg p-3 bg-ultralight overflow-auto">
            <div class="monospace" style="white-space: pre;" v-html="prettyPayload()"></div>
        </div>
    </OModal>
</template>
<script>
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/github.css'

hljs.registerLanguage('json', json)

export default {
    props: ['webhookLog', '$t'],

    methods: {
        prettyPayload() {
            return hljs.highlight(this.webhookLog.payload, { language: 'json' }).value
        }
    }
}
</script>