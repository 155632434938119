<template>
    <OModal
        v-if="errorData"
        :title="'HTTP ' + error.status + ' ' + error.statusText"
        type="danger"
        @close="$emit('close')"
        @confirm="$emit('confirm')"
        size="lg"
        hide-footer
        class="theme-dark"
        >

        <div class="prehead text-danger">{{ errorData.exception }}</div>
        <h2>{{ errorData.message }}</h2>
        <div class="mb-3">
            <a 
                :href="error.config.baseURL + error.config.url" 
                :title="error.config.baseURL + error.config.url"
                target="_blank"
                class="small"
                >
                {{ error.config.baseURL + error.config.url }}
            </a>
        </div>

        <OAlert type="light">{{ errorData.exception }} in <strong>{{ getFileName(errorData.file) }}</strong> on line <strong>{{ errorData.line }}</strong></OAlert>

        <hr class="mx-n3">

        <h6>Stack trace</h6>
        <details class="mb-1">
            <summary class="text-truncate">
                <span class="text-muted">{{ getDirectoryPath(errorData.file) }}/</span><span class="text-danger">{{ getFileName(errorData.file) }}:{{ errorData.line }}</span>
            </summary>
            <pre>{{ { message: errorData.message, exception: errorData.exception } }}</pre>
        </details>
        
        <div v-if="error && errorData & errorData.trace">
            <details
                v-for="(frame, index) in errorData.trace.filter(f => showVendorFrames || !(f.file || '').includes('/vendor/'))"
                :key="index"
                class="mb-1"
                >
                <summary class="text-truncate" :class="{ 'opacity-50': (frame.file || '').includes('/vendor/') }">
                    <span class="text-muted">{{ getDirectoryPath(frame.file) }}/</span><span>{{ getFileName(frame.file) }}:{{ frame.line }}</span>
                </summary>
                <pre>{{ frame }}</pre>
            </details>
        </div>

        <OToggle 
            label="Show vendor frames"
            v-model="showVendorFrames"
            :states="['Yes', 'No']"
            class="mt-3"
            />

    </OModal>
</template>
<script>
export default {
    props: ['error'],

    data() {
        return {
            showVendorFrames: false,
            errorData: null,
        }
    },

    created() {
        if (this.error.config.responseType === 'blob') {
            this.error.data.text().then(text => {
                this.errorData = JSON.parse(text);
            })
        } else {
            this.errorData = this.error.data;
        }
    },

    methods: {
        getFileName(path) {
            if (!path) return ''

            return path.split('/').pop()
        },

        getDirectoryPath(path) {
            if (!path) return ''

            const dir = path.split('/').slice(0, -1).join('/')
            const common = this.getCommonPath()

            return dir.replace(common, '')
        },

        getCommonPath(paths = null) {
            paths = paths || this.errorData.trace.map(t => t.file)

            // check border cases size 1 array and empty first path)
            if (!paths[0] || paths.length ==  1) return paths[0] || "";
            let i = 0;
            // while all paths have the same character at position i, increment i
            while(paths[0][i] && paths.every(w => w[i] === paths[0][i]))
                i++;
            
            // prefix is the substring from the beginning to the last successfully checked i
            return paths[0].substr(0, i - 1);
        },
    }
}
</script>