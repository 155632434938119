import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('absence', {
    include: ['member.user.media', 'approver.user.media'],
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = crudStore.actions()