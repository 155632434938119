<template>
    <OModal
        :title="$t('offer.send-to')"
        :subtitle="offer.number + (offer.name ? ' · ' + offer.name : '')"
        :icon="['fad', 'envelope-open-text']"
        :confirmText="$t('crud.send')"
        confirmIcon="paper-plane"
        :cancelText="$t('crud.cancel')"
        :allowConfirm="recipients.length > 0"
        @close="$emit('close')"
        @confirm="$emit('confirm', { recipients: recipients.join(';'), message, ccToOwner })"
        size="lg"
        >

        <form @submit.prevent="add()" class="d-flex flex-column mb-2 gap-y-2">
            <div class="form-control form-control-within d-flex gap-2 align-items-center py-0 mb-3 mb-lg-0">
                <label for="emailToAdd" class="flex-shrink-0 text-muted my-0">{{ $t('document.email-to') }}</label>
                <div
                    v-for="email in recipients"
                    :key="email"
                    class="text-black">
                    {{ email }}<span v-if="recipients.length > 1">,</span>
                </div>
                <input
                    id="emailToAdd"
                    type="email"
                    v-model="emailToAdd"
                    required
                    @blur="add()"
                    @keydown.tab.prevent="add()"
                    @keydown.backspace="emailToAdd.length === 0 && remove(recipients[recipients.length - 1])"
                    style="all: unset;"
                    class="flex-1"
                    autofocus
                    list="contacts"
                    :required="recipients.length === 0"
                    />
                <datalist id="contacts">
                    <option 
                        v-for="contact in $store.state.contact.items.filter(contact => contact.company_id === offer.company?.id)" 
                        :key="contact.id" 
                        :value="contact.email"
                        >{{ contact.name }}
                    </option>
                </datalist>
            </div>
        </form>

        <RichtextEditor 
            :placeholder="$t('document.recipients-message-text')"
            v-model="message"
            :writing-assistant="{
                type: 'document_email',
                document_type: 'offer',
                document_id: offer.id,
            }"
            :writing-assistant-prompt-help-text="$t('document.email-prompt-help-text')"
            :translate-function="$t"
            :http-client="app.$axios"
            />

        <OToggle 
            :label="$t('offer.cc-to-owner')"
            :states="[$t('crud.yes'), $t('crud.no')]" 
            v-model="ccToOwner"
            inline 
            />

        <OAlert v-if="!offer.is_locked" type="info" icon="lock-alt" class="mb-0">
            {{ $t('document.lock-info-text') }}.
        </OAlert>
    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import RichtextEditor from '~/components/editor.vue'

export default {
    components: {
        AvatarComponent,
        RichtextEditor,
    },
    
    props: ['offer', '$t', 'app'],

    data() {
        return {
            recipients: this.offer.contact.email ? [this.offer.contact.email] : [],
            emailToAdd: '',
            message: '',
            ccToOwner: false,
        }
    },

    created() {
        this.message = this.$org.settings.offerMailMessage || ''
        this.$store.dispatch('contact/fetchAll')
    },

    methods: {
        add() {
            const email = this.emailToAdd

            if (email.length === 0 || !(email.includes('@') && email.includes('.'))) {
                return
            }

            if (!this.recipients.includes(email)) {
                this.recipients.push(email)
            }

            this.emailToAdd = ''

            if (this.$refs.messageTextarea) {
                this.$refs.messageTextarea.focus()
            }
        },

        remove(email) {
            if (this.recipients.length === 0) {
                return
            }

            this.recipients.splice(this.recipients.indexOf(email), 1)
        }
    }
}
</script>