<template>
    <OModal 
        v-bind="$attrs"
        v-on="$listeners"
        :hide-footer="true"
        v-slot="{
            type,
            close,
            confirm,
            confirmText,
            confirmIcon,
            cancelText,
        }"
        >
        <OLoading :loading="loadingState.submitting">
            <form @submit.prevent="event => onSubmit(event, confirm)">
                <slot />
            
                <div class="d-flex justify-content-end gap-2">
                    <OButton
                        v-if="cancelText"
                        @click="close()"
                        type="link"
                        >
                        {{ cancelText }}
                    </OButton>
                    <OButton
                        :type="type || 'primary'"
                        submit
                        :icon="confirmIcon"
                        >
                        {{ confirmText }}
                    </OButton>
                </div>
            </form>
        </OLoading>
    </OModal>
</template>
<script>
import HasLoadingState from '@/mixins/hasLoadingState'

export default {
    inheritAttrs: false,

    mixins: [HasLoadingState],

    props: {
        beforeConfirm: {
            type: Function,
            default: null
        },

        form: {
            type: Object,
            default: () => ({})
        },
    },

    methods: {
        onSubmit(event, confirm) {
            if (this.beforeConfirm) {
                this.$startLoading('submitting')
                this.beforeConfirm(this.form)
                    .then(() => {
                        confirm(this.form)
                    })
                    .finally(() => {
                        this.$stopLoading('submitting')
                    })
            } else {
                confirm(this.form)
            }
        }
    }
};
</script>
