<template>
    <div class="search">
        <div class="search-backdrop overlay-backdrop" @click="close()"></div>
        <OContainer narrow>
            <div class="search-container overlay-container">
                <input 
                    ref="searchInput" 
                    type="search" 
                    :placeholder="$t('search.find-anything') + ' ...'" 
                    class="search-input pl-5"
                    :value="searchQuery"
                    @input="search($event.target.value)"
                    @keydown.up.prevent="prev()"
                    @keydown.down.prevent="next()"
                    @keydown.shift.tab.prevent="prev()"
                    @keydown.exact.tab.prevent="next()"
                    @keyup.enter="enterHandler()"
                    @keyup.esc="close()"
                    autofocus
                    inputmode="search"
                    enterkeyhint="search"
                    >

                <OIcon 
                    :icon="['fal', 'search']" 
                    size="lg"
                    class="position-absolute text-muted"
                    style="top: 1rem; left: 1rem; z-index: 1;"
                />

                <OButton 
                    v-if="searchQuery.length > 0" 
                    icon="times"
                    @click="clearInput()" 
                    class="position-absolute right-0" style="z-index: 1; top: 0.5rem;">
                </OButton>

                <OLoading :loading="loading" class="d-block w-100"></OLoading>

                <div v-if="groupedResults.length === 0 && noResults" class="p-3 text-muted">
                    {{ $t('search.no-results') }}
                </div>

                <div v-if="groupedResults.length > 1" class="d-none text-muted d-xl-block px-3 pt-3 pb-1">
                    <span v-html="$t('search.navigate-through')"></span>
                </div>

                <div ref="results" v-if="groupedResults.length > 0" class="search-results">
                    <div v-for="group in groupedResults" :key="group.name" class="mb-3">
                        <div class="search-result-group">{{ $t(`${group.name.toLowerCase()}.${group.name.toLowerCase()}`) }}</div>
                        <NuxtLink 
                            v-for="result in group.items" 
                            :key="result.id"
                            :ref="result.id"
                            :to="getUrlForResult(result, group.name)"
                            class="search-result"
                            :class="{ 'selected': result == selected }">
                            <AvatarComponent 
                                :image="result.image" 
                                :fallback="result.caption || result.name"
                                dark
                                class="mr-3 flex-shrink-0" />
                            <div>
                                <h6 class="search-result-title mb-0">{{ result.name }}</h6>
                                <div class="text-muted">{{ result.caption }}</div>
                            </div>
                            <div class="search-result-keyhint d-none d-md-block">
                                <div class="badge badge-dark">⏎</div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </OContainer>
    </div>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'

export default {
    components: {
        AvatarComponent,
    },

    data() {
        return {
            loading: false,
            noResults: false,
            selected: null
        }
    },

    created() {
        if (this.$route.query.search) {
            this.$store.commit('search/SET_QUERY', this.$route.query.search)
        }

        if (this.searchQuery.length > 0) {
            this.search(this.searchQuery, true)
        }
    },

    mounted() {
        this.$bindKeyboard({
            'esc': () => this.close()
        })

        this.$nextTick(() => this.$refs.searchInput.focus())
        this.$nextTick(() => this.$refs.searchInput.select())

        document.body.classList.add('modal-open')
    },

    destroyed() {
        document.body.classList.remove('modal-open')
    },

    computed: {
        searchQuery() {
            return this.$store.state.search.query
        },

        groupedResults() {
            return this.$store.getters['search/getGroupedResults']
        },

        allResults() {
            return this.$store.getters['search/getAllResults']
        }
    },

    methods: {
        async search(query, immediate = false) {
            this.$store.commit('search/SET_QUERY', query)

            if (this.searchQuery.length < 3) {
                return
            }
            
            this.loading = true

            if (!immediate) {
                await this.$debounce(400)
            }
            
            this.noResults = false
            this.selected = null

            await this.$store.dispatch('search/fetchResults')
            
            this.loading = false

            if (this.allResults.length === 0) {
                this.noResults = true
                return
            }

            if (this.allResults.length > 0) {
                this.selected = this.allResults[0]
            }
        },

        close() {
            this.$store.dispatch('search/toggleOverlay')
        },

        getUrlForResult(result) {
            const urls = {
                Product: `/${result.type.toLowerCase()}/${result.id}/edit`,
                Rate: `/${result.type.toLowerCase()}/${result.id}/edit`,
                Contact: `/company/${result.data.company_id}/${result.type.toLowerCase()}/${result.id}/edit`,
            }

            return urls[result.type] || `/${result.type.toLowerCase()}/${result.id}`
        },

        next() {
            const index = this.allResults.indexOf(this.selected) || 0
            this.selected = this.allResults[index + 1] || this.allResults[0]
        },
        
        prev() {
            const index = this.allResults.indexOf(this.selected) || 0
            this.selected = this.allResults[index - 1] || this.allResults[this.allResults.length - 1]
        },

        enterHandler() {
            if (!this.selected) {
                this.search(this.searchQuery, true)
                return
            }

            this.$router.push(this.getUrlForResult(this.selected))
        },

        clearInput() {
            this.$store.commit('search/SET_QUERY', '')
            this.$store.dispatch('search/clearResults')
            this.$nextTick(() => this.$refs.searchInput.focus())
        }
    },

    watch: {
        '$route'() {
            this.close()
        },

        async selected(selected) {
            await this.$nextTick()
            if (selected) {
                const resultElement = this.$refs[selected.id][0].$el
                this.$refs.results.scrollTop = resultElement.offsetTop - this.$refs.results.clientHeight
            }
        }
    }
}
</script>