import Vue from 'vue'
import { library as IconLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import {
    faThLarge,
    faCube,
    faCubes,
    faEllipsisV,
    faEllipsisH,
    faPlus,
    faMinus,
    faPlusCircle,
    faMinusCircle,
    faCircle,
    faCircleNotch,
    faPencil,
    faTrashAlt,
    faEye,
    faEyeSlash,
    faSearch,
    faRocket,
    faArrowRight,
    faArrowToRight,
    faArrowLeft,
    faArrowToLeft,
    faArrowUp,
    faArrowDown,
    faArrowToBottom,
    faArrowFromBottom,
    faArrowsV,
    faExchange,
    faStream,
    faClipboardListCheck,
    faClipboardCheck,
    faCheck,
    faCheckCircle,
    faLayerPlus,
    faBars,
    faHome,
    faClock,
    faTruck,
    faUser,
    faUserCircle,
    faUserCog,
    faUserPlus,
    faUserMinus,
    faIndustry,
    faServer,
    faTimes,
    faTimesCircle,
    faTachometerAlt,
    faArrows,
    faSort,
    faSortDown,
    faSortUp,
    faSortAmountUp,
    faSortAmountDown,
    faFilter,
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faListAlt,
    faTag,
    faExclamationTriangle,
    faExclamationCircle,
    faQuestionCircle,
    faUsers,
    faAddressCard,
    faGripVertical,
    faSignIn,
    faSignOut,
    faCog,
    faCogs,
    faGenderless,
    faPaperPlane,
    faAt,
    faKey,
    faFileInvoiceDollar,
    faFileSearch,
    faQuoteLeft,
    faUndo,
    faCalendarAlt,
    faSyncAlt,
    faUserClock,
    faUserShield,
    faTable,
    faLineHeight,
    faBold,
    faItalic,
    faUnderline,
    faParagraph,
    faListUl,
    faListOl,
    faHeading,
    faTags,
    faClone,
    faArchive,
    faFilePdf,
    faFileExcel,
    faFileAlt,
    faPageBreak,
    faPhone,
    faMobile,
    faHourglass,
    faShare,
    faLockAlt,
    faUnlockAlt,
    faInfoCircle,
    faCreditCard,
    faReceipt,
    faChartPie,
    faChartLine,
    faChartBar,
    faBookmark,
    faHeartRate,
    faBellPlus,
    faFileInvoice,
    faAlarmExclamation,
    faPercentage,
    faBadgePercent,
    faShoppingCart,
    faCommentLines,
    faCommentAltPlus,
    faCheckSquare,
    faSquare,
    faSquareFull,
    faLayerGroup,
    faCode,
    faFileImport,
    faWifi,
    faWifiSlash,
    faThumbsDown,
    faThumbsUp,
    faBrowser,
    faQrcode,
    faAlignLeft,
    faAlignRight,
    faAlignCenter,
    faBalanceScale,
    faBan,
    faEnvelope,
    faSortNumericUp,
    faKeyboard,
    faRedo,
    faCrown,
    faExternalLink,
    faPalette,
    faSpinner,
    faSpinnerThird,
    faFileExport,
    faFileCsv,
    faForward,
    faGasPump,
    faLightbulbOn,
    faLightbulbSlash,
    faFileUpload,
    faFile,
    faGripLines,
    faDownload,
    faShieldAlt,
    faICursor,
    faSlidersH,
    faPercent,
    faEnvelopeOpenText,
    faBook,
    faBooks,
    faUnlink,
    faSparkles,
    faMailBulk,
    faBell,
    faCopy,
    faLink,
    faMoneyCheckEdit,
    faRepeat,
    faReply,
    faTasksAlt,
    faCalculator,
    faUserEdit,
    faUniversity,
    faCoins,
    faIslandTropical,
    faHouseLeave,
    faUsersClass,
    faRocketLaunch,
    faVideo,
    faVideoSlash,
    faSun,
    faMoon,
    faSigma,
    faFolderOpen,
    faFolderTree,
    faMoneyBillWave,
    faLineColumns,
    faLocationArrow,
    faStar,
    faPaperclip,
    faDesktop,
    faLandmark,
    faCropAlt,
} from '@fortawesome/pro-regular-svg-icons'

// import {
//     faCaretDown as fasCaretDown,
// } from "@fortawesome/free-solid-svg-icons"

import {
    faFlame as falFlame,
    faUserCircle as falUserCircle,
    faBell as falBell,
    faCube as falCube,
    faFastForward as falFastForfaFastForward,
    faFileInvoiceDollar as falFileInvoiceDollar,
    faQuoteLeft as falQuoteLeft,
    faAddressCard as falAddressCard,
    faTags as falTags,
    faChartBar as falChartBar,
    faUser as falUser,
    faUsers as falUsers,
    faCog as falCog,
    faHeartRate as falHeartRate,
    faCircle as falCircle,
    faUserClock as falUserClock,
    faLayerPlus as falLayerPlus,
    faBuilding as falBuilding,
    faUsersClass as falUsersClass,
    faReceipt as falReceipt,
    faCreditCard as falCreditCard,
    faCheckCircle as falCheckCircle,
    faSearch as falSearch,
    faClock as falClock,
    faAlarmExclamation as falAlarmExclamation,
    faBars as falBars,
    faTimes as falTimes,
    faCommentLines as falCommentLines,
    faChartPie as falChartPie,
    faCogs as falCogs,
    faMoneyBillWave as falMoneyBillWave,
    faEnvelopeOpenDollar as falEnvelopeOpenDollar,
    faDatabase as falDatabase,
    faMailBulk as falMailBulk,
    faQuestionCircle as falQuestionCircle,
    faTerminal as falTerminal,
    faGlobe as falGlobe,
    faCalculator as falCalculator,
    faIslandTropical as falIslandTropical,
    faUniversity as falUniversity,
} from '@fortawesome/pro-light-svg-icons'

import {
    faApple as fabApple,
    faGoogle as fabGoogle,
    faCcVisa as fabCcVisa,
    faCcMastercard as fabCcMastercard,
    faCcAmex as fabCcAmex,
    faWpforms as fabWpforms,
} from "@fortawesome/free-brands-svg-icons";

import {
    faCube as fadCube,
    faBarcodeRead as fadBarcodeRead,
    faFlask as fadFlask,
    faCode as fadCode,
    faTasksAlt as fadTasksAlt,
    faSparkles as fadSparkles,
    faShoppingCart as fadShoppingCart,
    faSackDollar as fadSackDollar,
    faWeightHanging as fadWeightHanging,
    faReceipt as fadReceipt,
    faFileInvoiceDollar as fadFileInvoiceDollar,
    faEnvelopeOpenDollar as fadEnvelopeOpenDollar,
    faEnvelopeOpenText as fadEnvelopeOpenText,
    faBook as fadBook,
} from "@fortawesome/pro-duotone-svg-icons";

IconLibrary.add(
    faRocket,
    faThLarge,
    faCube,
    faCubes,
    faEllipsisV,
    faEllipsisH,
    faPlus,
    faMinus,
    faPlusCircle,
    faMinusCircle,
    faCircle,
    faCircleNotch,
    faPencil,
    faTrashAlt,
    faEye,
    faEyeSlash,
    faSearch,
    faArrowRight,
    faArrowToRight,
    faArrowLeft,
    faArrowToLeft,
    faArrowUp,
    faArrowDown,
    faArrowToBottom,
    faArrowFromBottom,
    faArrowsV,
    faExchange,
    faStream,
    faClipboardListCheck,
    faClipboardCheck,
    faCheck,
    faCheckCircle,
    faLayerPlus,
    faTachometerAlt,
    faBars,
    faHome,
    faClock,
    faTruck,
    faUser,
    faUserCircle,
    faUserCog,
    faUserPlus,
    faUserMinus,
    faIndustry,
    faServer,
    faTimes,
    faTimesCircle,
    faArrows,
    faSort,
    faSortDown,
    faSortUp,
    faSortAmountUp,
    faSortAmountDown,
    faFilter,
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faListAlt,
    faTag,
    faExclamationTriangle,
    faExclamationCircle,
    faQuestionCircle,
    faUsers,
    faAddressCard,
    faGripVertical,
    faSignIn,
    faSignOut,
    faCog,
    faCogs,
    faGenderless,
    faPaperPlane,
    faAt,
    faKey,
    faFileInvoiceDollar,
    faFileSearch,
    faQuoteLeft,
    faUndo,
    faCalendarAlt,
    faSyncAlt,
    faUserClock,
    faUserShield,
    faTable,
    faLineHeight,
    faBold,
    faItalic,
    faUnderline,
    faParagraph,
    faListUl,
    faListOl,
    faHeading,
    faTags,
    faClone,
    faArchive,
    faFilePdf,
    faFileExcel,
    faFileAlt,
    faPageBreak,
    faPhone,
    faMobile,
    faHourglass,
    faShare,
    faLockAlt,
    faUnlockAlt,
    faInfoCircle,
    faCreditCard,
    faReceipt,
    faChartPie,
    faChartLine,
    faChartBar,
    faBookmark,
    faHeartRate,
    faBellPlus,
    faFileInvoice,
    faAlarmExclamation,
    faPercentage,
    faBadgePercent,
    faShoppingCart,
    faCommentLines,
    faCommentAltPlus,
    faCheckSquare,
    faSquare,
    faSquareFull,
    faLayerGroup,
    faCode,
    faFileImport,
    faWifi,
    faWifiSlash,
    faThumbsDown,
    faThumbsUp,
    faBrowser,
    faQrcode,
    faAlignLeft,
    faAlignRight,
    faAlignCenter,
    faBalanceScale,
    faBan,
    faEnvelope,
    faSortNumericUp,
    faKeyboard,
    faRedo,
    faCrown,
    faExternalLink,
    faPalette,
    faSpinner,
    faSpinnerThird,
    faFileExport,
    faFileCsv,
    faForward,
    faGasPump,
    faLightbulbOn,
    faLightbulbSlash,
    faFileUpload,
    faFile,
    faGripLines,
    faDownload,
    faShieldAlt,
    faICursor,
    faSlidersH,
    faPercent,
    faEnvelopeOpenText,
    faBook,
    faBooks,
    faUnlink,
    faSparkles,
    faMailBulk,
    faBell,
    faCopy,
    faLink,
    faMoneyCheckEdit,
    faRepeat,
    faReply,
    faTasksAlt,
    faCalculator,
    faUserEdit,
    faUniversity,
    faCoins,
    faIslandTropical,
    faHouseLeave,
    faUsersClass,
    faRocketLaunch,
    faVideo,
    faVideoSlash,
    faSun,
    faMoon,
    faSigma,
    faFolderOpen,
    faFolderTree,
    faMoneyBillWave,
    faLineColumns,
    faLocationArrow,
    faStar,
    faPaperclip,
    faDesktop,
    faLandmark,
    faCropAlt,

    falFlame,
    falUserCircle,
    falBell,
    falCube,
    falFastForfaFastForward,
    falFileInvoiceDollar,
    falQuoteLeft,
    falAddressCard,
    falTags,
    falChartBar,
    falUser,
    falUsers,
    falCog,
    falHeartRate,
    falCircle,
    falUserClock,
    falLayerPlus,
    falBuilding,
    falUsersClass,
    falReceipt,
    falCreditCard,
    falCheckCircle,
    falSearch,
    falClock,
    falAlarmExclamation,
    falBars,
    falTimes,
    falCommentLines,
    falChartPie,
    falCogs,
    falMoneyBillWave,
    falEnvelopeOpenDollar,
    falDatabase,
    falMailBulk,
    falQuestionCircle,
    falTerminal,
    falGlobe,
    falCalculator,
    falIslandTropical,
    falUniversity,

    fabApple,
    fabGoogle,
    fabCcVisa,
    fabCcMastercard,
    fabCcAmex,
    fabWpforms,

    fadCube,
    fadBarcodeRead,
    fadFlask,
    fadCode,
    fadTasksAlt,
    fadSparkles,
    fadShoppingCart,
    fadReceipt,
    fadSackDollar,
    fadWeightHanging,
    fadFileInvoiceDollar,
    fadEnvelopeOpenDollar,
    fadEnvelopeOpenText,
    fadBook,
)

Vue.component('OIcon', FontAwesomeIcon)
Vue.component('o-icon-layers', FontAwesomeLayers)
Vue.component('o-icon-layers-text', FontAwesomeLayersText)

export default IconLibrary
