<template>
    <output 
        class="toast theme-dark" 
        :class="{ 'align-items-center': !($slots.default || toast.message), 'toast-failed': failed }"
        role="status"
        >
        <div ref="countdown" v-if="lifetime && !persist && !until" class="toast-countdown">
            <div :style="{ transitionDuration: `${Math.round(lifetime/1000)}s` }"></div>
        </div>

        <transition name="slide-down" mode="out-in">
            <div v-if="toast.icon || defaultIcon" :key="toast.icon || defaultIcon" :class="`text-${toast.type}`" class="mr-3">
                <OIcon :icon="['far', toast.icon || defaultIcon]" size="lg" :spin="toast.icon && toast.icon === 'spinner'" />
            </div>
        </transition>
        <div class="mr-3">
            <h6 v-if="toast.title" class="m-0">{{ toast.title }}</h6>
            <div v-if="$slots.default || toast.message" class="toast-message">
                <slot>
                    <ul v-if="Array.isArray(toast.message)" class="pl-3 m-0">
                        <li v-for="(m, i) in toast.message" :key="i">{{ m }}</li>
                    </ul>
                    <div v-else v-html="toast.message"></div>
                </slot>
            </div>
            <div v-if="action" class="mt-3">
                <OButton :type="action.type || 'primary'" size="sm" class="text-nowrap" @click.stop="runActionHandler(action)" :icon="action.icon || null" :disabled="actionPending">
                    {{ action.label }}
                </OButton>
                <OButton type="secondary" size="sm" class="text-nowrap" @click="cancel()" :disabled="actionPending">
                    {{ cancelLabel || $t('crud.cancel') }}
                </OButton>
            </div>
        </div>
        <OLoading :loading="(toast.loading || actionPending) ? true : false" class="position-absolute w-100" style="bottom: 0; left: 0;"></OLoading>
    </output>
</template>
<script>
import OButton from './Button.vue'
export default {
    components: {
        OButton
    },

    props: {
        title: {
            type: String,
            default: null
        },
        message: {
            type: [String, Array],
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        persist: {
            type: Boolean,
            default: false,
        },
        lifetime: {
            type: Number,
            default: 5000,
        },
        until: {
            type: Promise,
            default: null,
        },
        untilSuccess: {
            type: Function,
            default: null,
        },
        untilFailed: {
            type: Function,
            default: null,
        },
        action: {
            type: Object,
            default: null,
        },
        cancelLabel: {
            type: String,
            default: null,
        },
        onCancel: {
            type: Function,
            default: null,
        }
    },

    data() {
        return {
            failed: false,
            actionPending: false,
            toast: {
                title: this.title,
                message: this.message,
                type: this.type,
                icon: this.icon,
                loading: false,
            }
        }
    },

    mounted() {
        if (this.until) {
            this.toast.loading = true

            this.until.then((data) => {
                if (this.untilSuccess) {
                    setTimeout(() => {
                        const { title, message, icon, type, lifetime } = this.untilSuccess(data)
                        this.toast.title = title || this.title
                        this.toast.message = message || this.message
                        this.toast.icon = icon || 'check'
                        this.toast.type = type || 'success'
                        this.toast.loading = false

                        setTimeout(() => this.$emit('dismiss'), lifetime || 3000)
                    }, 1000)
                } else {
                    setTimeout(() => this.$emit('dismiss'), 1000)
                }
            }).catch(error => {
                if (this.untilFailed) {
                    setTimeout(() => {
                        const { title, message, icon, type, lifetime } = this.untilFailed(error)
                        this.toast.title = title || this.title
                        this.toast.message = message || this.message
                        this.toast.icon = icon || 'exclamation-triangle'
                        this.toast.type = type || 'danger'
                        this.toast.loading = false

                        this.failed = true
                        setTimeout(() => this.$emit('dismiss'), lifetime || 5000)
                    }, 1000)
                } else {
                    setTimeout(() => {
                        this.$emit('dismiss')
                        this.failed = true
                    }, 1000)
                }
            })
        } else if (!this.persist) {
            setTimeout(() => this.$emit('dismiss'), this.lifetime)
            setTimeout(() => {
                if (this.$refs.countdown) {
                    this.$refs.countdown.classList.add('toast-countdown-start')
                }
            }, 100)
        }
    },

    computed: {
        createdAt() {
            const d = new Date();
            const hour = d.getHours() < 10
                ? `0${d.getHours()}`
                : d.getHours();

            const minute = d.getMinutes() < 10
                ? `0${d.getMinutes()}`
                : d.getMinutes();

            const second = d.getSeconds() < 10
                ? `0${d.getSeconds()}`
                : d.getSeconds();

            return `${hour}:${minute}:${second}`;
        },

        defaultIcon() {
            if (this.type === 'success') return 'check'
            if (this.type === 'info') return 'info-circle'
            if (this.type === 'warning') return 'exclamation-triangle'
            if (this.type === 'danger') return 'exclamation-triangle'
            return null
        }
    },

    methods: {
        runActionHandler(action) {
            const result = action.handler()

            if (result instanceof Promise) {
                this.actionPending = true
                result.then(() => {
                    setTimeout(() => {
                        this.actionPending = false
                        this.$emit('dismiss')
                    }, 500)
                }).catch(() => {
                    this.actionPending = false
                    this.failed = true
                    setTimeout(() => this.$emit('dismiss'), 3000)
                })
            } else {
                this.$emit('dismiss')
            }
        },

        cancel() {
            if (this.onCancel) {
                this.onCancel()
            }
            
            this.$emit('dismiss')
        }
    }
}
</script>
