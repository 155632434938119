<template>
    <OForm-group :label="label" :required="required" :help-text="helpText">
        <textarea 
            ref="input"
            :type="type"
            class="form-control" 
            :class="inputClass"
            :style="monospace ? 'font-family: monospace;' : ''"
            :id="`form-textarea-${label}`" 
            :aria-describedby="`form-textarea-${label}-help`" 
            :placeholder="placeholder"
            :value="value"
            :required="required"
            :cols="cols"
            :rows="rows"
            :maxlength="max"
            :wrap="wrap"
            @input="$emit('input', $event.target.value)">
        </textarea>
    </OForm-group>
</template>
<script>
import OFormGroup from './Group.vue'

export default {
    components: {
        OFormGroup
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        value: {
            default: null,
        },
        rows: {
            type: Number,
            default: 5,
        },
        cols: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        helpText: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        monospace: {
            type: Boolean,
            default: false,
        },
        max: {
            type: [String, Number],
            default: null,
        },
        wrap: {
            type: String,
            default: null,
        },
        inputClass: {
            type: String,
            default: null,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        focus: function () {
            this.$refs.input.focus()
        }
    },

    mounted() {
        if (this.autofocus) {
            this.focus()
        }
    }
}
</script>
