<template>
    <OLoading :loading="loading">
        <OSearch
            ref="searchInput"
            :value="value"
            @input="$event => {
                $emit('input', $event)
                isSearching = false
            }"
            :options="filteredAccounts"
            v-bind="$attrs"
            @search="isSearching = $event.length > 0"
            :search-by="['number']"
            :null-option="allowNull ? $t('crud.none') : null"
            :style="{
                'max-width': compact ? '85px' : 'none',
            }"
            >
            <template #selected="{ option }">
                <div class="badge font-weight-semibold tabular-nums text-right" :class="{
                    'badge-info': option.type === 'active',
                    'badge-warning': option.type === 'passive',
                    'badge-success': option.type === 'revenue',
                    'badge-danger': option.type === 'expense',
                    'badge-notice': option.type === 'income_statement',
                    'badge-secondary': option.type === 'balance_sheet',
                    'badge-dot': simple,
                    'ml-n2': !simple,
                }">{{ simple ? '' : option.number }}</div>
                <div v-if="!compact" class="text-truncate">{{ option.label }}</div>
            </template>
            <template #default="{ option }">
                <div 
                    class="d-flex align-items-start gap-2"
                    :class="{
                        'bg-light py-2 mx-n3 mb-n2 px-3': option.type === 'class',
                    }">
                    <div v-if="!option.disabled" class="badge font-weight-semibold tabular-nums text-right" :class="{
                        'badge-info': option.type === 'active',
                        'badge-warning': option.type === 'passive',
                        'badge-success': option.type === 'revenue',
                        'badge-danger': option.type === 'expense',
                        'badge-notice': option.type === 'income_statement',
                        'badge-secondary': option.type === 'balance_sheet',
                        'badge-dot mt-15': simple,
                    }">{{ simple ? '' : option.number }}</div>
                    <div>
                        <h6 v-if="option.type === 'class'" class="my-0">{{ option.label }}</h6>
                        <h6 v-else-if="option.type === 'section'" class="my-0">{{ option.label }}</h6>
                        <span v-else-if="option.type === 'group'">{{ option.label }}</span>
                        <span v-else>{{ option.label }}</span>
                        <div class="text-muted">{{ option.description }}</div>
                    </div>
                    <!-- <div v-if="simple" class="text-muted tabular-nums ml-auto">{{ option.number }}</div> -->
                </div>
            </template>
        </OSearch>
    </OLoading>
</template>
<script>
import CrudListData from '~/components/crud/list-data.vue'
import { stringToColor } from '~/util/helpers'

export default {
    components: {
        CrudListData,
    },

    props: {
        value: {
            type: [Number, String],
            default: null
        },
        type: {
            type: [String, Array],
            default: null
        },
        hiddenType: {
            type: [String, Array],
            default: null,
        },
        group: {
            type: [String, Array],
            default: null,
        },
        subGroup: {
            type: [String, Array],
            default: null,
        },
        useId: {
            type: Boolean,
            default: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        allowNull: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isSearching: false,
        }
    },

    created() {
        this.$store.dispatch('accountList/fetchAccounts')
            .then(() => {
                if (this.autofocus) {
                    this.$nextTick(() => this.$refs.searchInput.focus())
                }
            })
    },

    computed: {
        accounts() {
            return this.$store.state.accountList.accounts
        },

        loading() {
            return this.$store.state.accountList.loading
        },

        filteredAccounts() {
            return this.accounts
                .filter(account => {
                    if (!this.type) {
                        return true
                    }

                    const type = Array.isArray(this.type) ? this.type : [this.type]
                    const hiddenType = Array.isArray(this.hiddenType) ? this.hiddenType : (this.hiddenType ? [this.hiddenType] : [])

                    if (
                        (this.isSearching || this.accounts.filter(a => hiddenType.includes(a.type)).find(a => this.getValue(a) == this.value)) 
                        && hiddenType.includes(account.type)
                    ) {
                        return true
                    }

                    return type.includes(account.type)

                })
                .filter(account => {
                    if (!this.group) {
                        return true
                    }

                    const group = Array.isArray(this.group) ? this.group : [this.group]

                    return group.includes(account.account_group)
                })
                .filter(account => {
                    if (!this.subGroup) {
                        return true
                    }

                    const subGroup = Array.isArray(this.subGroup) ? this.subGroup : [this.subGroup]

                    return subGroup.includes(account.account_subgroup)
                })
                .map(account => ({
                    value: this.getValue(account).toString(),
                    label: account.name,
                    ...account
                }))
        },
    },

    methods: {
        stringToColor,

        getValue(account) {
            return account[this.getValueProperty(account)]
        },

        getValueProperty() {
            return this.useId ? 'id' : 'number'
        },

        focus() {
            setTimeout(() => this.$refs.searchInput.focus(), 10)
        },
    },
}
</script>