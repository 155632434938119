<template>
    <OFormGroup @click.native="focus()" :label="label" :required="required" :help-text="helpText">
        <div v-if="disabled" v-html="value" class="form-control bg-light" style="min-height: 5rem;"></div>
        <div v-else class="editor" @click.stop>
            <div v-if="!hideToolbar" class="menubar d-flex gap-1">
                <button
                    v-if="withMentions"
                    class="btn btn-sm"
                    @click.prevent="editor.chain().focus().insertContent('@').run()"
                    v-tooltip="t('editor.mention')">
                    <OIcon :icon="['far', 'at']" />
                </button>
                <button
                    v-if="controls.includes('bold')"
                    class="btn btn-sm"
                    :class="{ 'btn-darkish text-white': editor.isActive('bold') }"
                    @click.prevent="editor.chain().focus().toggleBold().run()"
                    v-tooltip="t('editor.bold')">
                    <OIcon :icon="['far', 'bold']" />
                </button>
                <button
                    v-if="controls.includes('italic')"
                    class="btn btn-sm"
                    :class="{ 'btn-darkish text-white': editor.isActive('italic') }"
                    @click.prevent="editor.chain().focus().toggleItalic().run()"
                    v-tooltip="t('editor.italic')">
                    <OIcon :icon="['far', 'italic']" />
                </button>
                <button
                    v-if="controls.includes('underline')"
                    class="btn btn-sm"
                    :class="{ 'btn-darkish text-white': editor.isActive('underline') }"
                    @click.prevent="editor.chain().focus().toggleUnderline().run()"
                    v-tooltip="t('editor.underline')">
                    <OIcon :icon="['far', 'underline']" />
                </button>
                <button
                    v-if="controls.includes('blockquote')"
                    class="btn btn-sm"
                    :class="{ 'btn-darkish text-white': editor.isActive('blockquote') }"
                    @click.prevent="editor.chain().focus().toggleBlockquote().run()"
                    v-tooltip="t('editor.blockquote')">
                    <OIcon :icon="['far', 'quote-left']" />
                </button>
                <button
                    v-if="controls.includes('bulletList')"
                    class="btn btn-sm"
                    :class="{ 'btn-darkish text-white': editor.isActive('bulletList') }"
                    @click.prevent="editor.chain().focus().toggleBulletList().run()"
                    v-tooltip="t('editor.bullet-list')">
                    <OIcon :icon="['far', 'list-ul']" />
                </button>
                <button
                    v-if="controls.includes('orderedList')"
                    class="btn btn-sm"
                    :class="{ 'btn-darkish text-white': editor.isActive('orderedList') }"
                    @click.prevent="editor.chain().focus().toggleOrderedList().run()"
                    v-tooltip="t('editor.ordered-list')">
                    <OIcon :icon="['far', 'list-ol']" />
                </button>

                <button
                    v-if="writingAssistant && $org.addons.includes('experimental_features')"
                    class="btn btn-sm text-notice ml-auto"
                    type="button"
                    @click.prevent="toggleWritingAssistant()"
                    >
                    <OIcon :icon="['fad', 'sparkles']" class="mr-05" />
                    <span>{{ t('editor.writing-assistant') }}</span>
                </button>
            </div>
            <div v-if="writingAssistantIsOpen" class="menubar pt-0 border-top-0 rounded-top-0">
                <OLoading 
                    :loading="loadingState.writingAssistant" 
                    >
                    <div class="position-relative">
                        <textarea
                            ref="writingAssistantInput"
                            v-model="writingAssistantPrompt"
                            :placeholder="writingAssistantPromptHelpText"
                            :rows="3"
                            class="form-control form-control-notice pb-5"
                            max-length="200"
                            @keydown.enter.prevent="startWritingAssistant()"
                            style="resize: none;"
                            @focus="$refs.writingAssistantSettingsDropdown.close()"
                            >
                        </textarea>
                        <div class="position-absolute left-0 right-0 bottom-0 m-2 d-flex align-items-center gap-2">
                            <div class="btn-group">
                                <OButton
                                    :type="writingAssistantResponse ? 'light' : 'notice'"
                                    :icon="writingAssistantResponse ? 'repeat' : 'sparkles'"
                                    size="sm"
                                    :disabled="writingAssistantPrompt.length <= 10"
                                    @click="startWritingAssistant()"
                                    >
                                    <span>{{ writingAssistantResponse ? t('editor.writing-assistant-rewrite') : t('editor.writing-assistant-write') }}</span>
                                </OButton>

                                <ODropdown
                                    ref="writingAssistantSettingsDropdown"
                                    >
                                    <template #toggle>
                                        <OButton 
                                            :type="writingAssistantResponse ? 'light' : 'notice'"
                                            icon="cog"
                                            size="sm"
                                            :disabled="writingAssistantPrompt.length <= 10"
                                            class="rounded-left-0"
                                            :class="writingAssistantResponse ? 'border-left-0' : 'border-notice'"
                                            />
                                    </template>

                                    <ODropdownHeader class="mb-0 pb-0">{{ t('editor.writing-assistant-tone') }}</ODropdownHeader>
                                    <div class="dropdown-text">
                                        <ORadio
                                            v-model="writingAssistantTone"
                                            :options="[
                                                { value: 'professional', label: t('editor.writing-assistant-tones.professional') },
                                                { value: 'informal', label: t('editor.writing-assistant-tones.informal') },
                                                { value: 'advertorial', label: t('editor.writing-assistant-tones.advertorial') },
                                                { value: 'friendly', label: t('editor.writing-assistant-tones.friendly') },
                                                { value: 'technical', label: t('editor.writing-assistant-tones.technical') }
                                            ]"
                                            class="m-0"
                                        />
                                    </div>
                              
                                    <ODropdownHeader v-if="writingAssistantOutputTypes.length > 1" class="mb-0 pb-0">{{ t('editor.writing-assistant-output-type') }}</ODropdownHeader>
                                    <div v-if="writingAssistantOutputTypes.length > 1" class="dropdown-text">
                                        <ORadio
                                            v-model="writingAssistantOutputType"
                                            :options="[
                                                { value: 'text', label: t('editor.writing-assistant-output-types.text') },
                                                { value: 'list', label: t('editor.writing-assistant-output-types.list') }
                                            ]"
                                            class="m-0"
                                        />
                                    </div>
                                </ODropdown>
                            </div>

                            <span 
                                class="badge" 
                                v-tooltip="t('editor.writing-assistant-tone')"
                                ><OIcon :icon="['far', 'user']" fixed-width /> <span class="d-none d-xl-inline">{{ t('editor.writing-assistant-tones.' + writingAssistantTone) }}</span>
                            </span>
                            <span 
                                v-if="writingAssistantOutputTypes.length > 1" 
                                class="badge" 
                                v-tooltip="t('editor.writing-assistant-output-type')"
                                ><OIcon :icon="['far', 'align-left']" fixed-width /> <span class="d-none d-xl-inline">{{ t('editor.writing-assistant-output-types.' + writingAssistantOutputType) }}</span>
                            </span>

                            <OButton 
                                type="link"
                                size="sm"
                                @click="closeWritingAssistant()"
                                class="ml-auto"
                                >{{ t('crud.cancel') }}
                            </OButton>
                        </div>
                    </div>
                </OLoading>

                <div v-if="writingAssistantResponse" class="text-wrap px-1 mt-2 formatted-content">
                    <span class="text-notice" style="background-color: rgb(124 58 237 / 0.1);" v-html="$nl2br(writingAssistantResponse)"></span>
                    <span v-if="!writingAssistantResponseDone" class="blinking-cursor"></span>
                </div>

                <div 
                    v-if="writingAssistantResponse && writingAssistantResponseDone"
                    class="d-flex gap-2 mt-2 ml-1 mb-1">
                    <OButton
                        ref="writingAssistantAcceptButton"
                        type="notice"
                        icon="check"
                        size="sm"
                        @click="insertWritingAssistantResponse()"
                        >
                        {{ t('editor.writing-assistant-accept') }}
                    </OButton>

                    <OButton
                        type="light"
                        icon="sparkles"
                        size="sm"
                        @click="improveWritingAssistantResponse()"
                        >
                        {{ t('editor.writing-assistant-improve') }}
                    </OButton>
                </div>
            </div>
            <EditorContent :editor="editor" />
        </div>
    </OFormGroup>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import Mention from '@tiptap/extension-mention'
import Link from '@tiptap/extension-link'
import hasLoadingState from '@/mixins/hasLoadingState'
import suggestion from '@/util/mention-suggestion'

export default {
    mixins: [hasLoadingState],

	components: {
        EditorContent,
    },
    
    props: {
        id: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: null,
        },
        controls: {
            type: Array,
            default: () => [
                'bold',
                'italic',
                'underline',
                'blockquote',
                'bulletList',
                'orderedList',
            ],
        },
        writingAssistant: {
            type: Object,
            default: null,
        },
        writingAssistantPromptHelpText: {
            type: String,
            default: null,
        },
        writingAssistantOutputTypes: {
            type: Array,
            default: () => ['text', 'list'],
        },
        translateFunction: {
            type: Function,
            default: null,
        },
        hideToolbar: {
            type: Boolean,
            default: false,
        },
        httpClient: {
            type: Function,
            default: null,
        },
        editorClass: {
            type: String,
            default: null,
        },
        submitOnEnter: {
            type: Boolean,
            default: false,
        },
        withMentions: {
            type: [Boolean, Array],
            default: false,
        },
        withLinks: {
            type: Boolean,
            default: false,
        },
    },

	data() {
		return {
			// Create an `Editor` instance with some default content. The editor is
			// then passed to the `EditorContent` component as a `prop`
			editor: new Editor({
                content: this.value ? this.$nl2br(this.value) : '',
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML())
                },
                editorProps: {
                    attributes: {
                        class: this.editorClass,
                    },
                    handleDOMEvents: {
                        keydown: (view, event) => {
                            if (event.key === 'Enter' && !event.shiftKey && event.metaKey) {
                                event.preventDefault()
                                this.$emit('submit')

                                return false
                            }
                        },
                    },
                },
                extensions: [
                    StarterKit,
                    Underline,
                    Placeholder.configure({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        placeholder: this.placeholder,
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),

                    this.withLinks ? Link.configure({
                        protocols: ['ftp', 'mailto'],
                    }) : null,

                    this.withMentions ? Mention.configure({
                        HTMLAttributes: {
                            class: 'mention',
                        },
                        renderLabel({ options, node }) {
                            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
                        },
                        suggestion: {
                            ...suggestion,
                            items: ({ query }) => this.withMentions
                                .filter(user => user.label.toLowerCase().startsWith(query.toLowerCase()))
                                .slice(0, 5),
                        },
                    }) : null,
                ]
			}),
            writingAssistantIsOpen: false,
            writingAssistantPrompt: '',
            writingAssistantResponse: null,
            writingAssistantResponseDone: false,
            writingAssistantTone: 'professional',
            writingAssistantOutputType: 'text',
		}
    },

    activated() {
        this.updateContent()
    },

	beforeDestroy() {
		this.editor.destroy()
    },
    
    methods: {
        focus() {
            this.$nextTick(() => this.editor.chain().focus().run())
        },

        updateContent() {
            this.editor.commands.setContent(this.value ? this.$nl2br(this.value) : '', false)
        },

        async startWritingAssistant(data = {}) {
            this.$refs.writingAssistantSettingsDropdown?.close()

            this.$startLoading('writingAssistant')

            const http = this.httpClient || this.$axios
            const streamedResponse = await fetch(http.defaults.baseURL + 'ai/writing-assistant', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: this.writingAssistantPrompt,
                    context: this.writingAssistant,
                    tone: this.writingAssistantTone,
                    outputType: this.writingAssistantOutputTypes.length === 1
                        ? this.writingAssistantOutputTypes[0]
                        : this.writingAssistantOutputType,
                    ...data,
                }),
            })

            const reader = streamedResponse.body.getReader()
            this.writingAssistantResponse = ''
            this.writingAssistantResponseDone = false

            while (!this.writingAssistantResponseDone) {
                const { done, value } = await reader.read()

                if (done) {
                    this.writingAssistantResponseDone = true
                } else if (value) {
                    this.writingAssistantResponse += new TextDecoder('utf-8').decode(value)
                }
            }

            this.$stopLoading('writingAssistant')

            this.$nextTick(() => {
                this.$refs.writingAssistantAcceptButton?.focus()
            })

            if (this.$analyticsTrack) {
                this.$analyticsTrack('AI Writing Assistant', {
                    context: this.writingAssistant.type || '',
                    outputType: this.writingAssistantOutputType,
                    tone: this.writingAssistantTone,
                })
            }
        },

        async improveWritingAssistantResponse() {
            const prompt = await this.$modal({ text: this.writingAssistantResponse }, 'ai-assistant/prompt')

            if (prompt) {
                this.startWritingAssistant({
                    prompt: `${this.writingAssistantResponse}
---
${prompt}`.trim(),
                    context: {
                        type: 'generic',
                    }
                })
            }
        },

        insertWritingAssistantResponse() {
            this.editor.commands.insertContent(this.writingAssistantResponse)
            this.closeWritingAssistant()
        },

        toggleWritingAssistant() {
            this.writingAssistantIsOpen 
                ? this.closeWritingAssistant() 
                : this.openWritingAssistant()
        },

        openWritingAssistant() {
            this.writingAssistantIsOpen = true
            this.$nextTick(() => this.$refs.writingAssistantInput.focus())
        },

        closeWritingAssistant() {
            this.$refs.writingAssistantSettingsDropdown?.close()
            this.writingAssistantIsOpen = false
            this.writingAssistantResponse = null
        },

        t(key, values) {
            if (this.translateFunction) {
                return this.translateFunction(key, values)
            }

            return this.$t(key, values)
        }
    },
}
</script>
<style>
.blinking-cursor {
    animation: blink 1s infinite;
    border-left: 2px solid var(--notice);
    border-right: 2px solid var(--notice);
    border-top: 2px solid var(--notice);
    border-bottom: 2px solid var(--notice);
    height: 1.5em;
    margin-left: 2px;
    margin-top: -2px;
    position: absolute;
    width: 0;
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
</style>