<template>
    <OModal
        :title="$t('accounting.entry') + ' ' +transaction.number"
        :subtitle="transaction.description"
        size="xl"
        >
        <div class="d-flex gap-5 mb-4">
            <dl>
                <dt>{{ $t('crud.date') }}</dt>
                <dd>{{ $formatDate(transaction.posted_at) }}</dd>
            </dl>
            <dl v-if="transaction.document?.number">
                <dt>{{ $t('accounting.document') }}</dt>
                <dd>{{ transaction.document.number }}</dd>
            </dl>
            <dl v-else-if="transaction.files && transaction.files.length > 0">
                <dt>{{ $t('accounting.document') }}</dt>
                <dd>
                    <a v-for="file in transaction.files" :key="file.id" :href="file.url" target="_blank">
                        <OIcon :icon="['far', 'paperclip']" fixed-width />
                        {{ file.name }}
                    </a>
                </dd>
            </dl>
        </div>

        <table class="table">
            <thead>
                <th class="border-right">{{ $t('account.account') }}</th>
                <th class="border-right">{{ $t('vat.vat') }}</th>
                <th class="text-right border-right">{{ $t('accounting.entry-record-debit') }}</th>
                <th class="pr-3 text-right">{{ $t('accounting.entry-record-credit') }}</th>
            </thead>
            <tbody>
                <tr v-for="entry in transaction.entries || []">
                    <td class="border-right">
                        <div class="d-flex align-items center gap-2">
                            <span class="badge badge-muted tabular-nums">{{ entry.account?.number }}</span>
                            <span class="d-none d-lg-inline">{{ entry.account?.name }}</span>
                        </div>
                    </td>
                    <td class="border-right">{{ entry.vat_rate?.label || '-' }}</td>
                    <td class="text-right tabular-nums border-right">{{ entry.debit_amount && $formatCurrency(entry.debit_amount) }}</td>
                    <td class="text-right tabular-nums">{{ entry.credit_amount && $formatCurrency(entry.credit_amount) }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="3" class="">{{ $t('accounting.entry-amount') }}</th>
                    <th class="text-right tabular-nums">
                        <div>{{ $org.country.currency_code }} {{ $formatCurrency(transaction.amount) }}</div>
                        <div v-if="transaction.currency != $org.country.currency_code" class="text-muted">{{ transaction.currency }} {{ $formatCurrency(transaction.original_amount) }}</div>
                    </th>
                </tr>
            </tfoot>
        </table>

        <div v-if="explanation" class="mt-3">
            <OLoading :loading="loadingState.assistant" type="notice" />
            <OAlert :title="$t('accounting.ai-assistant')" type="notice" class="mb-n3">
                <div v-html="explanation" class="my-3"></div>
            </OAlert>
        </div>
    </OModal>
</template>
<script>
import HasLoadingState from '~/mixins/hasLoadingState'
export default {
    mixins: [HasLoadingState],
            
    props: ['transaction', '$t', 'app'],

    data() {
        return {
            explanation: null,
            explanationDone: false,
        }
    },
    
    created() {
        if (this.$org.addons.includes('experimental_features')) {
            this.explain()
        }
    },

    methods: {
        async explain() {
            this.$startLoading('assistant')

            const streamedResponse = await fetch(this.app.$axios.defaults.baseURL + `journal/${this.transaction.id}/ai-explain`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accpet': 'application/json',
                },
            })

            const reader = streamedResponse.body.getReader()
            this.explanation = ''
            this.explanationDone = false

            while (!this.explanationDone) {
                const { done, value } = await reader.read()

                if (done) {
                    this.explanationDone = true
                } else if (value) {
                    this.explanation += new TextDecoder('utf-8').decode(value)
                }
            }

            this.$stopLoading('assistant')
        }
    }
}
</script>