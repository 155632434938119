var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OLoading',{attrs:{"loading":_vm.loading}},[_c('OSearch',_vm._b({ref:"searchInput",style:({
            'max-width': _vm.compact ? '85px' : 'none',
        }),attrs:{"value":_vm.value,"options":_vm.filteredAccounts,"search-by":['number'],"null-option":_vm.allowNull ? _vm.$t('crud.none') : null},on:{"input":$event => {
            _vm.$emit('input', $event)
            _vm.isSearching = false
        },"search":function($event){_vm.isSearching = $event.length > 0}},scopedSlots:_vm._u([{key:"selected",fn:function({ option }){return [_c('div',{staticClass:"badge font-weight-semibold tabular-nums text-right",class:{
                'badge-info': option.type === 'active',
                'badge-warning': option.type === 'passive',
                'badge-success': option.type === 'revenue',
                'badge-danger': option.type === 'expense',
                'badge-notice': option.type === 'income_statement',
                'badge-secondary': option.type === 'balance_sheet',
                'badge-dot': _vm.simple,
                'ml-n2': !_vm.simple,
            }},[_vm._v(_vm._s(_vm.simple ? '' : option.number))]),_vm._v(" "),(!_vm.compact)?_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(option.label))]):_vm._e()]}},{key:"default",fn:function({ option }){return [_c('div',{staticClass:"d-flex align-items-start gap-2",class:{
                    'bg-light py-2 mx-n3 mb-n2 px-3': option.type === 'class',
                }},[(!option.disabled)?_c('div',{staticClass:"badge font-weight-semibold tabular-nums text-right",class:{
                    'badge-info': option.type === 'active',
                    'badge-warning': option.type === 'passive',
                    'badge-success': option.type === 'revenue',
                    'badge-danger': option.type === 'expense',
                    'badge-notice': option.type === 'income_statement',
                    'badge-secondary': option.type === 'balance_sheet',
                    'badge-dot mt-15': _vm.simple,
                }},[_vm._v(_vm._s(_vm.simple ? '' : option.number))]):_vm._e(),_vm._v(" "),_c('div',[(option.type === 'class')?_c('h6',{staticClass:"my-0"},[_vm._v(_vm._s(option.label))]):(option.type === 'section')?_c('h6',{staticClass:"my-0"},[_vm._v(_vm._s(option.label))]):(option.type === 'group')?_c('span',[_vm._v(_vm._s(option.label))]):_c('span',[_vm._v(_vm._s(option.label))]),_vm._v(" "),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(option.description))])])])]}}])},'OSearch',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }