var render = function render(_c,_vm){return _c('div',_vm._g(_vm._b({staticClass:"card",class:[{ 
        'theme-dark': _vm.props.dark, 
        'card-invisible': _vm.props.invisible, 
        'card-action': _vm.props.clickable, 
        'card-stretch': _vm.props.fullHeight,
        'card-disabled': _vm.props.disabled,
    }, _vm.data.class, _vm.data.staticClass],style:([_vm.data.style, _vm.data.staticStyle]),attrs:{"tabindex":_vm.props.clickable ? '0' : null,"role":_vm.props.clickable && 'button'}},'div',_vm.data.attrs,false),_vm.listeners),[(_vm.props.image)?_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.props.image}}):_vm._e(),_vm._v(" "),(_vm.slots().header)?_c('div',{staticClass:"card card-body px-3 mx-n1 mx-md-0",class:[{ 'card-sticky': _vm.props.stickyHeader }, _vm.props.headerClass]},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_vm._t("header")],2)]):_vm._e(),_vm._v(" "),(_vm.props.title)?_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"h5 m-0"},[_vm._v(_vm._s(_vm.props.title))]),_vm._v(" "),_c('div',{staticClass:"text-muted mw-prose"},[_vm._v(_vm._s(_vm.props.subtitle))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body",class:[_vm.props.bodyClass, { 'pb-0': _vm.props.noPaddingBottom }]},[_vm._t("default")],2),_vm._v(" "),(_vm.slots().footer)?_c('div',{staticClass:"card-footer"},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }