<template functional>
    <RouterLink :to="props.link" :exact="props.link === '/'" class="sidebar-row sidebar-item">
        <div class="sidebar-icon" title="label" v-tooltip:right="props.tooltip ? props.label : null">
            <OIcon v-if="props.icon" :icon="['fal', props.icon]" :size="props.iconSize"></OIcon>
        </div>
        <div class="sidebar-label d-flex align-items-center">
            <slot>
                {{ props.label }}
            </slot>
            <div v-if="props.badge" class="badge badge-pill ml-auto" :class="'badge-' + props.badgeType" style="min-width: 22px;">{{ props.badge }}</div>
        </div>
    </RouterLink>
</template>
<script>
export default {
    props: {
        link: {
            type: String,
            default: '/',
        },
        label: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        iconSize: {
            type: String,
            default: 'lg',
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        badge: {
            type: [String, Number],
            default: null
        },
        badgeType: {
            type: String,
            default: 'primary'
        },
    },
}
</script>
