<template>
    <OModal 
        ref="modal" 
        :title="$t('2fa.setup')"
        @close="$emit('close')"
        >

        <p>{{ $t('2fa.setup-text') }}</p>
        <div v-html="qrcode" class="two-factor-qrcode d-flex justify-content-center mt-5"></div>

        <div class="text-center mt-3 mb-5">
            <a :href="qrcode_url" target="_blank" class="btn btn-sm btn-secondary">{{ $t('2fa.open-qrcode-url') }}</a>
        </div>

        <p>{{ $t('2fa.setup-confirm') }}</p>

        <OAlert v-if="error" type="danger" icon="exclamation-triangle">
            <strong>{{ $t('2fa.2fa') }}</strong><br>
            {{ $t('2fa.verification-code-invalid') }}
        </OAlert>
        <OInput
            ref="codeInput"
            :label="$t('2fa.verification-code')"
            placeholder="123 456"
            v-model="code"
            :format="{
                swapHiddenInput: true,
                blocks: [3, 3],
            }"
            />

        <template #footer>
            <OButton @click="$refs.modal.close()" type="light">{{ $t('crud.cancel') }}</OButton>
            <OButton
                @click="challenge()"
                type="primary"
                :disabled="!(code.length >= 6)">
                {{ $t('crud.ok') }}
            </OButton>
        </template>
    </OModal>
</template>
<script>
export default {
    props: ['$t', 'app', 'qrcode', 'qrtoken', 'qrcode_url'],

    data() {
        return {
            error: false,
            code: '',
        }
    },

    methods: {
        challenge() {
            this.error = false
            this.app.$axios.$post('../two-factor-confirm', { code: this.code })
                .then(() => {
                    this.$emit('confirm')
                })
                .catch(error => {
                    this.error = true
                })
        }
    }
}
</script>