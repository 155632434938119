<template>
    <div>
        <div class="modal d-block">
            <div class="modal-dialog modal-dialog-centered modal-lg overflow-auto d-block" style="pointer-events: all;">
                <div v-if="pdf" key="pdf" class="py-3 px-3 position-relative" v-click-outside="close">
                    <div ref="renderContainer" ></div>
                </div>
                <div v-else-if="loadingState.default" key="loader" class="text-center py-5">
                    <OSpinner>{{ $t('document.pdf-loading') }}</OSpinner>
                </div>
                <div v-else-if="error" class="text-center text-danger py-5">
                    <img src="/illu_error.svg" class="d-block mx-auto mb-5" style="width: 10rem;">
                    {{ $t('document.pdf-error') }}
                </div>
            </div>
            <div class="position-absolute p-4 d-flex flex-column" style="top: 0; right: 0;">
                <OButton icon="times" type="light" v-tooltip:left="$t('crud.close')"></OButton>
                <a :href="url" download target="_blank">
                    <OButton icon="download" type="light" class="mt-2" v-tooltip:left="$t('document.download-pdf')"></OButton>
                </a>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>
<script>
import HasLoadingState from '~/mixins/hasLoadingState'
import pdfjsLib from 'pdfjs-dist/webpack'

export default {
    mixins: [HasLoadingState],

    props: {
        url: {
            type: String,
            required: true
        },
        scale: {
            type: Number,
            default: 2
        },
        '$t': {
            type: Function
        }
    },

    data() {
        return {
            pdf: null,
            error: false,
        }
    },

    mounted() {
        document.addEventListener('keyup', this.globalKeyDownHandler)
    },

    methods: {
        async load() {
            this.$startLoading()
            try {
                const loadPdfTask = pdfjsLib.getDocument(this.url)
                this.pdf = await loadPdfTask.promise
            } catch (err) {
                this.error = true
                this.$stopLoading()
                return
            }

            for (let pageNumber = 1; pageNumber <= this.pdf.numPages; pageNumber++) {
                let page = await this.getPage(pageNumber)
                await this.$nextTick()
                await this.renderPage(page)
            }

            this.$emit('loaded', this.pdf)
            this.$stopLoading()
        },

        async getPage(pageNumber = 1) {
            const page = await this.pdf.getPage(pageNumber)
            this.$emit('pageloaded', page)

            return page
        },

        async renderPage(page) {
            const viewport = page.getViewport({ scale: this.scale, })
            const container = this.$refs.renderContainer
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')

            canvas.height = viewport.height
            canvas.width = viewport.width
            canvas.classList.add('d-block', 'w-100', 'mb-2', 'shadow', 'rounded-lg')
            container.appendChild(canvas)

            const renderContext = {
                canvasContext: context,
                viewport: viewport
            }

            const renderTask = page.render(renderContext);
            await renderTask.promise

            this.$emit('pagerendered', page)
        },

        close() {
            this.$emit('close')
        },

        globalKeyDownHandler(ev) {
            if (ev.keyCode === 27) {
                ev.preventDefault()
                this.close()
            }
        },
    },

    watch: {
        url: {
            handler(newUrl) {
                this.load()
            },
            immediate: true,
        }
    }
}
</script>