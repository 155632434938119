<template>
    <OModal
        :title="$t('document.mark-as-paid')"
        @close="$emit('close')"
        @confirm="$emit('confirm', paidAt)"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('document.mark-as-paid')"
        type="primary"
        allow-overflow>

        <ODatePicker
            :label="$t('document.paid-at')"
            v-model="paidAt"
            required
            />

    </OModal>
</template>
<script>
export default {
    props: ['$t'],

    data() {
        return {
            paidAt: this.$dateForDB('now'),
        }
    }
}
</script>