<template>
    <OModal
        :title="title"
        :subtitle="account.name"
        allow-overflow
        @confirm="$emit('confirm', { posted_at, description, amount, accountId })"
        >
        <div class="row">
            <div class="col-8">
                <OInput 
                    :label="$t('crud.description')"
                    v-model="description"
                    autofocus
                    />
            </div>
            <div class="col-4">
                <ODatePicker 
                    :label="$t('crud.date')"
                    v-model="posted_at"
                    />
            </div>
            <div class="col-12">
                <AccountSelect 
                    :label="$t('account.account')"
                    v-model="accountId"
                    :type="allowedType || null"
                    use-id
                    select-first
                    />
            </div>
            <div class="col-12">
                <OCurrencyInput 
                    :label="$t('accounting.entry-amount')"
                    v-model="amount"
                    />
            </div>
        </div>
    </OModal>
</template>
<script>
import AccountSelect from '@/components/account/select.vue'
export default {
    components: {
        AccountSelect,
    },

    props: ['account', 'title', 'allowedType', '$t'],

    data() {
        return {
            posted_at: new Date().toISOString().substring(0, 10),
            description: '',
            amount: 0,
            accountId: null,
        }
    }
}
</script>