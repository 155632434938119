<template>
    <OModal
        type="dark"
        confirmIcon="arrow-right"
        :confirmText="$t('app.changelog')"
        :cancelText="$t('crud.close')"
        @close="$emit('close')"
        @confirm="$emit('confirm')"
        size="lg"
        >
        <div class="p-lg-3">
            <div class="prehead text-primary">{{ $t('app.changelog') }}</div>
            <h1 class="mt-0 mb-4">Update {{ update.date.long }}</h1>

            <div v-html="update.content['de'].html" class="prose"></div>
        </div>
    </OModal>
</template>
<script>
export default {
    props: ['update', '$t']
}
</script>