export default async function ({ store, redirect, route, from }) {
    /**
     * User does not have a auth token
     * -> Redirect to login page
     */
    if (!store.getters['user/loggedIn']) {
        return redirect(200, '/auth/login', {
            redirect: route.fullPath != '/' ? route.fullPath : null
        })
    }

    /**
     * User has a auth token but no user data
     * -> Fetch user data
     */
    if (!store.getters['user/user']) {
        return await store.dispatch('user/getUser')
    }

    /**
     * Close sidebar if user does not have organization active
     */
    if (!store.getters['user/user'].current_organization) {
        store.commit('setSidebarOpen', false)
    }

    /**
     * Get fresh user data on first page load
     */
    const organization = store.getters['user/user'].current_organization
    if (route.fullPath === from.fullPath || organization && (organization.rates_count + organization.companies_count) < 2) {
        store.dispatch('user/getUser')
    }
}