<template>
    <OModal
        :title="$t('organization.activate-plan')"
        :subtitle="$t('organization.activate-plan-confirm')"
        type="primary"
        :confirmText="$t('organization.activate-plan')"
        :allow-confirm="!loading"
        :icon="['fad', 'shopping-cart']"
        @close="$emit('close')"
        @confirm="$emit('confirm', {
            promotionCodeId: promotion ? promotion.id : null,
        })"
        >
        <table class="table mt-n3">
            <tbody>
                <tr>
                    <td class="text-muted">{{ $t('organization.plan') }}</td>
                    <td class="font-weight-semibold text-nowrap">{{ $t('plans.' + plan + '.label') }}</td>
                </tr>
                <tr>
                    <td class="text-muted">{{ $t('payment.billing-period') }}</td>
                    <td class="font-weight-semibold text-nowrap">{{ $t('payment.' + billingPeriod) }}</td>
                </tr>
                <tr>
                    <td class="text-muted">{{ $t('payment.card') }}</td>
                    <td class="font-weight-semibold text-nowrap">
                        <span class="badge badge-light mr-2">{{ $org.pm_type.toUpperCase() }}</span>
                        <span>**** {{ $org.pm_last_four }}</span>
                    </td>
                </tr>
                <tr v-if="promotion">
                    <td class="text-muted">{{ $t('payment.coupon') }}</td>
                    <td class="font-weight-semibold text-nowrap text-primary">
                        <div class="d-flex align-items-center gap-2">
                            <span>{{ promotion.code }}</span>
                            <span class="badge badge-primary">-{{ promotion.discount }}</span>
                            <button type="button" @click="promotion = null" class="btn-reset ml-auto" v-tooltip="$t('crud.delete')">&times</button>
                        </div>
                    </td>
                </tr>
                <tr v-else-if="couponCode === null">
                    <td colspan="2">
                        <OButton 
                            type="btn-reset p-0" 
                            size="sm" 
                            icon="plus"
                            @click="() => {
                                couponCode = '';
                                $nextTick(() => $refs.couponCodeInput.focus())
                            }"
                            >{{ $t('payment.add-coupon-code') }}
                        </OButton>
                    </td>
                </tr>
                <tr v-else>
                    <td class="text-muted">{{ $t('payment.coupon-code') }}</td>
                    <td class="font-weight-semibold text-nowrap">
                        <form class="d-flex align-items-center" @submit.prevent="verifyCouponCode(couponCode)">
                            <input
                                ref="couponCodeInput"
                                type="text"
                                class="form-control flex-grow-1 mr-2"
                                v-model="couponCode"
                                :disabled="loading"
                                />
                            <OButton icon="check" type="secondary" :disabled="!couponCode" submit />
                        </form>
                    </td>
                </tr>
            </tbody>
        </table>
    </OModal>
</template>
<script>
export default {
    props: ['plan', 'billingPeriod', '$t', 'app'],

    data() {
        return {
            couponCode: null,
            promotion: null,
            loading: false,
        }
    },

    methods: {
        verifyCouponCode(code) {
            if (code === '' || code === null) {
                this.couponCode = null;
                return;
            }

            code = code.toUpperCase();
            this.loading = true;

            this.app.$axios.$post('/payment/promotion', { coupon_code: code }).then((data) => {
                this.promotion = data;
                this.couponCode = null;
                this.$toast(this.promotion.code, 'success', {
                    message: this.$t('payment.coupon-code-applied'),
                })
            })
            .catch(error => {
                this.couponCode = '';
                this.$toast(this.$t('payment.coupon-code-invalid', { code }), 'danger')
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>