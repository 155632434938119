<template functional>
    <div :class="[{
        'container': !(props.fluid || props.wide),
        'container-fluid': (props.fluid || props.wide)
    }, data.class, data.staticClass]">
        <div v-if="props.narrow" class="row justify-content-center">
            <div class="col-lg-10 col-xl-8">
                <slot />
            </div>
        </div>
        <div v-else-if="props.wide" class="row justify-content-center">
            <div class="col-12 col-qhd-10">
                <slot />
            </div>
        </div>
        <slot v-else />
    </div>
</template>
<script>
export default {
    props: {
        fluid: {
            type: Boolean,
            default: false
        },
        narrow: {
            type: Boolean,
            default: false
        },
        wide: {
            type: Boolean,
            default: false
        }
    }
}
</script>