const setupJune = (writeKey) => {
    window.analytics = {};
    window.analytics._writeKey = writeKey;
    
    var script = document.createElement("script");
    script.type = "application/javascript";
    script.src = "https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js";
    script.onload = function () {
        window.analytics.page();
    }
    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
}

export const track = (event, properties = {}, user = null) => {
    if (window.analytics && window.analytics.track) {
        window.analytics.track(event, properties, {
            context: {
                groupId: user?.current_organization?.id,
            }
        });
    } else {
        console.log('June Track', {
            event,
            properties,
        })
    }
}

export const identify = (user, traits = {}) => {
    if (window.analytics && window.analytics.identify) {
        window.analytics.identify(user.id, {
            email: user.email,
            first_name: user.firstname,
            last_name: user.lastname,
            ...traits,
        });

        const organization = user.current_organization
        if (organization) {
            window.analytics.group(organization.id, user.id, {
                name: organization.name,
                trial: organization.is_on_trial,
                plan: organization.subscription 
                    ? organization.subscription.stripe_plan 
                    : '',
                viable_for_vat: organization.settings.liableForVat || false
            });
        }
    } else {
        console.log('June Identify', {
            user,
            traits,
        })
    }
}

export default ({ app, $config }, inject) => {
    setupJune($config.juneWriteKey)
    inject('analyticsIdentify', (user, traits = {}) => setTimeout(identify(user, traits), 3000))
    inject('analyticsTrack', (event, properties = {}, user = null) => track(event, properties, user || app.$auth.user))
}