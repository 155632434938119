import { downloadResponse } from "../util/helpers"

export default function ({ app, $axios, store, redirect, error: appError, $auth }) {
    /**
     * Intercept file blob responses and automatically download them
     */
    $axios.onResponse(response => {
        if (
            response.request.responseType === 'blob' 
            && response.headers['content-disposition'] 
            && response.headers['content-disposition'].includes('attachment')) {
                return downloadResponse(response)
        }
    })

    /**
     * Display various error messages depending
     * on the error's HTTP response code
     */
    $axios.onError(error => {
        const code = parseInt(error.response && error.response.status)
        const data = error.response ? error.response.data : null
        const errors = data ? data.errors : []

        if (!store.state.auth.loggedIn || error.config.skipErrorHandler) {
            return
        }

        // Validation error
        if (code === 422 && store.state.auth.loggedIn && errors) {
            const validationMessages = Object.keys(errors).map(key => errors[key].join('<br>'))

            store.dispatch('toast/push', {
                title: app.i18n.t('error.422.title'),
                type: 'danger',
                message: validationMessages,
                lifetime: 10000,
            })

            return
        }

        // Authorization error: Forbidden
        if (code === 403) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.403.title'),
                message: app.i18n.t('error.403.message'),
                type: 'danger'
            })

            return
        }

        // Not Found Error
        if (code === 404) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.404.title'),
                message: app.i18n.t('error.404.message'),
                type: 'danger'
            })

            return
        }

        // Payment required (No Active Subscription)
        if (code === 402) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.402.title'),
                message: app.i18n.t('error.402.message'),
                type: 'warning',
                action: {
                    label: app.i18n.t('payment.manage-subscription'),
                    icon: 'arrow-right',
                    handler: () => {
                        redirect('/organization/billing')
                    }
                }
            })

            return
        }

        // Throttling error: Too many requests
        if (code === 429) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.429.title'),
                message: app.i18n.t('error.429.message'),
                type: 'danger'
            })

            return
        }

        // Maintanance mode
        if (code === 503) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.503.title'),
                message: app.i18n.t('error.503.message'),
                type: 'info',
                persist: true,
            })
            return
        }

        // Server error
        if (code >= 500) {
            if (process.env.NODE_ENV === 'development') {
                app.$createModal({ error: error.response }, 'error/500')
            } else {
                store.dispatch('toast/push', {
                    title: app.i18n.t('error.500.title'),
                    message: data.message || app.i18n.t('error.500.message'),
                    type: 'danger'
                })
            }

            return
        }

        // Offline
        if (!window.navigator.onLine) {
            console.error('Network offline, could not make API call')

            return
        }
    })
}