<template>
    <OModal
        :title="$t('feedback.modal-title')"
        :confirmText="$t('crud.send')"
        :cancelText="$t('crud.cancel')"
        @close="$emit('close')"
        @confirm="sendFeedback()"
        :allow-confirm="feedback.type && feedback.message.length > 0"
        size="lg"
        >

        <OSelect :label="$t('feedback.type')"
            v-model="feedback.type"
            :options="[
                { label: 'Verbesserungsvorschlag (UI/UX)', value: 'ux' },
                { label: 'Bug / Problembericht', value: 'bug' },
                { label: 'Feature Request', value: 'feature' },
            ]" required />
        
        <o-textarea 
            :label="$t('feedback.message')" 
            :help-text="$t('feedback.message-text')" 
            :rows="10"
            v-model="feedback.message" 
            required />

        <label for="userFeedbackFile">Screenshots</label>
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="userFeedbackFile" @change="feedback.files = Array.from($event.target.files)">
            <label class="custom-file-label" for="userFeedbackFile">{{ feedback.files.map(f => f.name).join(', ') }}</label>
        </div>
    </OModal>
</template>
<script>
import { track as analyticsTrack } from '~/plugins/analytics'

export default {
    props: ['$t', 'app'],

    data() {
        return {
            feedback: {
                type: 'ux',
                message: '',
                files: []
            }
        }
    },

    methods: {
        async sendFeedback() {
            const feedback = await this.app.$axios.$post('/user/feedback', {
                type: this.feedback.type,
                message: this.feedback.message,
                files: this.feedback.files.length > 0 ? true : false,
            })

            this.$toast(this.$t('feedback.sent-title'), 'info', {
                message: this.$t('feedback.sent-text')
            })

            if (this.feedback.files.length > 0) {
                await this.uploadFile(feedback, this.feedback.files[0])
            }

            this.$emit('confirm')

            analyticsTrack('User feedback submitted')
        },

        async uploadFile(item, file) {
            return await this.$store.dispatch('media/upload', {
                file,
                endpoint: `/user/feedback/${item.id}/upload`,
            })
        },
    }
}
</script>