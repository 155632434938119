<template>
    <OSearch 
        :label="label || $t('address.country')"
        :value="value"
        @input="$emit('input', $event)"
        :options="countries"
        v-slot="{ option: country }"
        v-bind="$attrs"
        >
        <div class="d-flex align-items-center">
            <img :src="`https://flagcdn.com/${country.value.toLowerCase()}.svg`" loading="lazy" style="width: 1.5rem;" class="rounded overflow-hidden mr-2">
            {{ country.label }}
        </div>
    </OSearch>
</template>
<script>
import CountriesList from '~/util/countries.json'
export default {
    props: {
        value: {
            type: String,
            default: null
        },
        
        label: {
            type: String,
            default: null
        }
    },

    computed: {
        countries() {
            return Object.keys(CountriesList).map(countryCode => {
                return {
                    label: CountriesList[countryCode],
                    value: countryCode.toUpperCase(),
                }
            })
        }
    }
}
</script>