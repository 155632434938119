import { VueRenderer } from '@tiptap/vue-2'
import { createPopper } from '@popperjs/core'

import MentionList from '@/components/member/mention-list.vue'

export default {
    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                const currentThemeOnRoot = document.documentElement.dataset.theme

                component = new VueRenderer(MentionList, {
                    parent: this,
                    propsData: props,
                })

                if (currentThemeOnRoot) {
                    document.documentElement.dataset.theme = currentThemeOnRoot
                }

                if (!props.clientRect) {
                    return
                }

                document.body.appendChild(component.element)

                popup = createPopper(
                    { getBoundingClientRect: props.clientRect }, 
                    component.element, 
                    { placement: 'bottom-start' }
                )
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup.setOptions({
                    getReferenceClientRect: props.clientRect
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup.hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup.destroy()
                component.destroy()
                component.element.remove()
            }
        }
    }
}
