var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OModal',_vm._g(_vm._b({attrs:{"hide-footer":true},scopedSlots:_vm._u([{key:"default",fn:function({
        type,
        close,
        confirm,
        confirmText,
        confirmIcon,
        cancelText,
    }){return [_c('OLoading',{attrs:{"loading":_vm.loadingState.submitting}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return (event => _vm.onSubmit(event, confirm)).apply(null, arguments)}}},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-end gap-2"},[(cancelText)?_c('OButton',{attrs:{"type":"link"},on:{"click":function($event){return close()}}},[_vm._v("\n                    "+_vm._s(cancelText)+"\n                ")]):_vm._e(),_vm._v(" "),_c('OButton',{attrs:{"type":type || 'primary',"submit":"","icon":confirmIcon}},[_vm._v("\n                    "+_vm._s(confirmText)+"\n                ")])],1)],2)])]}}],null,true)},'OModal',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }