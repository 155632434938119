var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.image && !_vm.imageFailed)?_c('img',{staticClass:"avatar flex-shrink-0 rounded-circle",class:{ 'bg-darkish': _vm.dark },staticStyle:{"object-fit":"contain"},style:({ width: _vm.getSize(_vm.size), height: _vm.getSize(_vm.size) }),attrs:{"src":_vm.image.url,"loading":"lazy"},on:{"error":function($event){_vm.imageFailed = true}}}):_c('div',{staticClass:"avatar flex-shrink-0 text-white rounded-circle d-inline-flex align-items-center justify-content-center text-uppercase",class:{ 
            'small': _vm.size === 'sm', 
            'bg-light text-muted': _vm.fallback === '?',
        },style:({
			backgroundColor: _vm.getColor(_vm.fallback),
			width: _vm.getSize(_vm.size), 
			height: _vm.getSize(_vm.size),
            lineHeight: '110%',
		})},[_vm._v(_vm._s(_vm.initials))])
}
var staticRenderFns = []

export { render, staticRenderFns }