import Vue from 'vue'
import VueI18n from 'vue-i18n'

import German from '~/locales/de.json'
import English from '~/locales/en.json'

Vue.use(VueI18n)

export default ({ app, store }) => {
    const i18n = new VueI18n({
        locale: store.state.locale,
        fallbackLocale: 'de',
        messages: {
            de: German,
            en: English
        },
    })

    window.document.documentElement.lang = i18n.locale

    app.i18n = i18n
}
