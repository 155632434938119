<template>
    <OModal
        :title="$t('invoice.send-to')"
        :subtitle="invoice.number + (invoice.name ? '· ' + invoice.name : '')"
        :icon="['fad', 'envelope-open-dollar']"
        :confirmText="$t('crud.send')"
        confirmIcon="paper-plane"
        :cancelText="$t('crud.cancel')"
        :allowConfirm="recipients.length > 0"
        @close="$emit('close')"
        @confirm="$emit('confirm', { 
            recipients: recipients.join(';'), 
            increaseDunningLevel,
            message,
            ccToOwner,
            adjustDates,
        })"
        size="lg"
        >

        <form @submit.prevent="add()" class="d-flex flex-column mb-2 gap-y-2">
            <div class="form-control form-control-within d-flex gap-2 align-items-center py-0 mb-3 mb-lg-0">
                <label for="emailToAdd" class="flex-shrink-0 text-muted my-0">{{ $t('document.email-to') }}</label>
                <div
                    v-for="email in recipients"
                    :key="email"
                    class="text-black">
                    {{ email }}<span v-if="recipients.length > 1">,</span>
                </div>
                <input
                    id="emailToAdd"
                    type="email"
                    v-model="emailToAdd"
                    required
                    @blur="add()"
                    @keydown.tab.prevent="add()"
                    @keydown.backspace="emailToAdd.length === 0 && remove(recipients[recipients.length - 1])"
                    style="all: unset;"
                    class="flex-1"
                    autofocus
                    list="contacts"
                    :required="recipients.length === 0"
                    />
                <datalist id="contacts">
                    <option 
                        v-for="contact in $store.state.contact.items.filter(contact => contact.company_id === invoice.company?.id)" 
                        :key="contact.id" 
                        :value="contact.email"
                        >{{ contact.name }}
                    </option>
                </datalist>
            </div>
        </form>

        <RichtextEditor 
            :placeholder="$t('document.recipients-message-text')"
            v-model="message"
            :translate-function="$t"
            />

        <OToggle
            :label="$t('invoice.cc-to-owner')"
            :states="[$t('crud.yes'), $t('crud.no')]"
            v-model="ccToOwner"
            class="my-0"
            inline
            />

        <hr class="mx-n3">

        <div class="d-flex flex-column gap-2">
            <OToggle
                v-if="!invoice.is_locked"
                :label="$t('invoice.adjust-dates')"
                :help-text="$t('invoice.adjust-dates-text')"
                :states="[$t('crud.yes'), $t('crud.no')]"
                v-model="adjustDates"
                inline-block
                />
            <OToggle
                v-if="invoice.is_overdue && invoice.reminded === 0"
                :label="$t('invoice.send-dunning')"
                :help-text="$t('invoice.send-dunning-text')"
                :states="[$t('crud.yes'), $t('crud.no')]"
                v-model="increaseDunningLevel"
                inline-block
                />
            <OToggle
                v-else-if="invoice.is_overdue && invoice.reminded > 0 && invoice.reminded < $org.max_dunning_level"
                :label="$t('invoice.auto-increase-dunning-level')"
                :help-text="$t('invoice.auto-increase-dunning-level-text')"
                :states="[$t('crud.yes'), $t('crud.no')]"
                v-model="increaseDunningLevel"
                inline-block
                />
        </div>

        <!-- <OAlert v-if="!invoice.is_sent" type="info" icon="info-circle" class="mb-0">
            {{ $t('invoice.lock-info-text') }}
        </OAlert> -->
    </OModal>
</template>
<script>
import { isPast, isToday } from 'date-fns'
import AvatarComponent from '~/components/avatar.vue'
import RichtextEditor from '~/components/editor.vue'

export default {
    components: {
        AvatarComponent,
        RichtextEditor,
    },

    props: ['invoice', '$t', 'app'],

    data() {
        return {
            recipients: this.invoice.contact.email ? [this.invoice.contact.email] : [],
            emailToAdd: '',
            increaseDunningLevel: true,
            message: '',
            ccToOwner: false,
            adjustDates: true,
        }
    },

    created() {
        this.message = this.$org.settings.invoiceMailMessage || ''
        this.$store.dispatch('contact/fetchAll')
    },

    methods: {
        add() {
            const email = this.emailToAdd

            if (email.length === 0 || !(email.includes('@') && email.includes('.'))) {
                return
            }

            if (!this.recipients.includes(email)) {
                this.recipients.push(email)
            }

            this.emailToAdd = ''

            if (this.$refs.messageTextarea) {
                this.$refs.messageTextarea.focus()
            }
        },

        remove(email) {
            if (this.recipients.length === 0) {
                return
            }

            this.recipients.splice(this.recipients.indexOf(email), 1)
        },

        isInPast(date) {
            if (typeof date === 'string') {
                date = date
                    .replace(/-/g, '/')
                    .replace('T', ' ')
                    .slice(0, 19)
                date = new Date(date)

                return isPast(date) && !isToday(date)
            }
        }
    }
}
</script>