<template>
    <OModal
        :title="$t('invoice.mark-as-paid')"
        :subtitle="`${invoice.number} ${invoice.name ? '· ' + invoice.name : ''}`"
        @close="$emit('close')"
        @confirm="$emit('confirm', paidAt)"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('invoice.mark-as-paid')"
        type="success"
        allow-overflow>

        <div class="mb-3">{{ $t('invoice.mark-as-paid-confirm', {
            currency: $org.country.currency_code,
            total: $formatCurrency(invoice.total),
            total_paid: $formatCurrency(invoice.total_paid),
            total_open: $formatCurrency(invoice.total_with_vat - invoice.total_paid),
        }) }}</div>

        <ODatePicker
            :label="$t('invoice.paid_at')"
            v-model="paidAt"
            :options="{ minDate: invoice.issued_at }"
            required
            />

    </OModal>
</template>
<script>
export default {
    props: ['invoice', '$t'],

    data() {
        return {
            paidAt: this.$dateForDB('now'),
        }
    }
}
</script>