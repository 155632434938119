<template>
    <div class="tag" :class="`tag-${tag.color || 'muted'}`">
        <span v-if="tag.group">
            <span class="opacity-50">{{ tag.group.name }}</span> <span>{{ tag.group.value }}</span>
        </span>
        <span v-else>{{ tag.name }}</span>
        <button v-if="removeable" type="button" @click.stop="$emit('delete')" class="tag__button ml-1">
            <OIcon :icon="['far', 'times']" />
        </button>
    </div>
</template>
<script>
export default {
    props: {
        tag: {
            type: Object,
            required: true
        },

        removeable: {
            type: Boolean,
            default: false
        }
    }
}
</script>