<template>
    <OFormGroup 
        :id="$attrs.id" 
        :label="label" 
        :required="required" 
        :help-text="helpText" 
        v-slot="{ id }"
        >
        <div class="input-group">
            <input
                :id="id"
                type="number"
                class="form-control text-right border-right-0 appearance-none"
                :value="value[0] || null"
                :placeholder="to ? '0' : $t('crud.range-from')"
                inputmode="decimal"
                @blur="$emit('input', [$event.target.value, to])"
                />

            <div class="input-group-append">
                <span 
                    class="input-group-text bg-transparent border-left-0 border-right-0">
                    –
                </span>
            </div>

            <input
                type="number"
                class="form-control text-left border-left-0 appearance-none"
                :value="value[1] || null"
                :placeholder="from ? '♾️' : $t('crud.range-to')"
                inputmode="decimal"
                @blur="$emit('input', [from, $event.target.value])"
                />
        </div>
    </OFormGroup>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        helpText: {
            type: String,
            default: null
        },
        value: {
            type: Array,
            default: () => [null, null]
        }
    },

    computed: {
        from() {
            return this.value[0] || null
        },

        to() {
            return this.value[1] || null
        },
    },
}
</script>