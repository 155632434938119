import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('journal_transaction_template', {
    include: [],
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = crudStore.actions()