<template>
    <OFormGroup :id="$attrs.id" :label="label" :required="required" :help-text="helpText" v-slot="{ id }">
        <div :class="{
            'input-group': $slots.prepend || $slots.append || prepend || append || type === 'password',
            'input-group-sm': size === 'sm',
            'input-group-lg': size === 'lg',
        }">
            <slot name="prepend">
                <div v-if="prepend" class="input-group-prepend">
                    <div v-if="Array.isArray(prepend)" class="input-group-text" :class="prependClass">
                        <OIcon :icon="prepend"></OIcon>
                    </div>
                    <div v-else class="input-group-text">{{ prepend }}</div>
                </div>
            </slot>
            <input 
                ref="input"
                :type="showPassword ? 'text' : type"
                :name="name"
                class="form-control" 
                :class="[{
                    'bg-darkish border-dark text-white-real': active,
                    'is-invalid': invalid || $attrs.error,
                    'form-control-sm': size === 'sm',
                    'form-control-lg': size === 'lg',
                    'form-control-frameless': frameless,
                }, inputClass]"
                :id="`form-input-${id}`" 
                :list="`form-input-${id}-list`"
                :aria-describedby="`form-input-${label}-help`" 
                :placeholder="placeholder"
                :value="value"
                @input="$emit('input', $event.target.value)"
                @blur="$emit('blur', $event.target.value)"
                @paste="$emit('paste', $event.clipboardData ? $event.clipboardData.getData('text/plain') : null)"
                :autocomplete="autocomplete ? typeof autocomplete === 'boolean' ? 'on' : autocomplete : 'off'"
                :autofocus="autofocus"
                :required="required"
                :disabled="disabled"
                :step="step"
                :min="min"
                :max="max"
                :minlength="minlength"
                :maxlength="maxlength"
                :inputmode="inputmode || (type === 'number' ? 'decimal' : null)">
            <div v-if="append" class="input-group-append">
                <div v-if="Array.isArray(append)" class="input-group-text" :class="appendClass">
                    <OIcon :icon="append"></OIcon>
                </div>
                <div v-else class="input-group-text">{{ append }}</div>
            </div>
            <div v-if="type === 'password'" class="input-group-append">
                <a href="javascript:;" class="input-group-text btn btn-link" 
                    :class="{ 'bg-darkish text-white': showPassword }"
                    @click="togglePasswordVisiblity()"
                    title="Show password">
                    <OIcon :icon="['far', showPassword ? 'eye-slash' : 'eye']" fixed-width />
                </a>
            </div>
        </div>
        <datalist v-if="datalist" :id="`form-input-${id}-list`">
            <option v-for="option in datalist" :value="option"></option>
        </datalist>
    </OFormGroup>
</template>
<script>
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.ch'

import OFormGroup from './Group.vue'

export default {
    components: {
        OFormGroup
    },
    
    props: {
        label: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        prepend: {
            type: [String, Array],
            default: null,
        },
        append: {
            type: [String, Array],
            default: null,
        },
        placeholder: {
            type: [String, Number],
            default: null,
        },
        value: {
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        helpText: {
            type: String,
            default: null,
        },
        autocomplete: {
            type: [Boolean, String],
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: [Boolean, Array],
            default: false,
        },
        size: {
            type: String,
            default: null,
        },
        step: {
            type: [Number, String],
            default: null,
        },
        min: {
            type: [Number, String],
            default: null,
        },
        max: {
            type: [Number, String],
            default: null,
        },
        maxlength: {
            type: [Number, String],
            default: null,
        },
        minlength: {
            type: [Number, String],
            default: null,
        },
        frameless: {
            type: Boolean,
            default: false,
        },
        inputmode: {
            type: String,
            default: null,
        },
        inputClass: {
            type: [String, Object, Array],
            default: null,
        },
        appendClass: {
            type: [String, Object, Array],
            default: null,
        },
        prependClass: {
            type: [String, Object, Array],
            default: null,
        },
        format: {
            type: Object,
            default: null,
        },
        datalist: {
            type: Array,
            default: null,
        }
    },

    data() {
        return {
            cleave: null,
            showPassword: false,
        }
    },

    mounted() {
        this.$emit('mounted', this)

        if (this.autofocus) {
            this.$nextTick(() => this.focus())
        }

        if (this.format) {
            let options = {
                ...this.format
            }

            if (options.swapHiddenInput) {
                options.onValueChanged = e => {
                    this.$emit('input', e.target.rawValue)
                    this.$emit('value-changed', e.target.rawValue)
                }
            }

            this.cleave = new Cleave(this.$refs.input, options)
        }
    },

    activated() {
        if (this.autofocus) {
            this.$nextTick(() => this.focus())
        }
    },

    methods: {
        focus: function () {
            this.$refs.input.focus()
        },

        select: function () {
            this.$refs.input.select()
        },

        togglePasswordVisiblity() {
            this.showPassword = !this.showPassword
        }
    },

    watch: {
        value(newValue) {
            if (this.cleave) {
                this.cleave.setRawValue(newValue)
            }
        }
    }
}
</script>
