<template>
    <OFormGroup :label="label" :required="required" :help-text="helpText" v-click-outside="closeDropdown">
        <div class="dropdown">
            <input 
                ref="input"
                type="text"
                :name="name"
                class="form-control custom-select" 
                :class="{ 'border-0': frameless }"
                :id="`form-input-${label}`" 
                :aria-describedby="`form-input-${label}-help`" 
                :placeholder="selectedOption ? null : (placeholderText || nullOption)"
                :value="selectedOption && !$scopedSlots.selected 
                    ? selectedLabel(selectedOption) 
                    : null"
                :required="required"
                :disabled="disabled"
                :readonly="dropdownIsOpen && hideSearch"
                autocomplete="off"
                @focus="onInputFocus()">

            <div v-if="selectedOption && $scopedSlots.selected && !dropdownIsOpen" 
                class="position-absolute d-flex gap-2 align-items-center pointer-none pl-3 bottom-0 left-0 text-nowrap text-truncate" 
                style="height: 2.3rem; right: 1.5rem;">
                <slot name="selected" :option="selectedOption" />
            </div>
                
            <transition name="dropdown">
                <div v-if="dropdownIsOpen" ref="menu" class="dropdown-menu dropdown-menu-limit mt-2" :class="{ 'show': dropdownIsOpen }">
                    <div v-if="!hideSearch" class="py-1 px-1 mb-1">
                        <input 
                            ref="searchInput"
                            type="text" 
                            class="form-control" 
                            v-model="search" 
                            autocomplete="off"
                            :placeholder="allowCreate ? $t('crud.searchOrCreate') + ' ...' : 'Suchen'"
                            @input="onInputChange($event.target.value)"
                            @keyup.esc="onInputEsc()"
                            @keydown.enter.stop.prevent="onInputEnter()"
                            @keydown.up.prevent="onInputUp()"
                            @keydown.down.prevent="onInputDown()" />
                    </div>
                    <button v-if="nullOption && value" class="dropdown-item border-bottom" @click="onOptionSelect(null)" type="button">{{ nullOption }}</button>
                    <template
                        v-for="(option, index) in filteredOptions" 
                        >
                        <div v-if="groupBy && filteredOptions.find(account => groupBy(account) === groupBy(option)) === option" class="dropdown-header">{{ groupBy(option) }}</div>
                        <button
                            ref="items"
                            class="dropdown-item"
                            :class="{ 
                                'active': option === selectedOption,
                                'bg-light':  option !== selectedOption && index === currentIndex 
                            }"
                            @click.prevent="onOptionSelect(option)"
                            style="transform: translate3d(0,0,0);"
                            :disabled="!!option.disabled"
                            >
                            <slot :option="option" :index="index">
                                <div>{{ option.label }}</div>
                                <div v-if="option.description" class="text-muted">{{ option.description }}</div>
                            </slot>
                        </button>
                    </template>
                    <div v-if="filteredOptions.length === 0" class="dropdown-item disabled">Keine Ergebnisse.</div>
                    <button v-if="allowCreate && search" class="dropdown-item border-top" @click.prevent.stop="onCreate()"><OIcon :icon="['far', 'plus-circle']" fixed-width /> <strong>{{ search }}</strong> {{ $t('crud.create').toLowerCase() }} ...</button>
                </div>
            </transition>
            <transition name="dropdown-backdrop">
                <div v-if="dropdownIsOpen" class="dropdown-backdrop" @click="onInputEsc()"></div>
            </transition>
        </div>
    </OFormGroup>
</template>
<script>
import OFormGroup from './Group.vue'
import { createPopper } from '@popperjs/core'

export default {
    components: {
        OFormGroup,
    },
    
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            default: null
        },
        name: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        nullOption: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null
        },
        placeholder: {
            type: [String, Boolean],
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        searchBy: {
            type: [Array, Function],
            default: () => []
        },
        groupBy: {
            type: Function,
            default: null,
        },
        selectFirst: {
            type: Boolean,
            default: false,
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
        frameless: {
            type: Boolean,
            default: false,
        },
        preventInitEvent: {
            type: Boolean,
            default: false,
        },
        selectedLabel: {
            type: Function,
            default: (option) => option.label,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dropdownIsOpen: false,
            currentIndex: null,
            search: null,
            popper: null
        }
    },

    activated() {
        if (this.autofocus) {
            this.$nextTick(() => this.$refs.input.focus())
        }
    },

    methods: {
        focus() {
            this.$refs.input.focus()
        },

        openDropdown() {
            this.dropdownIsOpen = true
            this.currentIndex = this.options.findIndex(option => option.value == this.value)
            this.scrollToCurrentOption()
        },

        closeDropdown() {
            this.search = null
            this.currentIndex = null
            this.dropdownIsOpen = false

            this.$nextTick(() => {
                if (this.$refs.input) {
                    this.$refs.input.blur()
                }
            })
        },

        selectOption(option) {
            this.$emit('input', option ? option.value : null)
            this.closeDropdown()
        },

        onInputChange(query) {
            this.search = query

            this.$emit('search', query)

            if (this.filteredOptions.length > 0) {
                this.currentIndex = 0
            } else {
                this.currentIndex = null
            }
        },

        onInputFocus() {
            this.openDropdown()
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.select()
                }
            })
        },

        onInputEnter() {
            if (this.filteredOptions.length === 1) {
                this.selectOption(this.filteredOptions[0])
            } else {
                const option = this.filteredOptions[this.currentIndex] || this.selectedOption
                this.selectOption(option)
            }
        },

        onInputEsc() {
            this.closeDropdown()
        },

        onOptionSelect(option) {
            this.selectOption(option)
        },

        onInputUp() {
            if (this.currentIndex === null) {
                this.goToStart()
            } else if (this.currentIndex > 0) {
                this.goToPrev()
                this.scrollToCurrentOption()
            }
        },

        onInputDown() {
            if (this.currentIndex === null) {
                this.goToStart()
            } else if (this.currentIndex < (this.filteredOptions.length - 1)) {
                this.goToNext()
                this.scrollToCurrentOption()
            }
        },

        goToStart() {
            this.currentIndex = this.filteredOptions.findIndex(option => !option.disabled)
        },

        goToNext() {
            this.currentIndex = this.filteredOptions.findIndex((option, index) => {
                return index > this.currentIndex && !option.disabled
            })
        },

        goToPrev() {
            this.currentIndex = this.filteredOptions.findLastIndex((option, index) => {
                return index < this.currentIndex && !option.disabled
            })
        },

        scrollToCurrentOption() {
            this.$nextTick(() => {
                if (this.currentIndex >= 0) {
                    const itemOffsetTop = this.$refs.items[this.currentIndex]?.offsetTop || 0
                    this.$refs.menu.scrollTop = itemOffsetTop - 15
                }
            })
        },

        onCreate() {
            this.$emit('create', this.search)
            this.closeDropdown()
        }
    },

    computed: {
        selectedOption() {
            return this.options.find(option => option.value === this.value)
        },

        currentOption() {
            return this.currentIndex !== null
                ? this.filteredOptions[this.currentIndex]
                : null
        },

        filteredOptions() {
            let options = this.options
            let searchBy = ['label', 'value'].concat(this.searchBy)

            if (this.search) {
                options = options.filter(option => {
                    return searchBy.some(key => {
                        const value = typeof key === 'function' ? key(option) : option[key]
                        return value.toString().toLowerCase().includes(this.search.toLowerCase())
                    })
                })
            }

            return options
        },

        placeholderText() {
            if (this.dropdownIsOpen) {
                if (this.currentOption) {
                    return this.currentOption.label + ' (Enter zum Auswählen)'
                }
            }

            return this.placeholder
        }
    },

    watch: {
        options: {
            handler(options, optionsBefore) {
                if (!this.value && this.selectFirst && options.length > 0) {
                    this.selectOption(options[0])
                }

                if (this.value && optionsBefore && optionsBefore.length === 0 && options.length > 0 && !this.preventInitEvent) {
                    this.$emit('input', this.value)
                }
            },
            immediate: true
        },

        dropdownIsOpen(open) {
            if (!this.$breakpoint('md')) {
                return
            }

            this.$nextTick(() => {
                if (open) {
                    this.popper = createPopper(this.$refs.input, this.$refs.menu, {
                        placement: 'bottom-start'
                    })
                } else {
                    this.popper.destroy()
                    this.popper = null
                }
            })
        }
    }
}
</script>
