import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default ({ app, $axios, $config }, inject) => {
    const echo = new Echo({
        broadcaster: 'pusher',
        key: $config.pusherAppKey,
        cluster: 'eu',
        forceTLS: true,
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    $axios.post('broadcasting/auth', {
                        socket_id: socketId,
                        channel_name: channel.name
                    })
                    .then(response => {
                        callback(null, response.data)
                    })
                    .catch(error => {
                        callback(error)
                    })
                }
            }
        },
    })

    inject('pusher', Pusher)
    inject('echo', echo)

    inject('userChannel', (auth) => echo.private(`user.${auth.user.id}`))
    inject('memberChannel', (auth) => echo.private(`member.${auth.user.current_organization.member_id}`))
    inject('leaveChannels', (auth) => {
        echo.leave(`user.${auth.user.id}`)
        echo.leave(`member.${auth.user.current_organization.member_id}`)
    })
}