import crudStoreFactory from '~/util/crud/storeFactory'
import { track as analyticsTrack } from '~/plugins/analytics'
import { celebrate } from '~/util/helpers'

const crudStore = new crudStoreFactory('offer', {
    include: ['project', 'company.media', 'contact']
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    async sendToClient({ dispatch, commit }, { offer, email, message, ccToOwner }) {
        const sendPromise = this.$axios.$post(`offer/${offer.id}/send`, { email, message, ccToOwner }).then(item => {
            commit('UPDATE_OR_ADD_ITEM', item)
        })

        dispatch('toast/push', {
            title: this.app.i18n.t('offer.sending-to-client') + '...',
            message: this.app.i18n.t('offer.sending-to-client-text'),
            type: 'primary',
            icon: 'spinner',
            until: sendPromise,
            untilSuccess: () => {
                return {
                    title: this.app.i18n.t('offer.sent-to-client'),
                    message: this.app.i18n.t('offer.sent-to-client-text'),
                    type: 'success',
                }
            }
        }, { root: true })

        sendPromise.then(() => {
            analyticsTrack('Offer sent')
            setTimeout(celebrate, 1000)
        })

        return sendPromise
    },

    async createInvoice({ dispatch }, { offer, data }) {
        return this.$axios.$post('invoice', { fromOfferId: offer.id, ...data }).then(item => {
            return item
        })
    }
}