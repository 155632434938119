import Vue from 'vue'
import Storage from '~/util/storage'

const getActualTheme = theme => {
    return ['light', 'dark'].includes(theme)
        ? theme
        : window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'dark'
            : 'light'
}

export default () => {
    Vue.mixin({
        methods: {
            $theme(theme = null) {
                if (!theme) {
                    return this.$store.state.currentTheme
                }

                this.$store.commit('setCurrentTheme', theme)
            },

            $getActualTheme() {
                return getActualTheme(this.$store.state.currentTheme)
            }
        },

        watch: {
            '$store.state.currentTheme': {
                handler(theme, oldTheme) {
                    const actualTheme = getActualTheme(theme)
                    const actualOldTheme = getActualTheme(oldTheme)

                    if (actualTheme != actualOldTheme) {
                        const event = new CustomEvent('theme-changed', {
                            detail: { theme: actualTheme },
                            bubbles: true
                        })
                        document.dispatchEvent(event)
                    }

                    if (!theme || theme === 'auto') {
                        Storage.remove('theme')
                        try {
                            delete window.document.documentElement.dataset.theme
                        } catch (error) {
                            console.log(error)
                        }
                        return
                    }

                    Storage.set('theme', theme)
                    window.document.documentElement.dataset.theme = theme
                },
                immediate: true
            }
        }
    })
}
