<template functional>
    <div 
        class="d-flex" 
        :class="[
            {
                'flex-column': props.vertical,
                'flex-wrap': props.wrap,
            },
            `justify-content-${props.justify}`,
            `align-items-${props.align}`,
            props.gap ? `gap-${props.gap}` : null,
            data.class,
            data.staticClass
        ]"
        :style="[data.style, data.staticStyle]">
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        vertical: {
            type: Boolean, 
            default: false
        },
        justify: {
            type: String,
            default: 'between'
        },
        align: {
            type: String,
            default: 'center'
        },
        wrap: {
            type: Boolean, 
            default: false
        },
        gap: {
            type: [String, Number],
            default: null,
        }
    }
}
</script>