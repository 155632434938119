<template>
    <OFormGroup :label="label === false ? null : (label || $t('tag.tags'))" :help-text="helpText">
        <OCombobox
            ref="combobox"
            :options="tags"
            label="name"
            :multiple="!single"
            :taggable="allowCreate"
            :loading="loadingState.default"
            :create-option="name => ({ 
                name,
                group: name.includes('_') ? { 
                    name: name.split('_')[0],
                    value: name.split('_')[1],
                } : null,
            })"
            :value="normalizedValue"
            :disabled="disabled"
            @input="$emit('input', $event)"
            @option:created="onOptionCreated"
            @click.native.stop
            >
            <template
                #selected-option-container="{ option, deselect, multiple, disabled }"
                >
                <OTag :tag="option" removeable @delete="deselect(option)" />
            </template>

            <template #option="option">
                <div v-if="option.id" class="d-flex align-items-center mx-n2">
                    <OTag :tag="option" />
                    <a :href="`/tag/${option.id}/edit`" @click.stop class="ml-auto mr-n2">
                        <OButton type="link" icon="cog" size="sm" v-tooltip:right="editTagText || $t('crud.edit')" />
                    </a>
                </div>
                <div v-else class="d-flex align-items-center gap-2">
                    <span>{{ createTagText || $t('crud.create') }}</span>
                    <OTag :tag="option" />
                </div>
            </template>

            <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                    <OIcon :icon="['far', 'angle-down']" />
                </span>
            </template>

            <template #no-options="{ search, searching, loading }">
                <span v-if="tags.length === 0">{{ noTagsText || $t('tag.type-to-create') }}</span>
                <span v-else class="text-muted">{{ noTagsText || $t('tag.no-tags-selectable') }}</span>
            </template>
        </OCombobox>
    </OFormGroup>
</template>
<script>
import hasLoadingState from "~/mixins/hasLoadingState"

export default {
    mixins: [hasLoadingState],

    props: {
        value: {
            type: [Array, Object],
            default: () => []
        },

        filters: {
            type: Object,
            default: () => {}
        },

        label: {
            type: [String, Boolean],
            default: null
        },

        helpText: {
            type: String,
            default: null
        },

        context: {
            type: Object,
            default: null,
        },

        createTagText: {
            type: String,
            default: null
        },

        editTagText: {
            type: String,
            default: null
        },

        noTagsText: {
            type: String,
            default: null
        },

        autofocus: {
            type: Boolean,
            default: false
        },

        scope: {
            type: String,
            default: null
        },

        single: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        allowCreate: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            tags: [],
        }
    },

    computed: {
        selectableTags() {
            return this.tags.filter(tag => {
                return !this.normalizedValue.find(value => value.id === tag.id)
            })
        },

        normalizedValue() {
            if (!this.value) {
                return []
            }

            const value = Array.isArray(this.value) ? this.value : [this.value]

            return value.map(tag => {
                if (typeof tag === 'string') {
                    return this.tags.find(t => t.id === tag)
                }

                return tag
            })
        },
    },

    async created() {
        this.$startLoading()
        await this.fetchData()
        this.$stopLoading()
    },

    activated() {
        this.fetchData()
    },

    mounted() {
        if (this.autofocus) {
            this.$nextTick(() => {
                this.focus()
            })
        }
    },

    methods: {
        async fetchData() {
            const { data } = await (this.context || this).$axios.$get('tag?filter[type]=' + (this.scope || ''))
            this.tags = data
        },

        async onOptionCreated(newOption) {
            if (this.single) {
                const tag = await this.$axios.$post('tag', {
                    ...newOption,
                    type: this.scope,
                })

                this.$emit('input', tag)
                this.fetchData()
            }
        },

        focus() {
            const searchInput = this.$refs.combobox.$refs.search
            if (searchInput) {
                this.$nextTick(() => {
                    searchInput.focus()
                })
            }
        }
    }
}
</script>