import { userTimezone } from "~/util/helpers"
import { format, formatDistanceToNow } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import {
    de as deDateLocale,
    enGB as enDateLocale
} from 'date-fns/locale'

const dateLocales = {
    en: enDateLocale,
    de: deDateLocale
}

const currentLocale = window.localStorage.getItem('locale') || 'de'

export const parseDate = (dateString) => {
    return utcToZonedTime(dateString, userTimezone())
}

export const formatDate = (date, f = 'dd.MM.yyyy') => {
    if (!date || date === '0000-00-00 00:00:00') {
        return null
    }

    const zonedDate = parseDate(date)

    return format(zonedDate, f, {
        locale: dateLocales[currentLocale]
    })
}

export const formatDateTime = (date, f = 'dd.MM.yyyy kk:mm') => {
    return formatDate(date, f)
}

export const dateForDB = (date, withTime = true) => {
    if (date === 'now') {
        date = new Date()
    }

    if (withTime) {
        return formatDate(date, 'yyyy-MM-dd kk:mm:ss')
    }

    return formatDate(date, 'yyyy-MM-dd')
}

export const formatDateDistance = (date) => {
    if (typeof date === 'string') {
        date = new Date(date)
    }

    const zonedDate = parseDate(date)

    return formatDistanceToNow(zonedDate, {
        addSuffix: true,
        locale: dateLocales[currentLocale]
    })
}