import Vue from 'vue'
import { isTouchDevice, isMacOS } from '~/util/featureDetect'
import { formatDate, formatDateTime, formatDateDistance } from "~/util/date";

import '~/directives/tooltip'
import '~/directives/clickOutside'
import '~/directives/longPress'

export default ({ store, $config }, inject) => {
    if ($config.env != 'production') {
        const notice = document.createElement('div')
        notice.classList = 'd-block position-fixed px-2 py-1 bg-notice text-white text-uppercase rounded-top'
        notice.style.bottom = '0px'
        notice.style.left = '50%'
        notice.style.transform = 'translateX(-50%)'
        notice.style.zIndex = 9999
        notice.style.fontSize = '10px'
        notice.innerHTML = `Env &bull; <strong>${$config.env}</strong>`
        document.body.appendChild(notice)
    }

    inject('isProduction', $config.env === 'production')

    Vue.mixin({
        data() {
            return {
                debounceStack: []
            }
        },

        computed: {
            user() {
                return store.state.auth.user
            },

            $org() {
                return store.state.auth.user
                    ? store.state.auth.user.current_organization
                    : null
            },

            $isTouchDevice() {
                return isTouchDevice()
            },

            $canShare() {
                return navigator.share
            },
        },

        methods: {
            $toast(title, type = null, options = {}) {
                store.dispatch('toast/push', {
                    title,
                    type,
                    ...options
                })
            },

            $formatDate(date, f = 'dd.MM.yyyy') {
                return formatDate(date, f)
            },

            $formatDateTime(date, f = 'dd.MM.yyyy kk:mm') {
                return formatDateTime(date, f)
            },

            $dateForDB(date, withTime = true) {
                if (date === 'now') {
                    date = new Date()
                }

                if (withTime) {
                    return this.$formatDate(date, 'yyyy-MM-dd kk:mm:ss')
                }

                return this.$formatDate(date, 'yyyy-MM-dd')
            },

            $formatDateDistance(date) {
                return formatDateDistance(date)
            },

            $formatCurrency(value, precision = 2, withCurrencyCode = false) {
                const currentOrganization = store.state.auth.user
                    ? store.state.auth.user.current_organization
                    : null

                if (!value) {
                    value = 0
                }

                if (typeof value === 'string') {
                    value = parseFloat(value)
                }

                if (store.state.hideMoneyValues) {
                    return (withCurrencyCode ? currentOrganization.country.currency_code + ' ' : '') + Math.abs(value) <= 100 ? '***' : '****.**'
                }

                return (withCurrencyCode ? currentOrganization.country.currency_code + ' ' : '') + value.toLocaleString('de-CH', { minimumFractionDigits: precision, maximumFractionDigits: precision })
            },

            $formatTimeAmount(amount) {
                let hours = amount - (amount % 1)
                let minutes = Math.round((amount % 1) * 60)
    
                if (minutes < 10) {
                    minutes = '0' + minutes
                }
    
                return `${hours}:${minutes}`
            },

            $nl2br(str) {
                if (!str) {
                    return ''
                }

                const replaceStr = '$1<br>$2'
                return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
            },

            $stripTags(html) {
                let div = document.createElement('div')
                div.innerHTML = html
                return div.textContent || div.innerText || ""
            },

            $breakpoint(breakpoint) {
                const breakpoints = {
                    xs: 0,
                    sm: 576,
                    md: 768,
                    lg: 992,
                    xl: 1200,
                    hd: 1440
                }

                return window.matchMedia(`screen and (min-width: ${breakpoints[breakpoint]}px)`).matches
            },

            $debounce(delay, key = 'global') {
                return new Promise(resolve => {
                    clearTimeout(this.debounceStack[key])
                    this.debounceStack[key] = setTimeout(resolve, delay)
                })
            },

            $cssVar(variable) {
                return getComputedStyle(window.document.documentElement)
                    .getPropertyValue('--' + variable)
                    .trim()
            },

            async $share(text, url, successMessage = null) {
                return navigator.share({
                    title: 'StrawBlond',
                    text,
                    url
                }).then(() => {
                    this.$toast(successMessage, 'success')
                }).catch(error => {
                    // do nothing
                })
            },

            async $toClipboard(text, confirmationTitle = null) {
                return new Promise((resolve, reject) => {
                    navigator.permissions.query({ name: "clipboard-write" })
                        .then(result => {
                            if (result.state == "granted" || result.state == "prompt") {
                                navigator.clipboard.writeText(text).then(() => {
                                    this.$toast(confirmationTitle || this.$t('clipboard.written'), 'success', {
                                        icon: 'clipboard-check',
                                        message: confirmationTitle ? this.$t('clipboard.written') : null
                                    })

                                    resolve(text)
                                }, () => {
                                    reject(text)
                                })
                            }
                        })
                        .catch(() => {
                            reject(text)
                        })
                })
            },

            $ctrlOrCmd(key) {
                return isMacOS()
                    ? `⌘${key}`
                    : `Ctrl+${key}`
            },

            $altOrOption(key) {
                return isMacOS()
                    ? `⌥${key}`
                    : `Alt+${key}`
            },

            async $prompt(title, defaultValue = null, options = {}) {
                return this.$modal({
                    title,
                    defaultValue,
                    ...options
                }, 'prompt')
            }
        }
    })
}
