<template>
    <OModal
        :title="expense.name"
        :subtitle="expense.number"
        size="lg"
        frameless
    >
        <PdfRenderer
            v-if="expense.receipt && expense.receipt.mime_type === 'application/pdf'"
            :url="expense.receipt.url"
            :key="expense.receipt.id"
            :scale="2"
            class="aspect-a4"
            :paginate="false"
            :loading-message="$t('document.pdf-loading')"
            :http="app.$axios"
            />
            
        <img
            v-else-if="expense.receipt"
            :src="expense.receipt.url"
            class="img-fluid"
            >
    </OModal>
</template>
<script>
import PdfRenderer from '~/components/pdf-renderer.vue'
export default {
    components: {
        PdfRenderer,
    },

    props: ['expense', '$t', 'app'],
}
</script>