<template>
    <OModal
        :title="$t('project.create')"
        :confirmText="$t('crud.create')"
        :cancelText="$t('crud.cancel')"
        @close="$emit('close')"
        @confirm="$emit('confirm', project)">

        <OInput :label="$t('crud.name')" v-model="project.name" autofocus required></OInput>
        <ODatePicker :label="$t('project.starts_at')" :help-text="$t('project.starts_at-text')" v-model="project.starts_at" required></ODatePicker>
    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import MemberSelect from '~/components/member/select.vue'

export default {
    components: {
        AvatarComponent,
        MemberSelect
    },

    props: ['offer', '$t'],

    data() {
        return {
            project: {
                name: this.offer.name,
                member_id: null,
                company_id: this.offer.company.id,
                starts_at: this.$dateForDB(new Date()),
                billing_type: 'flat'
            }
        }
    },

    created() {
        this.project.member_id = this.$org.member_id
    },

    methods: {
        //
    }
}
</script>