<template>
    <OFormModal
        :title="$t('accounting.entry-create-template')"
        :confirm-text="$t('crud.save')"
        allow-overflow
        @confirm="$emit('confirm', { name, description })"
        >
        <OInput 
            :label="$t('accounting.entry-template-name')"
            :placeholder="$t('accounting.entry-template-name-text')"
            v-model="name"
            autofocus
            required
            />

        <OInput 
            :label="$t('crud.description')"
            :placeholder="$t('accounting.entry-template-description-text')"
            v-model="description"
            />
    </OFormModal>
</template>
<script>
import VatSelect from '@/components/vat/select.vue'
export default {
    props: ['$t', 'data'],

    components: {
        VatSelect,
    },

    data() {
        return {
            name: this.data.message,
            description: '',
        }
    }
}
</script>