<template>
    <Transition name="modal">
        <div v-if="show">
            <div class="modal d-block" tabindex="-1" role="dialog" @click="closeOnClickOutside && close()">
                <div class="modal-dialog modal-dialog-centered" :class="`modal-${size}`" role="document">
                    <div v-if="frameless" class="modal-content shadow-lg overflow-auto" @click.stop>
                        <slot />
                    </div>
                    <div v-else class="modal-content shadow-lg" @click.stop :class="{ 'overflow-auto': !allowOverflow }">
                        <div v-if="title" class="modal-header align-items-center gap-3">
                            <OIcon v-if="icon" :icon="Array.isArray(icon) ? icon : ['far', icon]" size="lg" :class="`align-self-start mt-1 text-${type}`" />
                            <div class="mr-auto">
                                <h4 class="modal-title">{{ title }}</h4>
                                <div v-if="subtitle" class="text-muted">{{ subtitle }}</div>
                            </div>
                            <OButton icon="times" icon-size="lg" class="btn btn-link py-0" @click="close()" aria-label="Close" title="Close"></OButton>
                        </div>
                        <div class="modal-body">
                            <slot 
                                :type="type"
                                :confirm-text="confirmText"
                                :confirm-icon="confirmIcon"
                                :cancel-text="cancelText"
                                :close="close"
                                :confirm="() => close(true)"
                                />
                        </div>
                        <div v-if="!hideFooter" class="modal-footer" :class="{ 'position-sticky bottom-0 portal-footer z-10': stickyFooter }">
                            <slot 
                                name="footer" 
                                :close="close"
                                :confirm="() => close(true)"
                                >
                                <OButton 
                                    v-if="cancelText" 
                                    ref="cancelButton"
                                    @click="close()" 
                                    type="link"
                                    >
                                    {{ cancelText }}
                                </OButton>
                                <OButton 
                                    v-if="confirmText" 
                                    ref="confirmButton"
                                    @click="close(true)" 
                                    :type="type || 'primary'" 
                                    :disabled="!allowConfirm"
                                    :icon="confirmIcon"
                                    >
                                    {{ confirmText }}
                                </OButton>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
            <OButton
                v-if="frameless"
                icon="times"
                v-tooltip="cancelText"
                class="position-absolute right-0 top-0 m-3"
                style="z-index: 1050;"
                @click="close()"
                />
            <div class="modal-backdrop fade show"></div>
        </div>
    </Transition>
</template>
<script>
export default {
    props: {
		title: {
			type: String,
			default: null
		},
        subtitle: {
            type: String,
            default: null
        },
		type: {
			type: String,
			default: null
		},
		size: {
			type: String,
			default: 'default'
        },
        icon: {
            type: [String, Array],
            default: null
        },
        confirmText: {
            type: String,
            default: 'OK'
        },
        confirmIcon: {
            type: String,
            default: null
        },
        cancelText: {
            type: String,
            default: null
        },
        allowOverflow: {
            type: Boolean,
            default: false
        },
        allowConfirm: {
            type: Boolean,
            default: true
        },
        closeOnClickOutside: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        stickyFooter: {
            type: Boolean,
            default: false
        },
        frameless: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show: false
        }
    },

    mounted() {
        this.show = true
        document.addEventListener('keyup', this.globalKeyDownHandler)

        this.$nextTick(() => {
            this.$refs.cancelButton && this.$refs.cancelButton.focus()
        })
    },

    destroyed() {
        document.removeEventListener('keyup', this.globalKeyDownHandler)
    },

    methods: {
        close(confirm = false) {
            this.show = false
            
            this.$nextTick(() => {
                if (confirm) {
                    this.$emit('confirm')
                } else {
                    this.$emit('close')
                }
            })
        },

        globalKeyDownHandler(ev) {
            if (ev.keyCode === 27) {
                ev.preventDefault()
                this.close()
            }
        }
    }
};
</script>
