import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('vat_rate')

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    async updateDefault({ commit, dispatch }, rate) {
        await dispatch('update', rate)
        await dispatch('refetchAll')
    },    
}