const setupGoogleAnalyticsScript = key => {
    if (typeof gtag === 'undefined') {
        const script = document.createElement('script')
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + key
        script.async = true
        script.onload = () => {
            window.dataLayer = window.dataLayer || []
            window.gtag = function() {
                dataLayer.push(arguments)
            }
            gtag('js', new Date())
            gtag('config', key)
        }

        document.body.append(script)
    }
}

const setupLinkedInTrackingScript = partnerId => {
    if (typeof _linkedin_data_partner_ids === 'undefined') {
        window._linkedin_partner_id = partnerId
        window._linkedin_data_partner_ids =
            window._linkedin_data_partner_ids || []
        window._linkedin_data_partner_ids.push(window._linkedin_partner_id)

        let scriptTag = document.createElement('script')
        scriptTag.async = true
        scriptTag.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
        document.body.appendChild(scriptTag)
    }
}

const setupHojarTrackingScript = id => {
    ;(function(h, o, t, j, a, r) {
        h.hj =
            h.hj ||
            function() {
                ;(h.hj.q = h.hj.q || []).push(arguments)
            }
        h._hjSettings = { hjid: id, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

export default ({ $config }, inject) => {
    inject('setupGoogleAnalyticsScript', () =>
        setupGoogleAnalyticsScript($config.googleAnalyticsId)
    )
    inject('setupLinkedInTrackingScript', () =>
        setupLinkedInTrackingScript($config.linkedinPartnerId)
    )

    if ($config.env === 'production') {
        setupHojarTrackingScript($config.hotjarId)
    }
}
