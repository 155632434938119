import confetti from 'canvas-confetti'

export const getRouteParts = (path) => {
    let parts = path.split('/').filter(part => part.length > 0)
    return parts
}

/**
 * Promise based debounced
 * @param {number} delay Debounce delay in seconds
 * @param {string} key Group debounces
 */
let debounceStack = []
export const debounce = (delay, key = 'global') => {
    return new Promise(resolve => {
        clearTimeout(debounceStack[key])
        debounceStack[key] = setTimeout(resolve, delay)
    })
}

/**
 * Turn a hashed string into a RGB color
 * @param {string} input String to turn into a color
 * @returns {string} RGB color
 */
export const stringToColor = (input) => {
    if (typeof input !== 'string') return

    let colors = [
        'red',
        'orange',
        'yellow',
        'green',
        'dark-green',
        'teal',
        'blue',
        'cyan',
        'indigo',
        'violet',
        'purple',
        'pink',
        'blond',
    ]

    let hash = 0
    for (let i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = colors[Math.abs(hash % colors.length)]

    return `var(--${color})`

    // let hash = 0
    // if (input.length === 0) return hash
    // for (let i = 0; i < input.length; i++) {
    //     hash = input.charCodeAt(i) + ((hash << 5) - hash)
    //     hash = hash & hash
    // }
    // let rgb = [0, 0, 0]
    // for (let i = 0; i < 3; i++) {
    //     let value = (hash >> (i * 8)) & 255
    //     rgb[i] = value
    // }
    // return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
}

/**
 * Strips all HTML tags from a given string
 * @param {string} html The html string to process
 */
export const stripTags = (html) => {
    return html.replace(/(<([^>]+)>)/ig,"")
}

export const downloadResponse = ({ data, headers }) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = headers["content-disposition"].split("filename=")[1]

    link.href = url;
    link.setAttribute('download', fileName)
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url)
}

export const userTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const deepClone = (obj) => {
    if (typeof window.structuredClone === 'function') {
        return window.structuredClone(obj)
    }

    return JSON.parse(JSON.stringify(obj))
}

export const celebrate = (options = {}) => {
    confetti({
        spread: 70,
        disableForReducedMotion: true,
        origin: { y: 0.95 },
        colors: [
            '#7ED321',
            '#08a946',

            '#ffffff',
            '#f6f6f9',
            '#f0f1f4',
            '#d3d5de',
            '#bec1cf',
            '#9da0af',
            '#5f616d',
            '#454754',
            '#2e2f38',
            '#181920',
            '#0f1014',
            '#090a11'
        ],
        ...options,
    })
}

export const isSwissIban = (iban) => {
    if (!iban) return false

    iban = iban.replace(/ /g, '').trim()

    return (iban.substring(0, 2) === 'CH' || iban.substring(0, 2) === 'LI')
        && iban.length === 21
}

export const isSwissQrIban = (iban) => {
    if (!iban) return false

    iban = iban.replace(/ /g, '').trim()

    return isSwissIban(iban)
        && iban.charAt(4) === '3'
}

export const isForeignIban = (iban) => {
    if (!iban) return false

    iban = iban.replace(/ /g, '').trim()

    return iban.length >= 15 
        && iban.length <= 33
        && iban.substring(0, 2) !== 'CH'
        && iban.substring(0, 2) !== 'LI'
}

export default {
    getRouteParts,
    debounce,
    stringToColor,
    stripTags,
    downloadResponse,
    celebrate,
}