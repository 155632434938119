var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OFormGroup',{attrs:{"id":_vm.$attrs.id,"label":_vm.label,"required":_vm.required,"help-text":_vm.helpText},scopedSlots:_vm._u([{key:"default",fn:function({ id }){return [_c('div',{class:{
        'input-group': _vm.$slots.prepend || _vm.$slots.append || _vm.prepend || _vm.append || _vm.type === 'password',
        'input-group-sm': _vm.size === 'sm',
        'input-group-lg': _vm.size === 'lg',
    }},[_vm._t("prepend",function(){return [(_vm.prepend)?_c('div',{staticClass:"input-group-prepend"},[(Array.isArray(_vm.prepend))?_c('div',{staticClass:"input-group-text",class:_vm.prependClass},[_c('OIcon',{attrs:{"icon":_vm.prepend}})],1):_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.prepend))])]):_vm._e()]}),_vm._v(" "),_c('input',{ref:"input",staticClass:"form-control",class:[{
                'bg-darkish border-dark text-white-real': _vm.active,
                'is-invalid': _vm.invalid || _vm.$attrs.error,
                'form-control-sm': _vm.size === 'sm',
                'form-control-lg': _vm.size === 'lg',
                'form-control-frameless': _vm.frameless,
            }, _vm.inputClass],attrs:{"type":_vm.showPassword ? 'text' : _vm.type,"name":_vm.name,"id":`form-input-${id}`,"list":`form-input-${id}-list`,"aria-describedby":`form-input-${_vm.label}-help`,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete ? typeof _vm.autocomplete === 'boolean' ? 'on' : _vm.autocomplete : 'off',"autofocus":_vm.autofocus,"required":_vm.required,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"max":_vm.max,"minlength":_vm.minlength,"maxlength":_vm.maxlength,"inputmode":_vm.inputmode || (_vm.type === 'number' ? 'decimal' : null)},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event.target.value)},"paste":function($event){_vm.$emit('paste', $event.clipboardData ? $event.clipboardData.getData('text/plain') : null)}}}),_vm._v(" "),(_vm.append)?_c('div',{staticClass:"input-group-append"},[(Array.isArray(_vm.append))?_c('div',{staticClass:"input-group-text",class:_vm.appendClass},[_c('OIcon',{attrs:{"icon":_vm.append}})],1):_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.append))])]):_vm._e(),_vm._v(" "),(_vm.type === 'password')?_c('div',{staticClass:"input-group-append"},[_c('a',{staticClass:"input-group-text btn btn-link",class:{ 'bg-darkish text-white': _vm.showPassword },attrs:{"href":"javascript:;","title":"Show password"},on:{"click":function($event){return _vm.togglePasswordVisiblity()}}},[_c('OIcon',{attrs:{"icon":['far', _vm.showPassword ? 'eye-slash' : 'eye'],"fixed-width":""}})],1)]):_vm._e()],2),_vm._v(" "),(_vm.datalist)?_c('datalist',{attrs:{"id":`form-input-${id}-list`}},_vm._l((_vm.datalist),function(option){return _c('option',{domProps:{"value":option}})}),0):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }