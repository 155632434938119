<template>
    <div class="app">
        <div class="brand-line"></div>
        
        <main>
            <nuxt />
        </main>

        <ToastContainer />
    </div>
</template>
<script>
import ToastContainer from '~/components/toast-container.vue'
export default {
    components: {
        ToastContainer
    }
}
</script>