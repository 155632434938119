<template>
    <OModal
        size="lg"
        :title="$t('document_element.add-products')"
        :subtitle="$t('product.explainer')"
        :confirm-text="$t('document_element.add-as-positions') + ` (${products.length})`"
        :allow-confirm="products.length > 0"
        @close="$emit('close')"
        @confirm="$emit('confirm', products)"
        sticky-footer
        >

        <div class="position-sticky bg-white" style="top: 1rem; z-index: 10;">
            <OInput :placeholder="$t('crud.search') + ` (${$t('product.name')}, ${$t('product.sku')})`" v-model="search" autofocus />
        </div>

        <CrudListData resource="product" :include="['tag']" :per-page="null" v-slot="{ items }" class="px-1">
            <table class="table table-hover">
                <tbody v-for="(section, index) in getSections(items)" :key="index">
                    <tr class="bg-ultralight">
                        <th class="py-2" style="width: 3rem;"></th>
                        <th class="py-2"></th>
                        <th colspan="2" class="py-2">{{ section.name }}</th>
                    </tr>
                    <tr v-for="product in section.products" :key="product.id">
                        <td class="py-2">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" :value="product.id" v-model="products" :id="`product-${product.id}`" />
                                <label class="custom-control-label text-dark" :for="`product-${product.id}`"></label>
                            </div>
                        </td>
                        <td class="py-2">
                            <label v-if="product.sku" class="text-muted tabular-nums m-0 user-select-none pointer" :for="`product-${product.id}`">{{ product.sku }} </label>
                        </td>
                        <td class="py-2">
                            <label class="text-dark m-0 user-select-none pointer text-wrap" :for="`product-${product.id}`">
                                {{ product.name }}
                            </label>
                        </td>
                        <td class="py-2 text-right tabular-nums text-nowrap">
                            <span v-if="product.is_percentage">{{ parseFloat(product.price).toLocaleString('de-CH', { minimumFractionDigits: 2 }) }} %</span>
                            <span v-else-if="product.price">{{ $org.country.currency_code }} {{ parseFloat(product.price).toLocaleString('de-CH', { minimumFractionDigits: 2 }) }}</span>
                            <span v-if="product.unit">/ {{ product.unit.name }}</span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="items.length === 0 || getSections(items).length === 0">
                    <tr>
                        <td colspan="2" class="text-center">{{ $t('crud.dataset-empty') }}</td>
                    </tr>
                </tbody>
            </table>

            <OAlert 
                v-if="items.length === 2 && items.every(p => p.name.startsWith('Beispiel Produkt'))" 
                type="info" 
                icon="info-circle"
                class="mt-3 mb-0"
                >
                {{ $t('product.only-samples') }}
            </OAlert>
        </CrudListData>
    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import CrudListData from '~/components/crud/list-data.vue'
import PipeThrough from '~/components/util/pipe.vue'

export default {
    components: {
        AvatarComponent,
        CrudListData,
        PipeThrough
    },

    props: ['document', '$t'],

    data() {
        return {
            products: [],
            search: '',
        }
    },

    methods: {
        getSections(products) {
            const sections = {}

            products
                .filter(
                    product => product.name.toLowerCase().includes(this.search.toLowerCase())
                    || product.sku?.toLowerCase().includes(this.search.toLowerCase()) 
                    || this.search.length === 0
                    )
                .sort((a, b) => a.sku && b.sku ? a.sku - b.sku : a.name.localeCompare(b.name))
                .forEach(product => {
                    const sectionName = product.tag ? product.tag.name : this.$t('product.uncategorized')
                    if (!sections[sectionName]) {
                        sections[sectionName] = []
                    }

                    sections[sectionName].push(product)
                })

            return Object.keys(sections).map(sectionName => {
                return {
                    name: sectionName,
                    products: sections[sectionName]
                }
            })
        },
    }
}
</script>