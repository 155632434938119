import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { isInternetExplorer } from '~/util/featureDetect'

export default ({ $config }) => {
    if (!$config.sentryDsn || isInternetExplorer()) return

    Sentry.init({
        dsn: $config.sentryDsn,
        integrations: [new Integrations.Vue({
            Vue,
            attachProps: true,
            logErrors: true
        })],
    })
}