<template>
    <div class="btn-group">
        <slot name="button" />
        <ODropdown ref="dropdown">
            <template #toggle="{ uuid, isOpen }">
                <div 
                    class="rounded-right overflow-hidden" 
                    style="border-left: 1px solid var(--light-blond);" 
                    v-tooltip="dropdownTooltip"
                    >
                    <OButton :id="uuid" :aria-expanded="isOpen.toString()" :type="type" icon="angle-down" class="rounded-0" />
                </div>
            </template>
            <slot />
        </ODropdown>
    </div>
</template>
<script>
import OButton from './Button.vue'
import ODropdown from './Dropdown.vue'

export default {
    components: {
        OButton,
        ODropdown
    },

    props: {
        type: {
            type: String,
            default: 'primary'
        },

        dropdownTooltip: {
            type: String,
            default: null,
        }
    },

    methods: {
        toggle() {
            this.$refs.dropdown.toggle()
        }
    }
}
</script>