<template>
    <div v-if="!loadingState.default && customFields.length > 0" class="mb-n3">
        <div class="d-flex align-items-center">
            <hr class="flex-grow-1" />
            <div class="text-muted mx-3 small font-weight-semibold">
                {{ __('custom_field.custom_fields') }}
            </div>
            <hr class="flex-grow-1" />
        </div>
        <div v-for="customField in customFields" :key="customField.id">
            <OInput
                v-if="customField.type === 'text'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event }"
                :required="customField.is_required"
            />
            <OInput
                v-if="customField.type === 'number'"
                type="number"
                step="any"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event }"
                :required="customField.is_required"
            />
            <OCurrencyInput 
                v-else-if="customField.type === 'currency'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event }"
                :required="customField.is_required"
                :currency="$org.country.currency_code"
                />
            <OTextarea
                v-else-if="customField.type === 'textarea'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event}"
                :required="customField.is_required"
            />
            <ODatePicker
                v-else-if="customField.type === 'date'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event}"
                :required="customField.is_required"
            />
            <ODatePicker
                v-else-if="customField.type === 'datetime'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event}"
                :required="customField.is_required"
                :time="true"
                placeholder="tt.mm.jjjj hh:mm"
            />
            <OSelect
                v-else-if="customField.type === 'select'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event}"
                :required="customField.is_required"
                :options="customField.options.map(option => ({ value: option, label: option }))"
                select-first
            />
            <OToggle 
                v-else-if="customField.type === 'toggle'"
                :label="customField.name"
                :help-text="customField.description"
                :value="item.custom_fields[customField.key]"
                @input="item.custom_fields = { ...item.custom_fields, [customField.key]: $event}"
                :states="[
                    __('status.yes'),
                    __('status.no')
                ]"
                />
        </div>
    </div>
</template>
<script>
import hasLoadingState from "~/mixins/hasLoadingState"

export default {
    mixins: [hasLoadingState],

    props: {
        item: {
            type: Object,
            required: true
        },

        scope: {
            type: String,
            default: null
        },

        context: {
            type: Object,
            default: null,
        },

        t: {
            type: Function,
            default: null,
        },
    },

    data() {
        return {
            customFields: []
        }
    },

    async created() {
        this.$startLoading()
        await this.fetchData()
        this.$stopLoading()
    },

    methods: {
        async fetchData() {
            const { data } = await (this.context || this).$axios.$get('custom_field?filter[scope]=' + (this.scope || ''))
            this.customFields = data
        },

        __() {
            return (this.t || this.$t).apply(this, arguments)
        }
    }
}
</script>
