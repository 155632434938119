<template>
  <div class="app pb-5">
    <div class="bokeh-bubbles">
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
    </div>

    <nuxt keep-alive :keep-alive-props="{ max: 5 }" />

    <Notifications 
        :open="$store.state.notification.isSlideoutOpen"
        @close="$store.dispatch('notification/toggleSlideout')" />

    <ToastContainer />
  </div>
</template>
<script>
import * as Sentry from '@sentry/browser'
import HeaderComponent from '~/components/header.vue'
import ToastContainer from '~/components/toast-container.vue'
import Notifications from '~/components/notification/notifications.vue'

export default {
    middleware: [
        'auth',
    ],

    components: {
        HeaderComponent,
        ToastContainer,
        Notifications,
    },

    mounted() {
        if ("Notification" in window) {
            setTimeout(() => {
                Notification.requestPermission(result => {
                    this.$store.commit('notification/setNotificationsPermission', result)
                })
            }, 5000)
        }

        if (this.$auth.user) {
            // Sentry identification
            Sentry.setUser({
                id: this.$auth.user.id,
                username: this.$auth.user.fullName,
                email: this.$auth.user.email,
                organization: this.$auth.user.current_organization ? this.$auth.user.current_organization.name : null,
                current_role: this.$auth.user.current_organization ? this.$auth.user.current_organization.role_in_organization : null
            })

            // June identification
            this.$analyticsIdentify(this.$auth.user, {
                theme: this.$getActualTheme(),
                two_factor_enabled: this.$auth.user.two_factor_enabled,
            })
        }
    }
}
</script>