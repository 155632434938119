var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"dropdown d-inline-block",attrs:{"role":"menu"}},[_c('div',{ref:"button",staticClass:"dropdown-button",on:{"click":function($event){return _vm.toggle()}}},[_vm._t("toggle",function(){return [(_vm.label)?_c('OButton',{attrs:{"id":_vm.uuid,"type":_vm.buttonType,"size":_vm.buttonSize,"aria-expanded":_vm.isOpen.toString(),"disabled":_vm.disabled}},[_vm._v(_vm._s(_vm.label)+" "),_c('OIcon',{staticClass:"ml-2",attrs:{"icon":['far', _vm.buttonIcon]}})],1):_c('OButton',{staticClass:"btn-icon",attrs:{"id":_vm.uuid,"type":_vm.buttonType,"size":_vm.buttonSize,"aria-expanded":_vm.isOpen.toString(),"disabled":_vm.disabled}},[_c('OIcon',{attrs:{"icon":['far', _vm.buttonIcon],"fixed-width":""}})],1)]},{"toggle":_vm.toggle,"uuid":_vm.uuid,"isOpen":_vm.isOpen,"disabled":_vm.disabled})],2),_vm._v(" "),_c('transition',{attrs:{"name":"dropdown"}},[_c('div',{ref:"menu",staticClass:"dropdown-menu",class:[_vm.isOpen ? _vm.menuClass : null, {
              'show': _vm.isOpen,
              'dropdown-menu-limit': _vm.limit,
              'mt-2': _vm.align === 'bottom-start' || _vm.align === 'bottom-end',
              'mb-2': _vm.align === 'top-start' || _vm.align === 'top-end',
              'ml-2': _vm.align === 'right-start' || _vm.align === 'right-end',
              'mr-2': _vm.align === 'left-start' || _vm.align === 'left-end',
          }],attrs:{"aria-labelledby":_vm.uuid},on:{"click":_vm.dropdownItemClicked}},[_vm._t("default",null,{"toggle":_vm.toggle,"isOpen":_vm.isOpen,"close":_vm.close})],2)]),_vm._v(" "),_c('transition',{attrs:{"name":"dropdown-backdrop"}},[(_vm.isOpen)?_c('div',{staticClass:"dropdown-backdrop",on:{"click":function($event){return _vm.close()}}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }