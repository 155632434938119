import Storage from '~/util/storage'
import { track as analyticsTrack } from '~/plugins/analytics'

const sidebarBreakpointQuery = 'screen and (min-width: 1200px)'

export const state = () => {
    return {
        locales: ['de'],
        locale: window.localStorage.getItem('locale') || 'de',
        sidebarOpen: window.matchMedia(sidebarBreakpointQuery).matches || false,
        validationErrors: [],
        showOnboarding: Storage.get('showOnboarding', true),
        hideMoneyValues: Storage.get('hideMoneyValues', false),
        screencastMode: Storage.get('screencastMode', false),
        currentTheme: Storage.get('theme'),
        lastProductUpdateId: Storage.get('lastProductUpdateId'),
    }
}

export const mutations = {
    setLocale(state, locale) {
        if (state.locales.includes(locale)) {
            state.locale = locale
            window.localStorage.setItem('locale', locale)
            window.document.documentElement.lang = locale
            this.app.i18n.locale = locale
        }
    },

    setSidebarOpen(state, open) {
        state.sidebarOpen = open
    },

    toggleSidebar(state) {
        state.sidebarOpen = !state.sidebarOpen
    },

    setValidationErrors(state, errors) {
        state.validationErrors = errors
    },

    setShowOnboarding(state, show) {
        state.showOnboarding = show
        Storage.set('showOnboarding', show)
    },

    setHideMoneyValues(state, hidden) {
        state.hideMoneyValues = hidden
        Storage.set('hideMoneyValues', hidden)

        analyticsTrack('Toggle money values', {
            state: hidden ? 'hidden' : 'visible'
        })
    },

    setScreencastMode(state, enabled) {
        state.screencastMode = enabled
        Storage.set('screencastMode', enabled)
    },

    setCurrentTheme(state, theme) {
        state.currentTheme = theme

        analyticsTrack('Theme changed', {
            theme
        })
    },

    setLastProductUpdateId(state, id) {
        state.lastProductUpdateId = id
        Storage.set('lastProductUpdateId', id)
    }
}

export const getters = {

}

export const actions = {
    closeOnSmallDevices({ commit }) {
        if (!window.matchMedia(sidebarBreakpointQuery).matches) {
            commit('setSidebarOpen', false)
        }
    },

    changeLocale({ commit }, locale) {
        commit('setLocale', locale)
    },

    showOnboarding({ commit }) {
        commit('setShowOnboarding', true)
    },

    dismissOnboarding({ commit }) {
        commit('setShowOnboarding', false)
    },

    toggleSidebar({ commit }) {
        commit('toggleSidebar')
    },

    async checkForProductUpdates({ commit, state }) {
        const latestProductUpdate = await this.$axios.$get('/changelog/latest')

        if (latestProductUpdate.id !== state.lastProductUpdateId) {
            commit('setLastProductUpdateId', latestProductUpdate.id)

            return latestProductUpdate
        }

        return null
    }
}
