<template functional>
    <nav :class="[data.staticClass, data.class]" :aria-label="props.label" v-bind="data.attrs">
        <ul class="nav align-items-end" :class="[
            props.pills ? 'nav-pills nav-sm' : [
                'nav-tabs', {
                    'nav-tabs-borderless': props.borderless,
                }],
        ]">
            <slot />
        </ul>
    </nav>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: null
        },
        pills: {
            type: Boolean,
            default: false,
        },
        borderless: {
            type: Boolean,
            default: false,
        },
    }
}
</script>