<template>
    <OModal
        :title="$t('editor.writing-assistant-improve')"
        @close="$emit('close')"
        @confirm="$emit('confirm', prompt)"
        :cancel-text="$t('crud.cancel')"
        size="lg"
        >

        <label class="label mb-0">Text</label>
        <div class="formatted-text mb-3">
            <span class="text-muted" v-html="$nl2br(text)"></span>
        </div>

        <OTextarea
            :label="$t('editor.writing-assistant-improve-label')"
            :placeholder="$t('editor.writing-assistant-improve-placeholder')"
            v-model="prompt"
            required
            />

    </OModal>
</template>
<script>
export default {
    props: ['$t', 'text'],

    data() {
        return {
            prompt: '',
        }
    }
}
</script>