import Vue from 'vue'
import BaseModal from '~/components/modals/base'

export default ({ app, store }, inject) => {
    const createModal = (props = {}, component = BaseModal, rejectOnCancel = false) => {
        if (typeof component === 'string') {
            component = require(`~/components/modals/${component}.vue`).default
        }

        return new Promise((resolve, reject) => {
            const ModalComponent = new Vue({
                render(h) {
                    return h(component, {
                        props: {
                            app: app,
                            routerPush: (url) => {
                                ModalComponent.$destroy()
                                ModalComponent.$el.remove()
                                app.router.push(url)
                            },
                            confirmText: app.i18n.t('crud.ok'),
                            cancelText: app.i18n.t('crud.cancel'),
                            '$t': (key, params) => app.i18n.t(key, params),
                            ...props
                        },
                        on: {
                            confirm: ($event) => {
                                resolve($event)
                                ModalComponent.$destroy()
                                ModalComponent.$el.remove()
                            },
                            close: ($event) => {
                                if (rejectOnCancel) {
                                    reject($event)
                                }
                                
                                ModalComponent.$destroy()
                                ModalComponent.$el.remove()
                            }
                        }
                    })
                }
            })

            ModalComponent.$store = store
            ModalComponent.i18n = app.i18n
            
            ModalComponent.$mount()
            document.body.appendChild(ModalComponent.$el)
        })
    }

    inject('createModal', createModal)
    Vue.prototype.$modal = createModal
}