<template>
    <OLoading :loading="loadingState.default">
        <OForm @submit="save()">
            <div class="crud-header crud-sticky d-flex align-items-center">
                <OButton type="link" icon="arrow-left" @click="$router.back()" class="ml-n2 mr-auto">
                    <span class="d-none d-md-inline">{{ $t('crud.back') }}</span> <span class="shortcut ml-2" hidden>B</span>
                </OButton>
                <slot name="additional-actions" />
                <OButton type="primary" icon="check" submit class="ml-3 d-none d-md-inline-block">
                    <span class="d-none d-md-inline">{{ createLabel || $t('crud.save') }}</span> <span class="shortcut ml-1" hidden>S</span>
                </OButton>
            </div>

            <div v-if="$scopedSlots.default" class="card card-body card-form">
                <slot :item="item" :invalid="invalid" />
            </div>
            
            <slot name="content" :item="item" :invalid="invalid" />

            <button type="submit" class="d-md-none crud-primary-action">
                <OIcon :icon="['far', 'check']" size="lg"></OIcon>
            </button>
        </OForm>
    </OLoading>
</template>
<script>
import { getRouteParts } from '~/util/helpers'
import HasLoadingState from '~/mixins/hasLoadingState'

export default {
    mixins: [HasLoadingState],

    props: {
        resource: {
            type: String,
            default: null
        },
        init: {
            type: Object,
            default: () => {}
        },
        watchInit: {
            type: Boolean,
            default: false
        },
        redirectTo: {
            type: [String, Object, Boolean],
            default: null
        },
        createLabel: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            resourceName: this.resource || getRouteParts(this.$route.path)[0],
            invalid: [],
            item: {
                ...this.init
            }
        }
    },

    activated() {
        this.$bindKeyboard({
            's': () => this.save(),
            'b': () => this.$router.back(),
        })
    },

    methods: {
        save() {
            this.$startLoading()
            this.$store.dispatch(this.resourceName + '/store', this.item).then(data => {
                this.invalid = []
                this.item = {
                    ...this.init
                }
                
                if (this.redirectTo === 'back') {
                    this.$router.back()
                } else if (this.redirectTo !== false) {
                    this.$router.push(this.redirectTo || '/' + this.resourceName)
                }

                this.$emit('saved', data)
            }).catch(error => {
                if (parseInt(error.response && error.response.status) == 422) {
                     this.invalid = error.response.data.errors
                }
            }).finally(() => {
                this.$stopLoading()
            })
        }
    },

    watch: {
        init: {
            handler(value) {
                if (this.watchInit) {
                    this.item = {
                        ...value
                    }
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>