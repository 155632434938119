<template>
    <OModal 
        ref="modal" 
        :title="$t('organization.changed')"
        @close="$emit('confirm', oldOrganization)"
        size="lg"
        >
        <p>{{ $t('organization.changing', { name: newOrganization.name }) }}</p>

        <template #footer>
            <div class="btn-group w-100">
                <OButton
                    type="secondary"
                    class="flex-1"
                    @click="$emit('confirm', oldOrganization)"><span v-html="$t('organization.cancel-change', { name: oldOrganization.name })"></span>
                </OButton>
                <OButton
                    type="primary"
                    class="flex-1"
                    @click="$emit('confirm', newOrganization)"><span v-html="$t('organization.confirm-change', { name: newOrganization.name })"></span>
                </OButton>
            </div>
        </template>
    </OModal>
</template>
<script>
export default {
    props: ['$t', 'app', 'newOrganization', 'oldOrganization'],

    data() {
        return {
            failed: false,
            password: '',
        }
    },
}
</script>