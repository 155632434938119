export const isInternetExplorer = () => {
    const ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE ') // IE 10 or older
    const trident = ua.indexOf('Trident/') //IE 11

    return msie > 0 || trident > 0
}

export const isTouchDevice = () => {
    return window.matchMedia('(hover: none)').matches
}

export const getOS = () => {
    if (navigator.appVersion.indexOf('Win') != -1) return 'Windows'
    if (navigator.appVersion.indexOf('Mac') != -1) return 'MacOS'
    if (navigator.appVersion.indexOf('X11') != -1) return 'UNIX'
    if (navigator.appVersion.indexOf('Linux') != -1) return 'Linux'
}

export const isMacOS = () => {
    return getOS() === 'MacOS'
}