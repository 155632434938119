export default async function ({ app, store, redirect, from }) {
    const user = store.getters['user/user']
    if (!user || (user && !user.verified)) {
        store.dispatch('toast/push', {
            title: app.i18n.t('user.verification-needed'),
            message: app.i18n.t('user.verification-needed-text'),
            type: 'warning'
        })

        return redirect(from)
    }
}