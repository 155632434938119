<script>
export default {
    props: ['value'],

    render() {
        return this.$scopedSlots.default({
            value: this.value
        })
    },
}
</script>