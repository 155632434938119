export const actions = {
    /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
    async upload({ commit }, { file, endpoint, onProgress, options = {} }) {
        const formData = new FormData()
        formData.append('file', file)

        return this.$axios.$post('media/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                if (typeof onProgress === 'function') {
                    onProgress((progressEvent.loaded / progressEvent.total) * 100);
                }
            }
        }).then(data => {
            return this.$axios.$post(endpoint, {
                uuid: data.uuid,
                key: data.key,
                name: file.name,
                content_type: file.type,
                data: options.data || {}
            })
        }).catch(error => {
            console.error(error)
        })
    }
}