<template>
    <CrudListData
        resource="bank_account"
        v-slot="{ items }"
        >
        <OSearch 
            :label="label || $t('bank_account.bank_account')"
            :value="value"
            @input="$emit('input', $event)"
            :options="items.map(bankAccount => ({
                value: bankAccount.id,
                label: bankAccount.name,
                ...bankAccount,
            }))"
            v-bind="$attrs"
            >

            <template #selected="{ option }">
                <div class="d-flex w-100 align-items-center gap-2 ml-n2">
                    <img
                        v-if="option.bank_iid"
                        :key="option.bank_iid"
                        :src="`/bank-icons/${option.bank_iid}.png`"
                        :alt="option.bank_name"
                        class="rounded-sm bg-white-real"
                        loading="lazy"
                        style="width: 1.25rem; height: auto;"
                        >
                    <div>{{ option.name }}</div>
                    <div class="ml-auto mr-n3 text-muted text-truncate small">{{ option.iban }}</div>
                </div>
            </template>

            <template #default="{ option: bankAccount }">
                <div class="d-flex align-items-center gap-3">
                    <img
                        v-if="bankAccount.bank_iid"
                        :key="bankAccount.bank_iid"
                        :src="`/bank-icons/${bankAccount.bank_iid}.png`"
                        :alt="bankAccount.bank_name"
                        class="rounded bg-white-real"
                        loading="lazy"
                        style="width: 2.2rem; height: auto;"
                        >
                    <div>
                        <div>{{ bankAccount.name }}</div>
                        <div class="text-muted">{{ bankAccount.iban }}</div>
                    </div>
                </div>
            </template>

        </OSearch>

    </CrudListData>
</template>
<script>
import CrudListData from '@/components/crud/list-data.vue'
export default {
    inheritAttrs: false,
    components: {
        CrudListData
    },
    props: {
        value: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        }
    },
}
</script>