<template>
    <div class="min-vh-100 d-flex flex-column justify-content-between">
        <div class="bokeh-bubbles">
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
        </div>

        <div 
            class="position-absolute-cover only-light grayscale"
            style="background-image: url(/mesh-1.png); background-size: cover;"
            ></div>

        <div class="d-flex align-items-md-center">
            <div class="container px-3 mb-5">
                <div class="row justify-content-center">
                    <div class="col-md-9 col-lg-7 col-xl-6 col-hd-5">
                        <nuxt />
                    </div>
                </div>
            </div>

            <ToastContainerComponent />
        </div>
        <footer class="bg-backdrop-body bg-blur px-5 py-3 text-muted text-center">
            <div class="row align-items-center">
                <div class="col-12 col-md-auto">
                    <strong class="text-black">{{ $t('app.title') }}</strong> • Copyright &copy; {{ new Date().getFullYear() }} Racerfish AG
                </div>
                <div class="col-12 col-md-auto ml-md-auto">
                    <a :href="'https://strawblond.com/' + $t('footer.terms.href')" class="btn btn-link">{{ $t('footer.terms.label') }}</a>
                    <a :href="'https://strawblond.com/' + $t('footer.privacy.href')" class="btn btn-link">{{ $t('footer.privacy.label') }}</a>
                    <a :href="'https://strawblond.com/' + $t('footer.help.href')" class="btn btn-link">{{ $t('footer.help.label') }}</a>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import ToastContainerComponent from '~/components/toast-container.vue'
export default {
    middleware: ['outdatedBrowser'],

    auth: 'guest',

    components: {
        ToastContainerComponent
    },
}
</script>