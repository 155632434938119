<template>
    <OFormModal
        :title="$t('payments.edit')"
        :subtitle="expense.name"
        :form="form"
        @close="$emit('close')"
        @confirm="$emit('confirm', form)"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('crud.save')"
        :before-confirm="beforeConfirm"
        :close-on-click-outside="false"
        size="xl"
        >
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <ODatePicker
                            :label="$t('payments.execute_at')"
                            v-model="form.execute_at"
                            required
                            />
                    </div>
                    <div class="col-12 col-md-6">
                        <OCurrencyInput
                            ref="amountInput"
                            :label="$t('payments.amount')"
                            v-model="form.amount"
                            required
                            />
                    </div>
                    <div v-if="$org.bank_accounts_count > 0" class="col-12">
                        <BankAccountSelect
                            :label="$t('payments.bank-account')"
                            v-model="form.bank_account_id"
                            select-first
                            />
                    </div>
                </div>

                <OSearch
                    :label="$t('expense.bank-payment-type')"
                    v-model="form.bank_payment_type"
                    :options="[
                        { value: 'credit_transfer_qrr', label: $t('expense.bank-payment-types.credit_transfer_qrr'), description: $t('expense.bank-payment-types.credit_transfer_qrr-text') },
                        { value: 'credit_transfer_scor', label: $t('expense.bank-payment-types.credit_transfer_scor'), description: $t('expense.bank-payment-types.credit_transfer_scor-text') },
                        { value: 'credit_transfer', label: $t('expense.bank-payment-types.credit_transfer'), description: $t('expense.bank-payment-types.credit_transfer-text') },
                        { value: null, label: $t('expense.bank-payment-types.null'), description: $t('expense.bank-payment-types.null-text') },
                    ]"
                    hide-search
                    />

                <hr class="mx-n3">

                <div v-if="payment.bank_payment_type" class="row">
                    <div class="col-12">
                        <OInput 
                            v-model="form.creditor_iban"
                            :label="form.bank_payment_type === 'credit_transfer_qrr' ? 'QR-IBAN' : 'IBAN'"
                            :format="{
                                swapHiddenInput: true,
                                delimiter: ' ',
                                blocks: [4, 4, 4, 4, 4, 4, 4, 4, 2],
                                uppercase: true,
                                delimiterLazyShow: true,
                            }"
                            :required="!!form.bank_payment_type"
                            />
                    </div>
                    <div v-if="isForeignIban(form.creditor_iban)" class="col-12">
                        <OInput
                            v-model="form.creditor_bic"
                            label="BIC/SWIFT"
                            minlength="8"
                            maxlength="11"
                            required
                            />
                    </div>
                    <div v-if="['credit_transfer_qrr', 'credit_transfer_scor'].includes(form.bank_payment_type)" class="col-12">
                        <OInput 
                            v-model="form.creditor_reference"
                            :label="$t('payments.creditor-reference')"
                            maxlength="70"
                            :format="{
                                swapHiddenInput: true,
                                delimiter: ' ',
                                blocks: [2, 5, 5, 5, 5, 5],
                                delimiterLazyShow: true,
                            }"
                            required
                            />
                    </div>
                    <div class="col-12">
                        <OInput 
                            v-model="form.creditor_name"
                            :label="$t('expense.biller-address')"
                            :placeholder="$t('address.name')"
                            maxlength="70"
                            class="mb-2"
                            required
                            />
                    </div>
                    <div class="col-8">
                        <OInput 
                            v-model="form.creditor_street"
                            :placeholder="$t('address.street')"
                            maxlength="70"
                            class="mb-2"
                            />
                    </div>
                    <div class="col-4">
                        <OInput 
                            v-model="form.creditor_building_no"
                            :placeholder="$t('address.building-no')"
                            maxlength="70"
                            class="mb-2"
                            />
                    </div>
                    <div class="col-4">
                        <OInput 
                            v-model="form.creditor_zip"
                            :placeholder="$t('address.zip')"
                            maxlength="70"
                            class="mb-2"
                            />
                    </div>
                    <div class="col-8">
                        <OInput 
                            v-model="form.creditor_city"
                            :placeholder="$t('address.city')"
                            maxlength="70"
                            class="mb-2"
                            />
                    </div>
                    <div class="col-12">
                        <CountrySelect 
                            :label="' '"
                            :placeholder="$t('address.country')" 
                            v-model="form.creditor_country"
                            />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 pb-3">
                <iframe 
                    v-if="expense.receipt && expense.receipt.url" 
                    :key="expense.receipt.url"
                    :src="expense.receipt.url + '#toolbar=0&navpanes=0&scrollbar=0'" 
                    frameborder="0"
                    class="border w-100 aspect-a4 rounded"
                ></iframe>
            </div>
        </div>
    </OFormModal>
</template>
<script>
import BankAccountSelect from '~/components/bank-account/select.vue'
import CountrySelect from '~/components/country/select.vue'
import { isSwissIban, isSwissQrIban, isForeignIban } from '~/util/helpers'

export default {
    components: {
        CountrySelect,
        BankAccountSelect,
    },

    props: ['expense', 'payment', '$t', 'beforeConfirm'],

    data() {
        return {
            form: {
                execute_at: this.payment.execute_at,
                amount: this.payment.amount,
                message: this.payment.message,
                bank_payment_type: this.payment.bank_payment_type,
                bank_account_id: this.payment.bank_account ? this.payment.bank_account.id : null,
                creditor_name: this.payment.creditor_name,
                creditor_street: this.payment.creditor_street,
                creditor_building_no: this.payment.creditor_building_no,
                creditor_zip: this.payment.creditor_zip,
                creditor_city: this.payment.creditor_city,
                creditor_country: this.payment.creditor_country,
                creditor_iban: this.payment.creditor_iban,
                creditor_bic: this.payment.creditor_bic,
                creditor_reference: this.payment.creditor_reference,
            },
        }
    },

    watch: {
        'form.creditor_iban'(iban) {
            if (!iban || iban.replaceAll(' ', '').trim().length < 21) return

            if (isSwissQrIban(iban)) {
                this.form.bank_payment_type = 'credit_transfer_qrr'
                this.$toast(this.$t('expense.bank-payment-type-changed', { type: this.$t('expense.bank-payment-types.' + this.form.bank_payment_type) }), 'info', {
                    icon: 'qrcode',
                })
            } else if (isSwissIban(iban)) {
                this.form.bank_payment_type = 'credit_transfer'
                this.$toast(this.$t('expense.bank-payment-type-changed', { type: this.$t('expense.bank-payment-types.' + this.form.bank_payment_type) }), 'info', {
                    icon: 'sync-alt',
                })
            } else if (isForeignIban(iban)) {
                this.form.bank_payment_type = 'credit_transfer'
                this.$toast(this.$t('expense.bank-payment-type-changed', { type: this.$t('expense.bank-payment-types.' + this.form.bank_payment_type) }), 'info', {
                    icon: 'sync-alt',
                })
            }
        },
    },

    methods: {
        isSwissIban,
        isSwissQrIban,
        isForeignIban,
    }
}
</script>