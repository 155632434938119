<template>
    <OFormGroup 
        :label="label" 
        :helpText="helpText"
        v-bind="$attrs"
        >
        <div class="custom-control custom-switch">
            <input :id="`form-input-${id}`"
                :name="name"
                type="checkbox" 
                class="custom-control-input" 
                :disabled="disabled"
                :checked="value"
                @input="$emit('input', $event.target.checked)"
                role="switch"
                :aria-checked="value ? 'true' : 'false'"
                >
            <label :for="`form-input-${id}`" class="custom-control-label d-inline-block" :class="labelClass">{{ states[value ? 0 : 1] || states[0] || '' }}</label>
        </div>
    </OFormGroup>
</template>
<script>
import OFormGroup from './Group.vue'
export default {
    components: {
        OFormGroup,
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null
        },
        states: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: String,
            default: null
        },
    },

    computed: {
        id() {
            return this.name || this.label || parseInt(Math.random() * 1000)
        }
    }
}
</script>
