import Vue from 'vue'

const exclude = [
    '.flatpickr-calendar',
]

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // Check if element contains click target
            if (el.contains(event.target)) {
                return
            }

            // Check if exclude contains click target
            if (exclude.some(selector => {
                const excludedElement = document.querySelector(selector)
                return excludedElement && excludedElement.contains(event.target)
            })) {
                return
            }

            vnode.context[binding.expression](event)
        }

        document.body.addEventListener('click', el.clickOutsideEvent)
    },

    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})