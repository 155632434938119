<template>
    <OModal
        :title="$t('project.create')"
        :confirmText="$t('crud.create')"
        :cancelText="$t('crud.cancel')"
        @close="$emit('close')"
        @confirm="$emit('confirm', project)"
        allow-overflow>

        <OInput :label="$t('project.name')" v-model="project.name" required />

        <CrudListData resource="company" :filters="{ is_archived: false }" :per-page="null" v-slot="{ items: companys }">
            <OSearch 
                :label="$t('company.company')" 
                v-model="project.company_id"
                :options="companys.map(company => ({ 
                    label: company.name, 
                    value: company.id,
                    ...company
                }))" required>
                <template #default="{ option }">
                    <div class="d-flex align-items-center">
                        <AvatarComponent 
                            :image="option.avatar" 
                            :fallback="option.name"
                            class="mr-3" />
                        <div>
                            <div>{{ option.name }}</div>
                            <div v-if="option.type" class="text-muted">{{ option.type.name }}</div>
                            <OBadge v-if="option.is_archived" type="light">{{ $t('crud.archived') }}</OBadge>
                        </div>
                    </div>
                </template>
            </OSearch>
        </CrudListData>

    </OModal>
</template>
<script>
import CrudListData from '~/components/crud/list-data.vue'
import AvatarComponent from '~/components/avatar.vue'

export default {
    components: {
        CrudListData,
        AvatarComponent
    },

    props: ['name', '$t'],

    data() {
        return {
            project: {
                name: this.name,
                company_id: null,
            }
        }
    }
}
</script>