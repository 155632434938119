import Storage from '~/util/storage'

export default async function({ route, query, from, store, redirect, $axios, $auth, app }) {
    if (query.tenant && $auth.user.current_organization && query.tenant != $auth.user.current_organization.id) {
        await $axios.$get('user/change-organization/' + query.tenant)
        await $auth.fetchUser()

        store.dispatch('organization/fetch', $auth.user.current_organization.id)
        store.dispatch('toast/push', {
            title: app.i18n.t('organization.changed'),
            message: app.i18n.t('organization.changed-text',  { name: $auth.user.current_organization.name }),
            type: 'info',
            icon: 'exchange',
            lifetime: 10000,
        })

        Storage.remove('time-items')
        Storage.remove('expense-items')
        Storage.remove('invoice-items')
        Storage.remove('offer-items')
        Storage.remove('product-items')
        Storage.remove('rate-items')
        Storage.remove('team-items')
        Storage.remove('company-items')
        Storage.remove('last-used-rate')

        if (from) {
            window.location.href = route.path + (route.hash ? route.hash : '')
        }
        
        redirect({ query: { tenant: null }, replace: true })
    } else if (query.tenant) {
        redirect({ query: { tenant: null }, replace: true })
    }
}
