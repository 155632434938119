<template>
    <div class="form-group" :class="{
        'd-flex align-items-center gap-3': inline || inlineBlock,
    }">
        <div :class="{ 'mr-auto': inlineBlock }">
            <label
                v-if="label"
                :for="`form-input-${elementId}`"
                :class="inline ? 'mb-0': 'mb-1'"
                >{{ label }} <span v-if="required" class="text-primary">*</span>
            </label>
            <div v-if="helpText"
                :id="`form-input-${label}-help`"
                class="form-text mt-n1 mb-2">
                {{ Array.isArray(helpText) ? helpText.join(', ') : helpText }}
            </div>
            <div v-if="errorMessage"
                :id="`form-input-${label}-help`"
                class="form-text mt-n1 mb-2 text-danger">
                {{ Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage }}
            </div>
        </div>
        <slot :id="elementId" />
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        helpText: {
            type: [String, Array],
            default: null,
        },
        errorMessage: {
            type: [String, Array],
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        inlineBlock: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        elementId() {
            return this.id || this.label || parseInt(Math.random() * 1000)
        }
    }
}
</script>