<template>
    <OModal
        :title="$t('document_element.add-expenses')"
        :confirm-text="$t('crud.add') + ` (${expenses.length})`"
        :allow-confirm="expenses.length > 0"
        @close="$emit('close')"
        @confirm="$emit('confirm', expenses)">

        <CrudListData resource="expense" :filters="{ project: document.project.id, is_billable: true, is_locked: false }" :per-page="null" v-slot="{ items }">
            <table class="table">
                <tbody>
                    <tr v-for="expense in items" :key="expense.id">
                        <td class="py-2">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" :value="expense.id" v-model="expenses" :id="`expense-${expense.id}`">
                                <label class="custom-control-label text-dark" :for="`expense-${expense.id}`">
                                    {{ expense.name }}
                                </label>
                            </div>
                        </td>
                        <td class="py-2 text-right tabular-nums">{{ $org.country.currency_code }} {{ parseFloat(expense.total_with_vat).toLocaleString('de-CH', { minimumFractionDigits: 2 }) }}</td>
                    </tr>
                </tbody>
            </table>
        </CrudListData>
    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import CrudListData from '~/components/crud/list-data.vue'
import PipeThrough from '~/components/util/pipe.vue'

export default {
    components: {
        AvatarComponent,
        CrudListData,
        PipeThrough
    },

    props: ['document', '$t'],

    data() {
        return {
            expenses: [],
        }
    },
}
</script>