<template>
    <OInput
        ref="input"
        :value="value"
        @input="$emit('input', $event)"
        :format="{
            swapHiddenInput: true,
            numeral: true,
            delimiter: '\'',
        }"
        v-bind="$attrs"
        input-class="rounded-right"
        inputmode="decimal"
        >
        <template #prepend>
            <div class="input-group-prepend">
                <OSelect 
                    v-if="withCurrencySwitcher" 
                    input-class="rounded-right-0" 
                    :value="currency"
                    @input="$emit('update:currency', $event)"
                    :options="currencies"
                    select-first
                    class="mb-0"
                    />
                <span 
                    v-else 
                    class="input-group-text" 
                    >{{ $attrs['prepend'] || (currency || ($org ? $org.country.currency_code : null)) }}
                </span>
            </div>
        </template>
    </OInput>
</template>
<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        currency: {
            type: String,
            default: null,
        },
        withCurrencySwitcher: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            currencies: [
                { value: 'CHF', label: 'CHF' },
                { value: 'EUR', label: 'EUR' },
                { value: 'USD', label: 'USD' },
            ],
        }
    },

    methods: {
        focus() {
            this.$refs.input.focus()
        },
    },
}
</script>