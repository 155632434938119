<template functional>
    <nav class="sidebar-group" :aria-labelledby="`nav-${props.name}`">
        <div v-if="props.name" class="sidebar-row">
            <div class="sidebar-icon"></div>
            <div :id="`nav-${props.name}`" class="sidebar-header">{{ props.name }}</div>
        </div>
        <slot></slot>
    </nav>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            default: null,
        }
    }
}
</script>
