<template>
    <OModal
        :title="`${$t('invoice.qrbill')} · ${invoice.name}`"
        @close="$emit('close')"
        @confirm="$emit('close')"
        size="qrbill">
        <OLoading :loading="loadingState.default">
            <div v-if="qrbill && Array.isArray(qrbill)">
                <OAlert type="danger" class="m-0">
                    <ul>
                        <li v-for="(error, i) in qrbill" :key="i">{{ error }}</li>
                    </ul>
                </OAlert>
            </div>
            <div v-else-if="qrbill" v-html="qrbill" style="background: white;"></div>
            <div v-else-if="error">
                <OAlert type="danger" class="m-0">
                    There was an error generating the QR bill
                </OAlert>
            </div>
        </OLoading>

    </OModal>
</template>
<script>
import HasLoadingState from '~/mixins/hasLoadingState'
export default {
    mixins: [HasLoadingState],
    props: ['invoice', '$t'],

    data() {
        return {
            qrbill: null,
            error: false,
        }
    },

    async created() {
        this.error = false
        this.$startLoading()

        this.qrbill = await this.$store.dispatch(
            'invoice/getQrBill',
            this.invoice
        ).catch(error => {
            this.error = true
            this.$stopLoading()
        })

        this.error = false
        this.$stopLoading()
    }
}
</script>