<template>
    <form @submit.prevent="save" class="d-flex flex-column flex-grow-1">
        <div class="px-3 px-lg-4">
            <slot :item="item" />
        </div>

        <hr class="my-4 mt-auto">
        <div class="portal-footer position-sticky bottom-0 p-3 p-lg-4 d-flex justify-content-end flex-md-row-reverse gap-3" style="z-index: 1;">
            <slot name="footer" :cancel="cancel" :save="save">
                <OButton type="link" @click="cancel()">{{ $t('crud.cancel') }}</OButton>
                <OButton type="primary" icon="check" submit>{{ $t('crud.save') }}</OButton>
            </slot>
        </div>
    </form>
</template>
<script>
export default {
    props: ['init', 'onSave'],

    data() {
        return {
            loading: false,
            item: {
                ...this.init
            },
        }
    },

    mounted() {
        const autofocus = this.$el.querySelector('[autofocus]')
        if (autofocus) {
            setTimeout(() => autofocus.focus(), 100)
        }
    },

    methods: {
        save() {
            this.$emit('save', this.item)
            this.loading = true
        
            if (this.onSave) {
                const result = this.onSave(this.item)

                if (result.then && typeof result.then === 'function') {
                    result
                        .then(() => {
                            this.$emit('saved')
                            // this.$store.dispatch('toast/push', {
                            //     title: this.item.name || this.$t('crud.saved'),
                            //     message: this.$t('crud.saved-text'),
                            //     type: 'success'
                            // })
                        })
                        .finally(() => this.loading = false)
                } else {
                    this.loading = false
                }
            } else {
                this.$emit('saved')
                this.loading = false
            }
        },

        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>