import queryBuilder from '~/util/crud/queryBuilder'
import crudStoreFactory from '~/util/crud/storeFactory'
import { formatDate } from "~/util/date";
import { track as analyticsTrack } from '~/plugins/analytics';
import { celebrate } from '~/util/helpers';

const crudStore = new crudStoreFactory('invoice', {
    include: ['project', 'company.media', 'contact'],
    onUpdate(invoice, updateData) {
        if (updateData.locked_at && updateData.is_scheduled_for_sending) {
            this.dispatch('toast/push', {
                title: invoice.number + ' ' + (invoice.name ? '· ' + invoice.name : ''),
                message: this.app.i18n.t('invoice.schedule-for-sending-text', { 
                    date: formatDate(invoice.issued_at), 
                    contact: invoice.contact.name 
                }),
                type: 'notice',
                icon: 'envelope-open-text'
            })
        }
    },

    onDestroy(invoice) {
        this.$axios.$get(`/time?filter[locked_by_invoice]=${invoice.id}`).then(({ data: times }) => {
            if (times.length === 0) return

            this.dispatch('toast/push', {
                title: this.app.i18n.t('invoice.unlock-times', {
                    count: invoice.locked_times_count 
                }),
                message: this.app.i18n.t('invoice.unlock-times-text', {
                    invoice: invoice.number,
                    count: invoice.locked_times_count 
                }),
                type: 'primary',
                persist: true,
                action: {
                    label: this.app.i18n.t('time.unlock'),
                    icon: 'unlock-alt',
                    type: 'primary',
                    handler: () => {
                        return this.$axios.$post('/time/unlock-from-invoice/' + invoice.id).then(() => {
                            this.dispatch('toast/push', {
                                title: this.app.i18n.t('invoice.unlocked-times', { count: invoice.locked_times_count }),
                                type: 'success'
                            })
                        })
                    }
                }
            }, { root: true })
        })
    }
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    async sendToClient({ dispatch, commit }, { invoice, recipients, increaseDunningLevel, message, ccToOwner, adjustDates }) {
        const sendPromise = this.$axios.$post(`invoice/${invoice.id}/send`, { recipients, increaseDunningLevel, message, ccToOwner, adjustDates }).then(item => {
            commit('UPDATE_OR_ADD_ITEM', item)
        })

        dispatch('toast/push', {
            title: this.app.i18n.t('invoice.sending-to-client') + '...',
            message: this.app.i18n.t('invoice.sending-to-client-text'),
            type: 'primary',
            icon: 'spinner',
            until: sendPromise,
            untilSuccess: () => {
                return {
                    title: this.app.i18n.t('invoice.sent-to-client'),
                    message: this.app.i18n.t('invoice.sent-to-client-text'),
                    type: 'success',
                }
            }
        }, { root: true })

        sendPromise.then(() => {
            analyticsTrack('Invoice sent')
            setTimeout(celebrate, 1000)
        })

        return sendPromise
    },

    async getQrBill(context, invoice) {
        return this.$axios.$get(`invoice/${invoice.id}/qrbill`)
    },

    export({ dispatch }, { fileType, filters, sort }) {
        if (sort) {
            sort = sort.key ? (!sort.ascending ? '-' : '') + sort.key : null
        }

        dispatch('toast/push', {
            title: this.app.i18n.t('crud.export'),
            message: this.app.i18n.t('invoice.exporting'),
            type: 'primary',
            icon: 'file-export',
        }, { root: true })

        const query = { 
            filters, 
            sort,
            perPage: null,
        }

        window.open(`${this.$axios.defaults.baseURL}invoice/export/${fileType}${queryBuilder(query)}`, '_blank')

        return
    }
}