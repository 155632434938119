<template>
    <OModal
        :title="$t('tag.attach-to-items', { count: items.length })"
        @close="$emit('close')"
        @confirm="$emit('confirm', { tags, keepExisting })"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('crud.save')"
        type="primary"
        allow-overflow
        >

        <TagSelect 
            ref="tagSelect"
            :label="$t('tag.tags')" 
            :create-tag-text="$t('crud.create')"
            :edit-tag-text="$t('crud.edit')"
            :no-tags-text="$t('tag.no-tags-selectable')"
            v-model="tags" 
            :context="app" 
            :scope="scope"
            allow-create
            autofocus
            />

        <OToggle 
            :label="$t('tag.keep-existing')"
            :states="[$t('status.yes'), $t('status.no')]"
            :help-text="$t('tag.keep-existing-text')"
            v-model="keepExisting"
            />
    </OModal>
</template>
<script>
import TagSelect from '~/components/tag/select.vue'
export default {
    props: ['$t', 'items', 'app', 'scope'],

    components: {
        TagSelect
    },

    data() {
        return {
            tags: [],
            keepExisting: false,
        }
    },

    created() {
        const tagArrays = this.items.map(item => item.tags.map(tag => tag.id))

        this.tags = tagArrays.shift().filter(v => {
            return tagArrays.every(a => {
                return a.indexOf(v) !== -1
            })
        })
    },
}
</script>