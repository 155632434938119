<template>
    <OModal
        :title="`${expenses.length} ${$t('expense.expenses')} – ${$t('expense.add-to-invoice')}`"
        @close="$emit('close')"
        @confirm="$emit('confirm', { invoiceId })"
        :allow-confirm="invoiceId != null"
        allow-overflow>
        <CrudListData 
            resource="invoice" 
            :filters="{ 
                status: 'draft', 
                company: expenses.length === 1 && expenses[0].project 
                    ? expenses[0].project.company.id 
                    : null 
            }" 
            :per-page="false" 
            v-slot="{ items: invoices }">

            <table class="table mb-3">
                <tbody>
                    <tr v-for="expense in expenses" :key="expense.id">
                        <td class="py-2">
                            {{ expense.name }}
                        </td>
                        <td class="py-2 text-right tabular-nums">{{ $org.country.currency_code }} {{ parseFloat(expense.total).toLocaleString('de-CH', { minimumFractionDigits: 2 }) }}</td>
                    </tr>
                </tbody>
            </table>
            
            <OSearch
                :label="$t('invoice.invoice')"
                v-model="invoiceId"
                :options="invoices.map(i => ({ label: `${i.number} • ${i.name}`, value: i.id, ...i }))"
                v-slot="{ option }"
                :select-first="expenses.length === 1">
                    <div class="d-flex">
                        <div>
                            <div><strong>{{ option.number }} • {{ option.name }}</strong></div>
                            <div>
                                <span v-if="option.company">{{ option.company.name }}</span>
                                <span v-if="option.project"> / {{ option.project.name }}</span>
                            </div>
                        </div>
                        <div class="ml-auto pl-3 tabular-nums">{{ $org.country.currency_code }} {{ $formatCurrency(option.total) }}</div>
                    </div>
            </OSearch>
        </CrudListData>
    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import CrudListData from '~/components/crud/list-data.vue'

export default {
    components: {
        AvatarComponent,
        CrudListData,
    },

    props: ['expenses', '$t'],

    data() {
        return {
            invoiceId: null,
        }
    }
}
</script>