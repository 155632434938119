<template>
    <OFormModal
        :title="template.name"
        :subtitle="template.description || $t('accounting.entry-apply-template')"
        allow-overflow
        @confirm="$emit('confirm', { date, message, amount })"
        >
        <div class="row">
            <div class="col-12">
                <OCurrencyInput 
                    :label="$t('accounting.entry-amount')"
                    v-model="amount"
                    required
                    min="0"
                    autofocus
                    />
            </div>
            <div class="col-12 col-lg-8">
                <OInput 
                    :label="$t('crud.description')"
                    v-model="message"
                    :placeholder="template.name"
                    :autofocus="template.amount > 0"
                    />
            </div>
            <div class="col-12 col-lg-4">
                <ODatePicker 
                    :label="$t('crud.date')"
                    v-model="date"
                    required
                    />
            </div>
            <!-- <div class="col-12">
                <VatSelect 
                    :label="$t('vat.vat')"
                    v-model="vat_rate_id"
                    />
            </div> -->
        </div>
    </OFormModal>
</template>
<script>
import VatSelect from '@/components/vat/select.vue'
export default {
    props: ['template', '$t'],

    components: {
        VatSelect,
    },

    data() {
        return {
            date: new Date().toISOString().substring(0, 10),
            message: this.template.message || '',
            amount: this.template.amount || 0,
        }
    }
}
</script>