import { render, staticRenderFns } from "./logo.vue?vue&type=template&id=3c311c73&functional=true"
import script from "./logo.vue?vue&type=script&lang=js"
export * from "./logo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports