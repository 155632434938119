import Vue from 'vue'

const THRESHOLD = 25
const DELAY = 300

Vue.directive('long-press', {
    bind(el, binding) {
        let pressTimer = null
        let startTime = 0
        let startScreenYPos = 0
        let endScreenYPos = 0

        const start = (e) => {
            if ((e.type === 'click' && e.button !== 0) || !window.matchMedia(`screen and (min-width: 768px)`).matches) {
                return
            }

            if (pressTimer === null) {
                startScreenYPos = e.touches[0].screenY
                endScreenYPos = e.touches[0].screenY
                startTime = Date.now()

                pressTimer = setTimeout(() => {
                    let diff = startScreenYPos - endScreenYPos
                    diff = Math.sqrt(diff * diff)

                    if (diff < THRESHOLD) {
                        binding.value()
                    }
                }, DELAY)
            }
        }

        let cancel = (e) => {
            if (pressTimer !== null) {
                const touchDuration = Date.now() - startTime

                if (touchDuration >= DELAY) {
                    e.preventDefault()
                }

                startTime = 0
                startScreenYPos = 0
                startScreenYPos = 0
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }

        let move = (e) => {
            endScreenYPos = e.touches[0].screenY
        }

        el.addEventListener('touchstart', start)
        el.addEventListener('touchend', cancel)
        el.addEventListener('touchcancel', cancel)
        el.addEventListener('touchmove', move)
    },

    unbind(el) {
        const start = (e) => { }
        const cancel = (e) => { }
        const move = (e) => { }

        el.removeEventListener('touchstart', start)
        el.removeEventListener('touchend', cancel)
        el.removeEventListener('touchcancel', cancel)
        el.removeEventListener('touchmove', move)
    }
})