<template>
    <OModal
        :title="title"
        :type="type"
        :confirmText="confirmText"
        :cancelText="cancelText"
        @close="$emit('close')"
        @confirm="$emit('confirm', { reason })">
        <div v-html="message" class="mb-3"></div>

        <OTextarea
            :label="$t('absence.rejection-reason')"
            v-model="reason" 
            autofocus
            />
    </OModal>
</template>
<script>
export default {
    props: ['title', 'message', 'type', 'confirmText', 'cancelText', '$t'],

    data() {
        return {
            reason: ''
        }
    },
}
</script>