<template>
    <OModal
        :title="$t('offer.create')"
        :confirmText="$t('crud.create')"
        :cancelText="$t('crud.cancel')"
        @close="$emit('close')"
        @confirm="$emit('confirm', { offer, set_project_billing_flat })"
        allow-overflow
        >

        <OInput :label="$t('offer.name')" v-model="offer.name" autofill required autofocus />

        <CrudListData resource="contact" :include="['company']" :filters="{ company: project.company.id }" v-slot="{ items: contacts }">
            <OSearch 
                :label="$t('contact.contact')" 
                v-model="offer.contact_id"
                :options="contacts.map(i => ({ label: `${i.firstname} ${i.lastname} • ${i.email || $t('contact.email-missing')}`, value: i.id, ...i }))"
                :disabled="contacts.length == 0"
                :help-text="contacts.length == 0 ? $t('company.no-contacts') : ''"
                select-first
                required>
                <template #default="{ option }">
                    <div><strong>{{ option.firstname }} {{ option.lastname }}</strong> <span class="text-muted">{{ (option.company || {}).name }}</span></div>
                    <div>{{ option.fullAddress }}</div>
                </template>
            </OSearch>
        </CrudListData>

        <OToggle
            v-if="project.billing_type === 'hourly'"
            :label="$t('project.change-to-billing-flat')"
            :help-text="$t('project.change-to-billing-flat-text')"
            :states="[$t('status.yes'), $t('status.no')]"
            v-model="set_project_billing_flat"
            />

    </OModal>
</template>
<script>
import CrudListData from '~/components/crud/list-data.vue'
import AvatarComponent from '~/components/avatar.vue'

export default {
    components: {
        CrudListData,
        AvatarComponent
    },

    props: ['project', '$t'],

    data() {
        return {
            offer: {
                name: this.project.name,
                project_id: this.project.id,
                contact_id: null,
            },
            set_project_billing_flat: true,
        }
    }
}
</script>