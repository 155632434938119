<template>
    <div class="app">
        <div v-if="$nuxt.isOffline" class="nuxt-offline d-flex align-items-center">
            <OIcon :icon="['far', 'exclamation-triangle']" class="mr-3 mr-md-2" />
            {{ $t('app.is-offline') }}
        </div>
        
        <AppSidebar />

        <main class="main-wrapper" :class="{ 'pushed': $store.state.sidebarOpen }">
            <AppHeader />

            <div class="bokeh-bubbles">
                <div class="bubble"></div>
                <div class="bubble"></div>
                <div class="bubble"></div>
            </div>

            <div class="position-relative px-2 pb-5 px-md-3">
                <Nuxt 
                    keep-alive 
                    :keep-alive-props="{ 
                        max: 3, 
                        exclude: ['Organization'] 
                    }" 
                    />
            </div>
        </main>

        <OActionBar v-if="$onPlan('pro|business')">
            <OActionBarItem v-if="$can('show time')" link="/time" icon="user-clock">{{ $t('time.times') }}</OActionBarItem>
            <OActionBarItem v-if="$onPlan('business') && $can('show absence') && $org.role_in_organization === 'member'" link="/absence" icon="island-tropical">{{ $t('absence.absences') }}</OActionBarItem>
            <OActionBarItem v-if="$can('show invoice')" link="/invoice" icon="file-invoice-dollar">{{ $t('invoice.invoices') }}</OActionBarItem>
            <OActionBarItem v-if="$can('show expense')" link="/expense" icon="receipt">{{ $t('expense.expenses') }}</OActionBarItem>
            <OActionBarItem v-if="$can('show project')" link="/project" icon="cube">{{ $t('project.projects') }}</OActionBarItem>
            <OActionBarItem :icon="$store.state.sidebarOpen ? 'times' : 'bars'" @click.stop="$store.commit('toggleSidebar')">{{ $t('crud.more') }}</OActionBarItem>
        </OActionBar>

        <OActionBar v-else>
            <OActionBarItem v-if="$can('show company')" link="/company" icon="address-card">{{ $t('company.companies') }}</OActionBarItem>
            <OActionBarItem v-if="$can('show offer')" link="/offer" icon="quote-left">{{ $t('offer.offers') }}</OActionBarItem>
            <OActionBarItem v-if="$can('show invoice')" link="/invoice" icon="file-invoice-dollar">{{ $t('invoice.invoices') }}</OActionBarItem>
            <OActionBarItem :icon="$store.state.sidebarOpen ? 'times' : 'bars'" @click.stop="$store.commit('toggleSidebar')">{{ $t('crud.more') }}</OActionBarItem>
        </OActionBar>

        <Transition name="overlay">
            <CommandPalette v-if="$org && $store.state.command.isOverlayOpen" />
        </Transition>
        
        <Transition name="overlay">
            <GlobalSearch v-if="$org && $store.state.search.isOverlayOpen" />
        </Transition>

        <Notifications 
            :open="$store.state.notification.isSlideoutOpen"
            @close="$store.dispatch('notification/toggleSlideout')" />

        <ToastContainer />

        <div id="tooltip-container"></div>
    </div>
</template>
<script>
import * as Sentry from '@sentry/browser'
import AppSidebar from '~/components/sidebar.vue'
import AppHeader from '~/components/header.vue'
import ToastContainer from '~/components/toast-container.vue'
import Notifications from '~/components/notification/notifications.vue'
import CommandPalette from '~/components/command-palette.vue'
import GlobalSearch from '~/components/global-search.vue'
import { differenceInDays } from 'date-fns'

export default {
    middleware: [
        'outdatedBrowser',
        'auth',
        'closeSidebarOnSmallDevices',
        'tenantRedirect',
        'analyticsPageView',
    ],

    components: {
        AppSidebar,
        AppHeader,
        ToastContainer,
        Notifications,
        CommandPalette,
        GlobalSearch,
    },
    
	mounted() {
        if (this.$config.env != 'production') {
            console.table(this.$config)
        }

        document.addEventListener('click', this.closeSidebarOnSmallDevices)
        
        this.requestNotificationPermission()
        this.listenForNewNotifications()
        this.listenForOrganizationChange()
        this.listenForWebShareTarget()
        this.checkForServiceWorkerUpdate()
        
        if (this.$auth.user) {
            this.setupSupportChat()
            this.setupErrorTracking()
            this.setupProductAnalytics()
        }

        // Check for new product updates if the organization is older than 1 day
        if (
            this.$org 
            && this.$org.role_in_organization != 'member'
            && differenceInDays(new Date, new Date(this.$org.created_at)) > 1
        ) {
            this.checkForProductUpdate()
        }
    },

    destroyed() {
        document.removeEventListener('click', this.closeSidebarOnSmallDevices, { passive: true })
    },
    
    methods: {
        closeSidebarOnSmallDevices() {
            this.$store.dispatch('closeOnSmallDevices')
        },

        setupSupportChat() {
            window._chatlio = window._chatlio || [];
            let t = document.getElementById("chatlio-widget-embed")
            if (t && window.ChatlioReact && _chatlio.init) {
                return void _chatlio.init(t, ChatlioReact)
            }
                
            for (var e = function (t) { return function () { _chatlio.push([t].concat(arguments)) } }, i = ["configure", "identify", "track", "show", "hide", "isShown", "isOnline", "page", "open", "showOrHide"], a = 0; a < i.length; a++)_chatlio[i[a]] || (_chatlio[i[a]] = e(i[a])); var n = document.createElement("script"), c = document.getElementsByTagName("script")[0]; n.id = "chatlio-widget-embed", n.src = "https://w.chatlio.com/w.chatlio-widget.js", n.async = !0, n.setAttribute("data-embed-version", "2.3");
                n.setAttribute('data-widget-id', '1c590307-c475-4c1e-652a-05db5ca58def');
                c.parentNode.insertBefore(n, c);

            this.$nextTick(() => {
                window._chatlio.identify(this.$auth.user.id, {
                    name: this.$auth.user.fullName,
                    email: this.$auth.user.email,
                    org: this.$org ? this.$org.name : null
                })

                if(!this.$breakpoint('lg')) {
                    window._chatlio.hide()
                }
            })
        },

        setupErrorTracking() {
            // Sentry identification
            Sentry.setUser({
                id: this.$auth.user.id,
                username: this.$auth.user.fullName,
                email: this.$auth.user.email,
                organization: this.$auth.user.current_organization ? this.$auth.user.current_organization.name : null,
                current_role: this.$auth.user.current_organization ? this.$auth.user.current_organization.role_in_organization : null
            })
        },

        setupProductAnalytics() {
            // June identification
            this.$analyticsIdentify(this.$auth.user, {
                theme: this.$getActualTheme(),
                two_factor_enabled: this.$auth.user.two_factor_enabled,
            })
        },

        async listenForWebShareTarget() {
            const workbox = await window.$workbox;
            if (workbox) {
                workbox.addEventListener('message', async (event) => {
                    if (event.data.message === 'shared-files') {
                        const expense = await this.$modal(
                            { sharedFile: event.data.files[0] }, 
                            'expense/create-from-receipt'
                        )
                        
                        if (expense) {
                            this.$router.push(`/expense/${expense.id}/edit`)
                        }
                    }
                })
            }
        },

        async checkForServiceWorkerUpdate() {
            const workbox = await window.$workbox;
            if (workbox) {
                workbox.addEventListener('installed', (event) => {
                    if (event.isUpdate) {
                        this.$toast(this.$t('app.update-available'), 'muted', { 
                            icon: 'sync-alt',
                            message: this.$t('app.refresh-to-update'),
                            lifetime: 15000,
                            action: {
                                label: this.$t('app.update-now'),
                                type: 'lightish',
                                handler() {
                                    window.location.reload()
                                }
                            }
                        })
                    }
                });
            }
        },

        async checkForProductUpdate() {
            const newProductUpdate = await this.$store.dispatch('checkForProductUpdates')

            if (newProductUpdate) {
                this.$analyticsTrack('Product update shown', { id: newProductUpdate.id })
                this.$modal({ update: newProductUpdate }, 'product-update')
                    .then(() => this.$router.push('/changelog'))
            }
        },

        requestNotificationPermission() {
            if ("Notification" in window) {
                setTimeout(() => {
                    Notification.requestPermission(result => {
                        this.$store.commit('notification/setNotificationsPermission', result)
                    })
                }, 5000)
            }
        },

        listenForNewNotifications() {
            this.$store.dispatch('notification/fetch')
        
            this.$userChannel(this.$auth).listen('.user.new-notification', (e) => {
                this.$store.dispatch('notification/fetch')
                this.$toast(e.data.title, 'primary', {
                    message: e.data.message,
                    icon: e.data.icon,
                    action: e.data.url ? {
                        label: this.$t('crud.show'),
                        type: 'primary',
                        handler: () => {
                            this.$router.push(e.data.url)
                        }
                    }: null
                })

                if (document.visibilityState === 'hidden' && Notification?.permission === 'granted') {
                    const notification = new Notification(e.data.title, {
                        body: e.data.message.replace(/(<([^>]+)>)/gi, ""),
                        tag: e.data.type,
                        icon: '/android-chrome-512x512.png?v=gAX9AKg4Kw3',
                    })

                    if (e.data.url) {
                        notification.onclick = () => {
                            this.$router.push(e.data.url)
                            notification.close()
                        }
                    }
                }
            })
        },

        listenForOrganizationChange() {
            this.$userChannel(this.$auth)
                .listen('.user.changed-organization', async (e) => {
                    const backendOrganization = e.organization
                    const frontendOrganization = this.$auth.user.current_organization

                    if (backendOrganization.id !== frontendOrganization.id) {
                        const organization = await this.$modal({
                            newOrganization: backendOrganization,
                            oldOrganization: frontendOrganization,
                        }, 'user/organization-changed')

                        await this.$store.dispatch('user/changeOrganization', organization)
                        await this.$auth.fetchUser()
                        
                        window.location.reload()
                    }
                })
        },
    },

    watch: {
        '$nuxt.isOffline'(isOffline) {
            if (isOffline) {
                this.$toast(this.$t('app.went-offline'), 'warning', { icon: 'wifi-slash' })
            } else {
                this.$toast(this.$t('app.went-online'), 'success', { icon: 'wifi' })
            }
        },
    }
}
</script>