var render = function render(_c,_vm){return _c('div',{staticClass:"d-flex",class:[
        {
            'flex-column': _vm.props.vertical,
            'flex-wrap': _vm.props.wrap,
        },
        `justify-content-${_vm.props.justify}`,
        `align-items-${_vm.props.align}`,
        _vm.props.gap ? `gap-${_vm.props.gap}` : null,
        _vm.data.class,
        _vm.data.staticClass
    ],style:([_vm.data.style, _vm.data.staticStyle])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }