<template functional>
	<span 
        class="badge badge-pill"
        :class="[
            data.class, 
            data.staticClass, 
            `badge-${props.type}`
        ]"
		:style="[data.style, data.staticStyle]"
		v-on="listeners">
        <OIcon v-if="props.icon" :icon="[props.iconPrefix, props.icon]" fixed-width></OIcon>
		<slot>{{ props.label }}</slot>
        <OIcon v-if="props.iconAfter" :icon="[props.iconPrefix, props.iconAfter]" fixed-width></OIcon>
	</span>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: "link"
		},
		icon: {
			type: String,
			default: null
		},
		label: {
			type: String,
			default: null
		},
		iconAfter: {
			type: String,
			default: null
		},
		iconPrefix: {
			type: String,
			default: "far"
		}
	}
};
</script>