<template>
    <OFormModal
        :title="$t('payments.create')"
        :subtitle="expense.name"
        :form="form"
        @close="$emit('close')"
        @confirm="$emit('confirm', form)"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('crud.save')"
        :before-confirm="beforeConfirm"
        :close-on-click-outside="false"
        size="xl"
        >
        <div class="row">
            <div class="col-12 col-lg-6">
                <OAlert 
                    v-if="form.amount > expense.total_open"
                    :title="$t('payments.amount-too-high')" 
                    type="warning"
                    >
                    <div>{{ $t('payments.amount-too-high-text', { diff: $org.country.currency_code + ' ' + $formatCurrency(form.amount - expense.total_open) }) }}</div>
                </OAlert>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <ODatePicker
                            :label="$t('payments.execute_at')"
                            v-model="form.execute_at"
                            required
                            />
                    </div>
                    <div class="col-12 col-md-6">
                        <OCurrencyInput
                            ref="amountInput"
                            :label="$t('payments.amount')"
                            v-model="form.amount"
                            autofocus
                            required
                            />
                    </div>
                    <div v-if="$org.bank_accounts_count > 0" class="col-12">
                        <BankAccountSelect
                            :label="$t('payments.bank-account')"
                            v-model="form.bank_account_id"
                            select-first
                            />
                    </div>
                    <div class="col-12">
                        <OInput
                            v-if="form.message !== null"
                            ref="messageInput"
                            v-model="form.message"
                            :label="$t('payments.message')"
                            maxlength="140"
                            />
                        <OButton 
                            v-else 
                            icon="plus" 
                            size="sm" 
                            @click="() => {
                                form.message = ''
                                $nextTick(() => this.$refs.messageInput.focus())
                            }"
                            class="pl-0 mt-n2 mb-3"
                            >{{ $t('payments.add-message') }}
                        </OButton>
                    </div>
                </div>

                <ORadio
                    :label="$t('expense.bank-payment-type')"
                    v-model="form.bank_payment_type"
                    :options="[
                        { value: 'credit_transfer_qrr', label: $t('expense.bank-payment-types.credit_transfer_qrr') },
                        { value: 'credit_transfer_scor', label: $t('expense.bank-payment-types.credit_transfer_scor') },
                        { value: 'credit_transfer', label: $t('expense.bank-payment-types.credit_transfer') },
                        { value: null, label: $t('expense.bank-payment-types.null') },
                    ]"
                    block
                    />

                <hr class="mx-n3">

                <div v-if="form.bank_payment_type" class="row">
                    <div class="col-12">
                        <OInput 
                            v-model="form.creditor_iban"
                            :label="form.bank_payment_type === 'credit_transfer_qrr' ? 'QR-IBAN' : 'IBAN'"
                            :format="{
                                swapHiddenInput: true,
                                delimiter: ' ',
                                blocks: [4, 4, 4, 4, 4, 4, 4, 4, 2],
                                uppercase: true,
                                delimiterLazyShow: true,
                            }"
                            :required="!!form.bank_payment_type"
                            />
                    </div>
                    <div v-if="isForeignIban(form.creditor_iban)" class="col-12">
                        <OInput
                            v-model="form.creditor_bic"
                            label="BIC/SWIFT"
                            minlength="8"
                            maxlength="11"
                            required
                            />
                    </div>
                    <div v-if="['credit_transfer_qrr', 'credit_transfer_scor'].includes(form.bank_payment_type)" class="col-12">
                        <OInput 
                            v-model="form.creditor_reference"
                            :label="$t('payments.creditor-reference')"
                            maxlength="70"
                            :format="{
                                swapHiddenInput: true,
                                delimiter: ' ',
                                blocks: [2, 5, 5, 5, 5, 5],
                                delimiterLazyShow: true,
                            }"
                            required
                            />
                    </div>
                    <div class="col-12">
                        <OInput 
                            v-model="form.creditor_name"
                            :label="$t('expense.biller-address')"
                            :placeholder="$t('address.name')"
                            maxlength="70"
                            class="mb-2"
                            required
                            />
                    </div>
                    <div class="col-8">
                        <OInput 
                            v-model="form.creditor_street"
                            :placeholder="$t('address.street') + ` (${$t('crud.optional')})`"
                            maxlength="70"
                            class="mb-2"
                            />
                    </div>
                    <div class="col-4">
                        <OInput 
                            v-model="form.creditor_building_no"
                            :placeholder="$t('address.building-no') + ` (${$t('crud.optional')})`"
                            maxlength="70"
                            class="mb-2"
                            />
                    </div>
                    <div class="col-4">
                        <OInput 
                            v-model="form.creditor_zip"
                            :placeholder="$t('address.zip')"
                            maxlength="70"
                            class="mb-2"
                            required
                            />
                    </div>
                    <div class="col-8">
                        <OInput 
                            v-model="form.creditor_city"
                            :placeholder="$t('address.city')"
                            maxlength="70"
                            class="mb-2"
                            required
                            />
                    </div>
                    <div class="col-12">
                        <CountrySelect 
                            :label="' '"
                            :placeholder="$t('address.country')" 
                            v-model="form.creditor_country"
                            />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 pb-3">
                <iframe 
                    v-if="expense.receipt && expense.receipt.url" 
                    :key="expense.receipt.url"
                    :src="expense.receipt.url + '#toolbar=0&navpanes=0&scrollbar=0'" 
                    frameborder="0"
                    class="border w-100 aspect-a4 rounded"
                ></iframe>
            </div>
        </div>
    </OFormModal>
</template>
<script>
import BankAccountSelect from '~/components/bank-account/select.vue'
import CountrySelect from '~/components/country/select.vue'
import { isSwissIban, isSwissQrIban, isForeignIban } from '~/util/helpers'

export default {
    components: {
        CountrySelect,
        BankAccountSelect,
    },

    props: ['expense', '$t', 'beforeConfirm'],

    data() {
        return {
            form: {
                execute_at: this.expense.due_at || this.$dateForDB('now'),
                amount: 0,
                message: null,
                bank_account_id: null,
                bank_payment_type: 'credit_transfer_qrr',
                creditor_name: null,
                creditor_street: null,
                creditor_building_no: null,
                creditor_zip: null,
                creditor_city: null,
                creditor_country: 'CH',
                creditor_iban: null,
                creditor_bic: null,
                creditor_reference: null,
            },
        }
    },

    created() {
        const lastPayment = this.expense.payments[this.expense.payments.length - 1]

        if (lastPayment) {
            this.form.bank_account_id = lastPayment.bank_account_id
            this.form.bank_payment_type = lastPayment.bank_payment_type
            this.form.creditor_name = lastPayment.creditor_name
            this.form.creditor_street = lastPayment.creditor_street
            this.form.creditor_building_no = lastPayment.creditor_building_no
            this.form.creditor_zip = lastPayment.creditor_zip
            this.form.creditor_city = lastPayment.creditor_city
            this.form.creditor_country = lastPayment.creditor_country
            this.form.creditor_iban = lastPayment.creditor_iban
            this.form.creditor_bic = lastPayment.creditor_bic
            this.form.creditor_reference = lastPayment.creditor_reference
            this.form.amount = this.expense.payments 
                ? Math.max(this.expense.total_with_vat - this.expense.payments.reduce((acc, payment) => acc + parseFloat(payment.amount), 0), 0)
                : this.expense.total_open
        } else {
            this.form.amount = this.expense.total_open
        }
    },

    watch: {
        'form.creditor_iban'(iban) {
            if (!iban || iban.replaceAll(' ', '').trim().length < 21) return

            if (isSwissQrIban(iban)) {
                this.form.bank_payment_type = 'credit_transfer_qrr'
                this.$toast(this.$t('expense.bank-payment-type-changed', { type: this.$t('expense.bank-payment-types.' + this.form.bank_payment_type) }), 'info', {
                    icon: 'qrcode',
                })
            } else if (isSwissIban(iban)) {
                this.form.bank_payment_type = 'credit_transfer'
                this.$toast(this.$t('expense.bank-payment-type-changed', { type: this.$t('expense.bank-payment-types.' + this.form.bank_payment_type) }), 'info', {
                    icon: 'sync-alt',
                })
            } else if (isForeignIban(iban)) {
                this.form.bank_payment_type = 'credit_transfer'
                this.$toast(this.$t('expense.bank-payment-type-changed', { type: this.$t('expense.bank-payment-types.' + this.form.bank_payment_type) }), 'info', {
                    icon: 'sync-alt',
                })
            }
        },
    },

    methods: {
        isSwissIban,
        isSwissQrIban,
        isForeignIban,
    }
}
</script>