import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('bank_transaction')

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    reconcile({ commit, state }, { transaction, text }) {
        return this.$axios.$post(`/bank_transaction/${transaction.id}/reconcile${state.query}`, {
            text,
        }).then(data => {
            commit('UPDATE_OR_ADD_ITEM', data)

            crudStore.options.onUpdate && crudStore.options.onUpdate.call(this, data, transaction)

            return data
        })
    },
}