<template>
    <OModal
        type="info"
        :title="$t('contact.create')"
        @close="$emit('close')"
        hide-footer
        size="lg"
        >

        <OForm @submit="$emit('confirm', contact)">
            <div class="row">
                <div class="col-12 col-md-6"><OInput :label="$t('address.firstname')" v-model="contact.firstname" autofocus /></div>
                <div class="col-12 col-md-6"><OInput :label="$t('address.lastname')" v-model="contact.lastname" /></div>

                <div class="col-12">
                    <div class="bg-ultralight mx-n3 px-3 py-3 mb-3">
                        <OTabs :tabs="[{
                            name: 'new-company',
                            label: $t('company.create'),
                            icon: 'plus',
                        }, {
                            name: 'existing-company',
                            label: $t('contact.attach-to-existing'),
                            icon: 'link',
                        }]"
                        @changed="resetCompany()">
                            <template #new-company>
                                <OInput
                                    :label="$t('company.name-text')"
                                    v-model="contact.company_name"
                                    :placeholder="`${contact.firstname} ${contact.lastname}`"
                                    class="mb-0"
                                    :required="contact.firstname.length + contact.lastname.length === 0"
                                    />
                            </template>
                            <template #existing-company>
                                <CompanySelect
                                    :label="$t('contact.attach-to-existing')"
                                    v-model="contact.company_id"
                                    select-first
                                    required
                                    class="mb-n3"
                                    />
                            </template>
                        </OTabs>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12"><OInput :label="$t('address.email')" :help-text="$t('address.email-text')" v-model="contact.email" :prepend="['far', 'at']" inputmode="email" /></div>
                <div class="col-12 col-md-6"><OInput :label="$t('address.phone_landline')" v-model="contact.phone_landline" type="tel" inputmode="tel" :prepend="['far', 'phone']"  /></div>
                <div class="col-12 col-md-6"><OInput :label="$t('address.phone_mobile')" v-model="contact.phone_mobile" type="tel" inputmode="tel" :prepend="['far', 'mobile']"  /></div>
            </div>
            <hr class="mx-n3">
            <div class="row">
                <div class="col-12"><OInput :label="$t('address.address')" v-model="contact.address" /></div>
                <div class="col-12"><OInput :label="$t('address.address-line-2')" v-model="contact.address_line_2" /></div>
                <div class="col-4"><OInput :label="$t('address.zip')" v-model="contact.zip" /></div>
                <div class="col-8"><OInput :label="$t('address.city')" v-model="contact.city" /></div>
                <div class="col-12"><CountrySelect :label="$t('address.country')" v-model="contact.country" /></div>
            </div>

            <CustomFieldEditor v-if="$hasAddon('custom_fields')" :item="contact" scope="contact" :context="app" :t="$t" />

            <OButton type="primary" icon="plus" submit class="mt-3">{{ $t('crud.create') }}</OButton>
        </OForm>

    </OModal>
</template>
<script>
import CrudCreate from '~/components/crud/create.vue'
import CrudListData from '~/components/crud/list-data.vue'
import AvatarComponent from '~/components/avatar.vue'
import CountrySelect from '~/components/country/select.vue'
import CompanySelect from '~/components/company/select.vue'
import CustomFieldEditor from '~/components/custom-field/editor.vue'

export default {
    components: {
        CrudCreate,
        CrudListData,
        AvatarComponent,
        CountrySelect,
        CompanySelect,
        CustomFieldEditor,
    },

    props: ['app', '$t'],

    data() {
        return {
            contact: {
                salutation: '',
                firstname: '',
                lastname: '',
                position: '',
                email: '',
                phone_landline: '',
                phone_mobile: '',
                address: '',
                address_line_2: '',
                city: '',
                zip: '',
                country: 'CH',
                company_name: '',
                company_id: null,
                preferred_language: null,
                custom_fields: {},
            }
        }
    },

    methods: {
        resetCompany() {
            this.contact.company_name = ''
            this.contact.company_id = null
        },
    }
}
</script>