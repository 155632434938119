import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('company', {
    include: ['media'],
    saveToLocalStorage: false,
})

export const state = () => {
    return crudStore.state()
}   

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = crudStore.actions()