<template functional>
    <div 
        class="hero hero-heading" 
        :class="[data.class, data.staticClass]">
        <div class="position-relative">
            <slot name="before" />
            <div class="prehead" v-if="props.prehead">{{ props.prehead }}</div>
            <h1 v-if="props.title" class="m-0">
                <span>{{ props.title }}</span> 
                <small v-if="props.postfix">{{ props.postfix }}</small>
                <span v-if="props.badge" class="badge badge-pill badge-reset" :class="`badge-${props.badgeType}`">{{ props.badge }}</span>
            </h1>
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        postfix: {
            type: [String, Number],
            default: null
        },
        prehead: {
            type: String,
            default: null
        },
        badge: {
            type: String,
            default: null
        },
        badgeType: {
            type: String,
            default: 'light'
        }
    }
}
</script>