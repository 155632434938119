<template functional>
    <li class="nav-item" :class="[data.staticClass, data.class]">
        <span v-if="props.disabled" class="nav-link text-muted pointer-none">
            <OIcon v-if="props.icon" :icon="[props.iconPrefix, props.icon]" fixed-width class="text-muted" :class="{ 'mr-1': slots().default }"></OIcon>
            <slot />
        </span>
        <NuxtLink v-else-if="props.link" class="nav-link" active-class="active" :exact="props.exact" :to="props.link" replace>
            <OIcon v-if="props.icon" :icon="[props.iconPrefix, props.icon]" fixed-width class="text-muted" :class="{ 'mr-1': slots().default }"></OIcon>
            <slot />
        </NuxtLink>
        <a v-else href="javascript:;" class="nav-link" :class="{ 'active': props.active }" @click="listeners['click'] ? listeners['click']($event) : null">
            <OIcon v-if="props.icon" :icon="[props.iconPrefix, props.icon]" fixed-width class="text-muted" :class="{ 'mr-1': slots().default }"></OIcon>
            <slot />
        </a>
    </li>
</template>
<script>
export default {
    props: {
        link: {
            type: [String, Object],
            default: null
        },
        icon: {
            type: String,
            default: null,
        },
        iconPrefix: {
            type: String,
            default: 'far',
        },
        active: {
            type: Boolean,
            default: false,
        },
        exact: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    }
}
</script>