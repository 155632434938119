<template>
    <CrudListData resource="member" :per-page="null" sort-by="name" v-slot="{ items: members }">
        <OSearch 
            :label="label || $t('member.member')" 
            :help-text="helpText"
            :value="value || init"
            @input="$emit('input', $event)"
            :options="members.map(member => ({ 
                label: member.user.fullName, 
                value: member.id,
                ...member
            }))"
            :required="required"
            :null-option="allowNull ? $t('crud.none') : null"
            :class="inputClass"
            v-bind="$attrs"
            >
            <template #default="{ option }">
                <div v-if="option.user" class="d-flex align-items-center">
                    <AvatarComponent 
                        :image="option.user.avatar" 
                        :fallback="option.user.fullName"
                        size="sm"
                        class="mr-2" />
                    <div>
                        <div>{{ option.user.fullName }}</div>
                    </div>
                </div>
            </template>
        </OSearch>
    </CrudListData>
</template>
<script>
import CrudListData from '~/components/crud/list-data.vue'
import AvatarComponent from '~/components/avatar.vue'

export default {
    components: {
        CrudListData,
        AvatarComponent
    },

    props: {
        value: {
            type: [Number, String],
            default: null
        },

        init: {
            type: [Number, String],
            default: null
        },

        label: {
            type: String,
            default: null
        },

        helpText: {
            type: String,
            default: null
        },

        required: {
            type: Boolean,
            default: false
        },

        allowNull: {
            type: Boolean,
            default: false
        },

        inputClass: {
            type: [String, Object, Array],
            default: null
        }
    }
}
</script>