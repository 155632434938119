var render = function render(_c,_vm){return _c('div',{staticClass:"loading-container",class:_vm.props.loading ? [
        _vm.data.class, 
        _vm.data.staticClass,
        `loading-${_vm.props.type}`
    ] : [],style:(_vm.props.loading ? [
        _vm.data.style, 
        _vm.data.staticStyle
    ] : [])},[_vm._t("default"),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.loading),expression:"props.loading"}],staticClass:"loading-inner"},[_c('div',{staticClass:"loading-bar"},[(_vm.props.message)?_c('div',{staticClass:"mb-1"},[(_vm.props.icon)?_c('OIcon',{attrs:{"icon":['far', _vm.props.icon],"fixed-width":""}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.props.message))])],1):_vm._e()])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }