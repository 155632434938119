<template>
    <OModal
        @close="$emit('close')"
        @confirm="$emit('confirm', value)"
        frameless>
        <form @submit.prevent="$emit('confirm', value)" class="p-3">
            <h6 class="mb-0">{{ title }}</h6>
            <div>{{ message }}</div>
            
            <hr class="my-3 mx-n3">

            <div v-if="inputType === 'textarea'" class="d-flex flex-column gap-2">
                <OTextarea 
                    v-model="value" 
                    class="flex-fill mb-0" 
                    :placeholder="placeholder"
                    :required="required"
                    autofocus
                    />
                <OButton type="primary" submit icon="check" class="ml-auto">OK</OButton>
            </div>
            <div v-else-if="inputType === 'currency'" class="d-flex gap-2">
                <OCurrencyInput 
                    v-model="value" 
                    class="flex-fill mb-0" 
                    :placeholder="placeholder"
                    :required="required"
                    autofocus
                    />
                <OButton type="primary" submit icon="check" />
            </div>
            <div v-else class="d-flex gap-2">
                <OInput 
                    v-model="value" 
                    class="flex-fill mb-0" 
                    :placeholder="placeholder"
                    :required="required"
                    autofocus
                    />
                <OButton type="primary" submit icon="check" />
            </div>
        </form>
    </OModal>
</template>
<script>
export default {
    props: ['title', 'subtitle', 'message', 'type', 'inputType', 'defaultValue', 'placeholder', 'required'],
    data() {
        return {
            value: this.defaultValue,
        };
    },
}
</script>