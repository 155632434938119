<template>
    <OModal
        :title="`${$t('invoice.invoice')} · ${invoice.name || invoice.number}`"
        @close="$emit('close')"
        @confirm="$emit('close')">
        
        <OLoading :loading="loadingState.default">
            <table v-if="invoiceLocal" class="table">
                <tbody>
                    <tr>
                        <th>{{ $t('invoice.number') }}</th>
                        <td>{{ invoiceLocal.number }}</td>
                    </tr>
                    <tr v-if="invoiceLocal.company && invoiceLocal.contact">
                        <th>{{ $t('invoice.company') }}</th>
                        <td><a :href="`/company/${invoiceLocal.company.id}`">{{ invoiceLocal.company.name }} <span v-if="invoiceLocal.contact.name">/ {{ invoiceLocal.contact.name }}</span></a></td>
                    </tr>
                    <tr v-if="invoiceLocal.source_offer">
                        <th>{{ $t('invoice.source-offer') }}</th>
                        <td><a :href="`/offer/${invoiceLocal.source_offer.id}`">{{ invoiceLocal.source_offer.number }} {{ invoiceLocal.source_offer.name }}</a></td>
                    </tr>
                    <tr>
                        <th>{{ $t('vat.rate') }}</th>
                        <td v-if="invoiceLocal.include_vat">{{ invoiceLocal.vat_rate }}%</td>
                        <td v-else>
                            <OBadge type="info">{{ $t('vat.exclude') }}</OBadge>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $t('invoice.qrbill') }}</th>
                        <td>
                            <OBadge v-if="invoiceLocal.show_qrbill" type="info">{{ $t('status.yes') }}</OBadge>
                            <OBadge v-else type="light">{{ $t('status.no') }}</OBadge>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $t('invoice.paid_at') }}</th>
                        <td>{{ $formatDateTime(invoiceLocal.paid_at) }}</td>
                    </tr>
                    <tr v-if="invoiceLocal.member">
                        <th>{{ $t('crud.assigned-to') }}</th>
                        <td class="text-nowrap">{{ invoiceLocal.member.name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('crud.updated-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(invoiceLocal.updated_at) }} <span v-if="invoiceLocal.updated_by"> / {{ invoiceLocal.updated_by.name }}</span></td>
                    </tr>
                    <tr>
                        <th>{{ $t('crud.created-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(invoiceLocal.created_at) }} <span v-if="invoiceLocal.created_by"> / {{ invoiceLocal.created_by.name }}</span></td>
                    </tr>
                </tbody>
            </table>
        
            <o-timeline class="mt-3">
                <o-timeline-item 
                    v-for="(activity, index) in invoiceLocal.activities" 
                    :key="index"
                    :timestamp="activity.created_at" 
                    :type="activity.message"
                    :class="{ 
                        'text-success': activity.message === 'marked:paid',
                        'text-warning': activity.message === 'sent',
                        'text-danger': activity.message === 'reminded'
                    }">
                    {{ $t('activity.' + activity.message) }} {{ $t('activity.logged-by') }}
                    <span v-if="activity.user">{{ activity.user.fullName }}</span>
                    <span v-else>{{ $t('activity.logged-by-system') }}</span>
                </o-timeline-item>
            </o-timeline>
        </OLoading>

    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import HasLoadingState from '~/mixins/hasLoadingState'

export default {
    mixins: [HasLoadingState],

    components: {
        AvatarComponent
    },

    props: ['invoice', '$t'],

    data() {
        return {
            invoiceLocal: this.invoice
        }
    },

    async created() {
        this.$startLoading()
        this.invoiceLocal = await this.$store.dispatch('invoice/needOne', {
            id: this.invoice.id,
            query: {
                include: ['member.user', 'createdBy.user', 'updatedBy.user', 'activities', 'sourceOffer'],
            }
        })

        this.$stopLoading()
    }
}
</script>