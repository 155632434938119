const HasLoadingState = {
    data() {
        return {
            loadingState: {
                default: false
            },
        }
    },

    methods: {
        $startLoading(group = 'default') {
            this.$set(this.loadingState, group, true)
        },

        $stopLoading(group = 'default') {
            this.$set(this.loadingState, group, false)
        },
    },

    computed: {
        $nuxtLoading() {
            return this.$nuxt.$loading.$data
                ? this.$nuxt.$loading.$data.show
                : false
        },
    }
}

export default HasLoadingState