import { track as analyticsTrack } from '~/plugins/analytics'

export const state = () => {
    return {
        isOverlayOpen: false,
        results: [],
        query: '',
    }
}

export const getters = {
    getGroupedResults(state) {
        return Object.keys(state.results).map(group => {
            return {
                name: group,
                items: state.results[group]
            }
        })
    },

    getAllResults(state) {
        return Object.keys(state.results).reduce((carry, group) => {
            return carry.concat(state.results[group])
        }, [])
    }
}

export const mutations = {
    SET_IS_OVERLAY_OPEN(state, open) {
        state.isOverlayOpen = open
    },

    SET_RESULTS(state, results = []) {
        state.results = results
    },

    SET_QUERY(state, query) {
        state.query = query
    }
}

export const actions = {
    async fetchResults({ commit, state }) {
        let results = await this.$axios.$get(`search?q=${encodeURIComponent(state.query)}`)

        commit('SET_RESULTS', results)

        return results
    },

    clearResults({ commit }) {
        commit('SET_RESULTS', [])
    },

    toggleOverlay({ commit, state }, query = null) {
        if (query) {
            commit('SET_QUERY', query)
        }

        commit('SET_RESULTS', [])
        commit('SET_IS_OVERLAY_OPEN', !state.isOverlayOpen)

        if (state.isOverlayOpen) {
            analyticsTrack('Global search opened')
        }
    }
}