<template>
    <OFormGroup :id="$attrs.id" :label="label" :required="required" :help-text="helpText" v-slot="{ id }">
        <div 
            class="d-flex">
            <img 
                v-for="file in files.filter(f => f.type.startsWith('image'))" 
                :key="file.name" 
                :src="getImageSource(file)" 
                :alt="file.name" 
                style="width: 6rem; height: 6rem; object-fit: contain;" 
                class="block rounded border mr-2 mb-2 shadow p-1" 
                :class="previewClass"
                >

            <embed 
                v-for="file in files.filter(f => f.type.startsWith('application'))" 
                :key="file.name" 
                :src="getImageSource(file)"
                style="width: 6rem; height: 6rem; object-fit: contain;" 
                class="block rounded border mr-2 mb-2 shadow" 
                :class="previewClass"
                >
        </div>

        <OButton type="light" class="position-relative" :class="[{
            'border-danger': invalid,
            'border-primary': isDraggingOver,
        }, inputClass]">
            <div class="d-flex justify-content-center gap-2 text-left">
                <OIcon :icon="['far', 'folder-open']" class="mr-05 mt-05" />
                <div>
                    <div v-if="multiple">{{ chooseLabel || 'Dateien auswählen' }}</div>
                    <div v-else>{{ chooseLabel || 'Datei auswählen' }}</div>
                    <div v-if="chooseDescription" class="text-muted">{{ chooseDescription }}</div>
                </div>
            </div>
            <input
                ref="fileInput"
                type="file"
                class="position-absolute inset-0 opacity-0 pointer"
                :class="{
                    'is-invalid': invalid,
                }"
                :multiple="multiple"
                :accept="accept"
                @change="updatePreviews()"
                @drop="onDrop"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                >
        </OButton>
    </OFormGroup>
</template>
<script>
import OFormGroup from './Group.vue'

export default {
    props: {
        value: {
            type: File,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        chooseLabel: {
            type: String,
            default: null,
        },
        chooseDescription: {
            type: String,
            default: null,
        },
        helpText: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: String,
            default: null,
        },
        previewClass: {
            type: String,
            default: null,
        },
        inputClass: {
            type: String,
            default: null,
        },
        maxFileSizeMb: {
            type: Number,
            default: 20,
        },
    },

    components: {
        OFormGroup,
    },

    data() {
        return {
            files: [],
            invalid: false,
            isDraggingOver: false,
        }
    },

    methods: {
        updatePreviews() {
            this.invalid = false
            this.files = Array.from(this.$refs.fileInput.files)

            const maxFileSizeBytes = this.maxFileSizeMb * 1024 * 1024
            if (this.files.some(file => file.size > maxFileSizeBytes)) {
                this.invalid = true
                this.$emit('file-too-large', this.files.filter(file => file.size > maxFileSizeBytes))
                this.$refs.fileInput.value = null
                this.files = []
                return
            }

            this.$emit('input', this.multiple ? this.files : (this.files[0] || null))
        },

        onDrop(event) {
            event.preventDefault()
            this.$refs.fileInput.files = event.dataTransfer.files
            this.updatePreviews()
        },

        onDragEnter(event) {
            event.preventDefault()
            this.isDraggingOver = true
        },

        onDragLeave(event) {
            event.preventDefault()
            this.isDraggingOver = false
        },

        getImageSource(file) {
            return URL.createObjectURL(file)
        }
    }
}
</script>