<template>
    <div 
        class="row align-items-center small">
        <div class="col-4 text-muted text-right" v-tooltip="formatDate(timestamp)">{{ formatDate(timestamp, true) }}</div>
        <div class="col-1 d-flex align-items-center justify-content-center py-3 py-md-2">
            <div class="activity-log-point text-lightish"></div>
        </div>
        <div class="col-7">
            <slot />
        </div>
    </div>
</template>
<script>
import { format, formatDistanceToNow } from 'date-fns'
import {
    de as deDateLocale,
    enGB as enDateLocale
} from 'date-fns/locale'

const dateLocales = {
    en: enDateLocale,
    de: deDateLocale
}

export default {
    props: {
        type: {
            type: String,
            default: null
        },
        timestamp: {
            type: String,
            required: true
        },

    },

    methods: {
        formatDate(date, distance = false) {
            date = date.replace(/-/g, '/').replace('T', ' ').slice(0, 19)
            date = new Date(date)

            if (distance) {
                return this.capitalize(formatDistanceToNow(date, {
                    addSuffix: true,
                    locale: dateLocales[this.$store.state.locale]
                }))
            }

            return format(date, 'dd.MM.yyyy kk:mm', {
                locale: dateLocales[this.$store.state.locale]
            })
        },

        capitalize(text) {
            return text.charAt(0).toUpperCase() + text.slice(1)
        }
    }
}
</script>
<style lang="scss" scoped>
.activity-log-point {
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 2px;
    
    &:before,
    &:after {
        content: "";
        border-left: 1px solid currentColor;
        width: 0;
        height: 25%;
        position: absolute;
        left: calc(50% - .5px);
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}
</style>