<template>
    <OModal
        :title="expense.name"
        @close="$emit('close')"
        @confirm="$emit('confirm', paidAt)"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('expense.mark-as-paid')"
        type="success"
        allow-overflow>

        <ODatePicker
            :label="$t('invoice.paid_at')"
            v-model="paidAt"
            :options="{ minDate: expense.issued_at }"
            required
            />

    </OModal>
</template>
<script>
export default {
    props: ['expense', '$t'],

    data() {
        return {
            paidAt: this.$dateForDB('now'),
        }
    }
}
</script>