<template>
	<img
		v-if="image && !imageFailed"
		:src="image.url"
		class="avatar flex-shrink-0 rounded-circle"
		:class="{ 'bg-darkish': dark }"
		style="object-fit: contain;"
		:style="{ width: getSize(size), height: getSize(size) }"
		loading="lazy"
		@error="imageFailed = true"
	/>
	<div
		v-else
		class="avatar flex-shrink-0 text-white rounded-circle d-inline-flex align-items-center justify-content-center text-uppercase"
		:class="{ 
            'small': size === 'sm', 
            'bg-light text-muted': fallback === '?',
        }"
		:style="{
			backgroundColor: getColor(fallback),
			width: getSize(size), 
			height: getSize(size),
            lineHeight: '110%',
		}"
	>{{ initials }}</div>
</template>
<script>
import { stringToColor } from '~/util/helpers'

export default {
    props: {
        image: {
            type: Object,
            default: null
        },

        fallback: {
            type: String,
            default: ''
        },

        dark: {
            type: Boolean,
            default: false
        },

        size: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            imageFailed: false
        }
    },

    computed: {
        initials() {
            if (!this.fallback || this.fallback.length === 0) {
                return '?'
            }
            
            const fallback = this.fallback.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, ' ')

            return fallback.indexOf(' ') !== -1
                ? fallback.split(' ', 2).map(word => word.charAt(0)).join('')
                : fallback.substring(0, 2)
        }
    },

    methods: {
        getColor(input) {
            if (input === '?') {
                return null;
            }

            return stringToColor(input)
        },

        getSize(size) {
            if (size === 'sm') {
                return '1.5rem'
            } else if (size === 'lg') {
                return '3.25rem'
            }

            return '2.5rem'
        }
    }
}
</script>