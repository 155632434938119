<template>
    <OHeader :title="$t('app.title')" :sidebar-open="$store.state.sidebarOpen">
        <OHeaderGroup brand>
            <div class="d-lg-none mr-3">
                <NuxtLink to="/" class="text-white">
                    <StrawblondLogo style="width: 1.75rem; height: 1.75rem;" />
                </NuxtLink>
            </div>

            <ODropdown v-if="$org">
                <template #toggle>
                    <OButton 
                        :type="$org.is_support_login ? 'primary' : 'light'" 
                        icon-after="angle-down" 
                        class="d-flex align-items-center" 
                        :class="{ 'pl-2': $org.logo }"
                        style="max-width: 15rem;"
                        >
                        <AvatarComponent v-if="$org.logo" :image="$org.logo" size="sm" class="mr-1 bg-white-real" style="padding: 2px;" />
                        <span v-else class="d-md-none"><OIcon :icon="['fal', 'building']" size="lg" swap-opacity /></span>
                        <span class="d-none d-md-inline ml-1 text-truncate">{{ $org.name }}</span>
                    </OButton>
                </template>

                <ODropdownHeader v-if="$can('edit organization|manage organization billing')">{{ $org.name }}</ODropdownHeader>
                <!-- <ODropdownItem v-if="$can('manage employment info')" to="/member" icon="users">{{ $t('organization.team') }}</ODropdownItem> -->
                <ODropdownItem v-if="$can('edit organization')" to="/organization/settings" icon="cog">{{ $t('organization.settings') }}</ODropdownItem>
                <ODropdownItem v-if="$can('manage organization billing')" to="/organization/billing" icon="credit-card">{{ $t('organization.plan-and-payment') }}</ODropdownItem>
                <ODropdownItem v-if="$can('list outgoing_mail')" to="/outgoing-mail" icon="mail-bulk">{{ $t('app.outgoing-mail') }}</ODropdownItem>
                <div v-if="$can('edit organization|manage organization billing')" class="dropdown-divider"></div>

                <div v-if="$auth.user.organizations.length > 1 && !$store.state.screencastMode">
                    <ODropdownHeader>{{ $t('organization.change') }}</ODropdownHeader>
                    <ODropdownItem
                        v-for="organization in $auth.user.organizations.filter(org => $auth.user.current_organization.id !== org.id)"
                        :key="organization.slug"
                        @click="$store.dispatch('user/changeOrganization', organization)">
                        <AvatarComponent :image="organization.logo" :fallback="organization.name" size="sm" class="ml-n1 mr-2" :class="{ 'border bg-white-real': organization.logo }" style="padding: 1px;" />
                        <span>{{ organization.name }}</span>
                    </ODropdownItem>
                    <div class="dropdown-divider"></div>
                </div>
                <ODropdownItem to="/organization-create" icon="plus">{{ $t('organization.create-extra') }}</ODropdownItem>
            </ODropdown>

            <a 
                v-if="$auth.user.is_superuser && !$store.state.screencastMode" 
                :href="$auth.user.superuser_dashboard_url" 
                target="_blank"
                >
                <OButton 
                    icon="shield-alt" 
                    class="ml-2 ml-md-3" 
                    type="light"
                    v-tooltip="'Superuser Dashboard'" 
                    />
            </a>

            <OToggle 
                v-if="$org"
                :states="[
                    $t('app.hide-money-values'),
                ]" 
                :value="$store.state.hideMoneyValues" 
                @input="$store.commit('setHideMoneyValues', $event)" 
                class="d-none d-xl-block mb-0 ml-3 ml-hd-4"
                v-tooltip:bottom="$t('app.hide-money-values-text')"
                />
        </OHeaderGroup>
        <OHeaderGroup>
            <div v-if="$org && !$store.state.screencastMode" class="mr-3 d-none d-lg-block text-sm font-weight-semibold border-right border-light px-3 py-2" style="line-height: normal;">
                <NuxtLink to="/organization/billing">
                    <div v-if="$can('manage organization billing') && $org && $org.subscription && $org.subscription.stripe_status === 'past_due'" class="text-danger">
                        <OIcon :icon="['far', 'exclamation-circle']" /> {{ $t('payment.past-due') }}
                    </div>
                    <div v-else-if="$org.is_on_trial" class="text-success text-right">
                        <OIcon :icon="['far', 'info-circle']" /> {{ $t('organization.on-trial') }}<br>
                        <span class="text-muted font-weight-normal">{{ $t('organization.trial-ends') }} {{ $formatDateDistance($org.trial_ends_at) }}</span>
                    </div>
                    <div v-else-if="!$org.subscription" class="text-danger text-right">
                        <OIcon :icon="['far', 'info-circle']" /> {{ $t('organization.no-active-plan') }}<br>
                        <span class="text-muted font-weight-normal">{{ $t('payment.buy-now') }}</span>
                    </div>
                </NuxtLink>
            </div>

            <div v-if="$org" class="keyboard-only">
                <ODropdown align="bottom-end" limit>
                    <OButton slot="toggle" icon="keyboard" icon-size="lg" v-tooltip:bottom="$t('app.shortcuts')"></OButton>

                    <ODropdownHeader>{{ $t('app.global') }}</ODropdownHeader>
                    <ODropdownItem :kbd="$ctrlOrCmd('G')">{{ $t('search.find-anything') }}</ODropdownItem>
                    <ODropdownItem :kbd="$ctrlOrCmd('K')">{{ $t('command.command-palette') }}</ODropdownItem>
                    <ODropdownItem :kbd="$altOrOption('S')">{{ $t('app.toggle-sidebar') }}</ODropdownItem>
                    <div class="dropdown-divider"></div>
                    <ODropdownHeader>{{ $t('crud.list-view') }}</ODropdownHeader>
                    <ODropdownItem kbd="N">{{ $t('crud.create-entry') }}</ODropdownItem>
                    <ODropdownItem kbd="F">{{ $t('search.search') }}</ODropdownItem>
                    <ODropdownItem kbd="K">{{ $t('pagination.next') }}</ODropdownItem>
                    <ODropdownItem kbd="J">{{ $t('pagination.prev') }}</ODropdownItem>
                    <div class="dropdown-divider"></div>
                    <ODropdownHeader>{{ $t('crud.detail-view') }}</ODropdownHeader>
                    <ODropdownItem kbd="S">{{ $t('crud.save-entry') }}</ODropdownItem>
                    <ODropdownItem kbd="E">{{ $t('crud.edit-entry') }}</ODropdownItem>
                    <ODropdownItem kbd="Del">{{ $t('crud.delete-entry') }}</ODropdownItem>
                    <ODropdownItem kbd="R">{{ $t('crud.discard-entry') }}</ODropdownItem>
                    <div class="dropdown-divider"></div>
                    <ODropdownHeader>{{ $t('crud.bulk-selection') }}</ODropdownHeader>
                    <ODropdownItem :kbd="$ctrlOrCmd('Click')">{{ $t('crud.select') }}</ODropdownItem>
                    <ODropdownItem kbd="A">{{ $t('crud.select-all') }}</ODropdownItem>
                    <ODropdownItem kbd="Esc">{{ $t('crud.clear-selection') }}</ODropdownItem>
                    <ODropdownItem kbd="E">{{ $t('crud.bulk-editor') }}</ODropdownItem>
                </ODropdown>
            </div>

            <ODropdown v-if="$store.state.locales.length > 1" align="bottom-end">
                <OButton slot="toggle" icon="globe" icon-prefix="fal" icon-size="lg" v-tooltip:bottom="$t('app.change-language')"></OButton>
                <ODropdownHeader>{{ $t('app.change-language') }}</ODropdownHeader>
                <ODropdownItem @click="$store.dispatch('changeLocale', 'de')" :active="$i18n.locale === 'de'">Deutsch</ODropdownItem>
                <ODropdownItem @click="$store.dispatch('changeLocale', 'en')" :active="$i18n.locale === 'en'">English</ODropdownItem>
            </ODropdown>

            <ODropdown align="bottom-end">
                <OButton slot="toggle" icon="question-circle" icon-prefix="fal" icon-size="lg" v-tooltip:bottom="$t('app.help')"></OButton>
                <ODropdownHeader>{{ $t('app.help') }}</ODropdownHeader>
                <ODropdownItem icon="book" :href="`https://strawblond.com/${$t('footer.handbook.href')}`" @click="$analyticsTrack('Handbook clicked')">{{ $t('footer.handbook.label') }}</ODropdownItem>
                <ODropdownItem icon="question-circle" @click="toggleSupportBubble()">{{ $t('app.contact-support') }}</ODropdownItem>
                <div class="dropdown-divider"></div>
                <ODropdownHeader>{{ $t('app.misc') }}</ODropdownHeader>
                <ODropdownItem icon="comment-lines" @click="$modal($auth.user, 'user-feedback')">{{ $t('feedback.modal-title') }}</ODropdownItem>
                <ODropdownItem icon="sparkles" to="/changelog" @click="$analyticsTrack('Changelog clicked')">{{ $t('footer.releases.label') }}</ODropdownItem>
            </ODropdown>

            <OButton @click="$store.dispatch('notification/toggleSlideout')" v-tooltip:bottom="$t('notification.notifications')" class="mr-1 mr-md-3">
                <span class="icon-wrapper">
                    <OIcon :icon="['fal', 'bell']" size="lg"></OIcon>
                    <span v-if="$store.state.notification.unread.length > 0" class="icon-layer">{{ $store.state.notification.unread.length }}</span>
                </span>
            </OButton>

            <OButton v-if="$org" @click.stop="$store.dispatch('search/toggleOverlay')" type="light">
                <OIcon :icon="['fal', 'search']" size="lg" class="mr-md-2"></OIcon> 
                <span class="d-none d-md-inline">{{ $t('crud.search') }}</span> 
                <span class="shortcut ml-1" v-html="$ctrlOrCmd('G')" hidden></span>
            </OButton>

            <ODropdown v-if="$auth.user" class="ml-3">
                <div slot="toggle">
                    <button class="btn btn-link p-0">
                        <OButton 
                            v-if="$store.state.screencastMode || !$auth.user.avatar" 
                            style="background-image: url(https://source.boringavatars.com/beam/120/Maria%20Mitchell?colors=FDBF39,FED158,FFDB6C,FFDF73); background-clip: padding-box;" 
                            class="rounded-circle bg-light bg-cover"
                            >
                            &nbsp;
                        </OButton>
                        <OButton 
                            v-else 
                            :style="{ backgroundImage: `url(${$auth.user.avatar.url})`, backgroundClip: 'padding-box' }" 
                            class="rounded-circle bg-primary bg-cover"
                            >
                            &nbsp;
                        </OButton>
                        <OIcon :icon="['far', 'angle-down']" class="d-none d-md-inline-block ml-1" />
                    </button>
                </div>

                <ODropdownHeader>{{ $auth.user.fullName }}</ODropdownHeader>
                <ODropdownItem to="/user/profile" icon="user-circle">{{ $t('user.profile') }}</ODropdownItem>
                <ODropdownItem key="logout" to="/auth/logout" icon="sign-in">{{ $t('auth.sign-out') }}</ODropdownItem>

                <div class="dropdown-divider"></div>
                <ODropdownHeader>{{ $t('theme.theme') }}</ODropdownHeader>
                <OSelect 
                    :value="$theme() || 'auto'" 
                    @input="$theme($event)" :options="[
                        { label: $t('theme.auto'), value: 'auto' },
                        { label: $t('theme.light'), value: 'light' },
                        { label: $t('theme.dark'), value: 'dark' },
                    ]"
                    class="mx-2 mb-2">
                </OSelect>

                <!-- <div class="dropdown-divider"></div> -->
                <!-- <ODropdownItem v-if="$can('edit organization')" icon="clipboard-list-check" to="/onboarding">{{ $t('onboarding.show') }}</ODropdownItem>
                <ODropdownItem icon="book" :href="'https://strawblond.com/' + $t('footer.handbook.href')">{{ $t('footer.handbook.label') }}</ODropdownItem> -->
            </ODropdown>
        </OHeaderGroup>
    </OHeader>
</template>
<script>
import AvatarComponent from "~/components/avatar.vue";
export default {
    components: {
        AvatarComponent,
    },

    methods: {
        showOnboarding() {
            this.$store.dispatch('showOnboarding')
            this.$router.push('/')
        },

        toggleSupportBubble() {
            window._chatlio.open()
        },
    }
}
</script>