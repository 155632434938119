<template>
    <div class="dropdown" style="z-index: 1060;">
        <div class="dropdown-menu show">
            <button 
                v-for="(item, index) in items" 
                :key="index"
                class="dropdown-item d-flex align-items-center gap-2"
                :class="{ 'active': index === selectedIndex }"
                @click="selectItem(index)"
                >
                <AvatarComponent 
                    :image="item.avatar" 
                    :fallback="item.label" 
                    size="sm" 
                    class="flex-shrink-0 ml-n1" 
                    />
                <div>{{ item.label }}</div>
            </button>

            <div v-if="items.length === 0" class="dropdown-text text-muted">
                Keine Resultate
            </div>
        </div>
    </div>
</template>
<script>
import AvatarComponent from '@/components/avatar.vue'
export default {
    components: {
        AvatarComponent,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },

        command: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            selectedIndex: 0,
        }
    },

    watch: {
        items() {
            this.selectedIndex = 0
        },
    },

    methods: {
        onKeyDown({ event }) {
            if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
            }

            if (event.key === 'Enter') {
                this.enterHandler()
                return true
            }

            return false
        },

        upHandler() {
            this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
        },

        downHandler() {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length
        },

        enterHandler() {
            this.selectItem(this.selectedIndex)
        },

        selectItem(index) {
            const item = this.items[index]

            if (item) {
                this.command({ 
                    id: item.id, 
                    label: item.label 
                })
            }
        },
    },
}
</script>