<template>
    <Component 
        ref="button" 
        :is="to ? 'NuxtLink' : 'button'"
        :to="to"
        :type="submit ? 'submit' : 'button'" 
        class="btn position-relative" 
        :class="[
            `btn${outline ? '-outline' : ''}-${type}`, 
            block ? 'btn-block' : null,
            size ? `btn-${size}` : null,
            (icon || iconAfter) && !$slots.default ? 'btn-icon' : null
        ]" 
        @click="onClick"
        :tabindex="preventFocus ? '-1' : null"
        :disabled="disabled || actionPending"
        :aria-disabled="disabled || actionPending"
        >

        <OIcon v-if="icon && actionPending" :icon="['far', 'spinner']" spin :size="iconSize" :class="{ 'mr-1': $slots.default }" :fixed-width="(icon || iconAfter) && !$slots.default"></OIcon>
        <OIcon v-else-if="icon" :icon="[iconPrefix, icon]" :size="iconSize" :class="{ 'mr-1': $slots.default }" :fixed-width="(icon || iconAfter) && !$slots.default"></OIcon>

        <slot :actionPending="actionPending"></slot>

        <span v-if="kbd" class="shortcut ml-1" hidden>{{ kbd }}</span>
        
        <OIcon v-if="iconAfter && actionPending" :icon="['far', 'spinner']" spin :size="iconSize" :class="{ 'ml-1': $slots.default }" :fixed-width="(icon || iconAfter) && !$slots.default"></OIcon>
        <OIcon v-else-if="iconAfter" :icon="[iconPrefix, iconAfter]" :size="iconSize" :class="{ 'ml-1': $slots.default }" :fixed-width="(icon || iconAfter) && !$slots.default"></OIcon>
    </Component>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'link'
        },
        to: {
            type: [String, Object],
            default: null
        },
        icon: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
        iconPrefix: {
            type: String,
            default: 'far',
        },
        iconSize: {
            type: String,
            default: null,
        },
        submit: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        outline: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: null
        },
        preventFocus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        action: {
            type: Function,
            default: null,
        },
        kbd: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            actionPending: false,
        }
    },

    methods: {
        focus() {
            this.$nextTick(() => {
                this.$refs.button.focus()
            })
        },

        onClick(event) {
            this.$emit('click', event)

            if (this.action) {
                this.actionPending = true
                this.action().then(result => {
                    this.actionPending = false
                    this.$emit('action-finished', result)
                })
            }
        }
    }
}
</script>
