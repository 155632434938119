<template>
    <div class="alert d-flex" :class="[`alert-${type}`]" role="alert">
        <OIcon v-if="icon" :icon="['far', icon]" fixed-width class="alert-icon flex-shrink-0 d-block mt-1 mr-3" />
        <div class="alert-body">
            <h6 v-if="title" class="alert-title">{{ title }}</h6>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'light'
        },
        icon: {
            type: String,
            default() {
                return {
                    'info': 'info-circle',
                    'success': 'check-circle',
                    'warning': 'exclamation-triangle',
                    'error': 'exclamation-circle'
                }[this.type] || null;
            }
        },
        title: {
            type: String,
            default: null
        }
    }
}
</script>