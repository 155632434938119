<template>
    <div class="form-group">
        <label v-if="label" :for="`form-radio-${uuid}`">{{ label }} <span v-if="required" class="text-primary">*</span></label>
        <div v-if="helpText" 
            :id="`form-radio-${uuid}-help`" 
            class="form-text mt-n2 mb-2">
            {{ Array.isArray(helpText) ? helpText.join(', ') : helpText }}
        </div>
        <div class="d-flex flex-column gap-y-1" :class="{
            'flex-wrap align-items-start flex-lg-row gap-x-3': inline,
        }">
            <div v-for="(option, index) in options" :key="`radio-option-${index}`" class="custom-control custom-radio">
                <input :id="`form-radio-${uuid}-${index}`"
                    type="radio"
                    class="custom-control-input"
                    :name="`form-radio-${uuid}`"
                    :value="option[valueField]"
                    :checked="option[valueField] == value"
                    :disabled="disabled"
                    @input="$emit('input', $event.target.value)">
                <label :for="`form-radio-${uuid}-${index}`" class="d-flex align-items-center gap-2 custom-control-label">
                    <OIcon v-if="option.icon" :icon="[option.iconPrefix || 'far', option.icon]" class="ml-2" fixed-width />
                    <div class="flex-grow-1">
                        <slot
                        :id="`form-radio-${uuid}-${index}`"
                        :option="option"
                        :index="index"
                        :checked="option[valueField] == value"
                        >
                            <div :class="{ 'font-weight-semibold': option[descriptionField] }">{{ option[labelField] }}</div>
                            <div v-if="option[descriptionField]" class="text-muted">{{ option[descriptionField] }}</div>
                        </slot>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
import Uuid from '~/mixins/uuid'

export default {
    mixins: [Uuid],

    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            default: null,
        },
        helpText: {
            type: [String, Array],
            default: null,
        },
        options: {
            type: Array,
            default: []
        },
        labelField: {
            type: String,
            default: 'label'
        },
        valueField: {
            type: String,
            default: 'value'
        },
        descriptionField: {
            type: String,
            default: 'description'
        },
        inline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    }
}
</script>