<template>
    <OModal
        :title="$t('billing.create-and-lock-items')"
        @close="$emit('close')"
        @confirm="$emit('confirm', { elementsMergeMode, mergeSimilarTimes })"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('invoice.create')"
        allow-overflow>

        <ORadio 
            v-model="elementsMergeMode"
            :options="[
                { label: $t('billing.elements-merge-list'), description: $t('billing.elements-merge-list-text'), value: 'list' },
                { label: $t('billing.elements-merge-group'), description: $t('billing.elements-merge-group-text'), value: 'group' },
                { label: $t('billing.elements-merge-squash'), description: $t('billing.elements-merge-squash-text'), value: 'squash' },
            ]"
            >
            <template #default="{ option, index }">
                <div class="d-flex mb-4">
                    <div class="mr-auto pr-3">
                        <h6 class="mb-0">{{ option.label }}</h6>
                        <div>{{ option.description }}</div>
                    </div>
                    <div style="width: 3rem;" class="flex-shrink-0">
                        <svg v-if="index === 0" class="w-100 border border-lightish rounded-sm shadow-sm" viewBox="0 0 170 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.5" y="1.5" width="167" height="247" rx="23.5" />
                            <rect x="27" y="101" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <rect x="27" y="125" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <rect x="27" y="149" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <rect x="27" y="173" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <line x1="27" y1="41.5" x2="68" y2="41.5" stroke="var(--lightish)" stroke-width="3"/>
                            <line x1="27" y1="48.5" x2="55" y2="48.5" stroke="var(--lightish)" stroke-width="3"/>
                            <line x1="27" y1="55.5" x2="60" y2="55.5" stroke="var(--lightish)" stroke-width="3"/>
                        </svg>
                        <svg v-else-if="index === 1" class="w-100 border border-lightish rounded-sm shadow-sm" viewBox="0 0 170 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.5" y="1.5" width="167" height="247" rx="23.5" />
                            <rect x="27" y="113" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <rect x="27" y="183" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <rect x="27" y="137" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <rect x="27" y="207" width="116" height="15" rx="5" fill="var(--primary)"/>
                            <line x1="27" y1="41.5" x2="68" y2="41.5" stroke="var(--lightish)" stroke-width="3"/>
                            <line x1="27" y1="101.5" x2="68" y2="101.5" stroke="var(--darkish)" stroke-width="3"/>
                            <line x1="27" y1="173.5" x2="68" y2="173.5" stroke="var(--darkish)" stroke-width="3"/>
                            <line x1="27" y1="48.5" x2="55" y2="48.5" stroke="var(--lightish)" stroke-width="3"/>
                            <line x1="27" y1="55.5" x2="60" y2="55.5" stroke="var(--lightish)" stroke-width="3"/>
                        </svg>
                        <svg v-else-if="index === 2" class="w-100 border border-lightish rounded-sm shadow-sm" viewBox="0 0 170 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.5" y="1.5" width="167" height="247" rx="23.5" />
                            <rect x="27" y="101" width="116" height="30" rx="5" fill="var(--primary)"/>
                            <line x1="27" y1="41.5" x2="68" y2="41.5" stroke="var(--lightish)" stroke-width="3"/>
                            <line x1="27" y1="48.5" x2="55" y2="48.5" stroke="var(--lightish)" stroke-width="3"/>
                            <line x1="27" y1="55.5" x2="60" y2="55.5" stroke="var(--lightish)" stroke-width="3"/>
                        </svg>
                    </div>
                </div>
            </template>
        </ORadio>

        <OAlert v-if="$org.addons.includes('experimental_features') && elementsMergeMode != 'list'" type="notice" :title="$t('ai.title')" icon="sparkles" class="mt-n3">
            <OToggle
                :label="$t('billing.merge-similar-times')"
                :help-text="$t('billing.merge-similar-times-text')"
                :states="[
                    $t('status.yes'),
                    $t('status.no')
                ]"
                v-model="mergeSimilarTimes"
                class="m-0"
                />
        </OAlert>

        <OAlert type="info" icon="info-circle" class="mt-4 mb-n3">{{ $t('billing.create-and-lock-items-text') }}</OAlert>

    </OModal>
</template>
<script>
export default {
    props: ['$t'],

    data() {
        return {
            elementsMergeMode: 'list',
            mergeSimilarTimes: false,
        }
    }
}
</script>