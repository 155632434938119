<template>
    <OModal
        :title="$t('expense.create')"
        :subtitle="$t('expense.create-text')"
        @close="onClose"
        @confirm="$emit('confirm', expense)"
        allow-overflow
        :hide-footer="loading"
        >
        <template #default>
            <div v-if="loading">
                <div v-if="!expense" class="py-5 mt-n3">
                    <OLoading
                        loading
                        type="notice"
                        :message="$t('file-upload.uploading')"
                        />
                </div>
                <div v-else>
                    <OLoading loading type="notice" />
                    <div 
                        class="card card-body shadow border border-lightish py-25 d-flex align-items-center flex-row overflow-hidden" 
                        style="height: 70px; z-index: 1;">
                        <div class="text-truncate">
                            <Transition name="switch" mode="out-in" appear>
                                <div :key="`expense-name-${expense.name}`" class="text-black font-weight-semibold text-truncate">{{ expense.name || $t('expense.type-' + expense.type) }}</div>
                            </Transition>
                            <Transition name="switch" mode="out-in" appear>
                                <div v-if="expense.issued_at" key="date" class="text-muted">{{ $formatDate(expense.issued_at) }}</div>
                                <div v-else key="no-date" class="text-muted">{{ $formatDate(new Date()) }}</div>
                            </Transition>
                        </div>
                        <div class="ml-auto monospace font-weight-bold my-0 text-right" style="font-size: 1.25em; line-height: normal;">
                            <Transition name="switch" mode="out-in" appear>
                                <div v-if="expense.total_with_vat" :key="`expense-total-${expense.total_with_vat}`">
                                    <span class="text-muted">{{ expense.currency }}</span> <span class="text-black">{{ $formatCurrency(expense.total_with_vat) }}</span>
                                </div>
                            </Transition>
                        </div>
                    </div>
                    <div class="border p-3 rounded d-flex flex-column gap-1 mt-n5 pt-5 mb-0 overflow-hidden">
                        <div
                            v-for="(s, source) in status"
                            class="d-flex align-items-center gap-2"
                            >
                            <OIcon :icon="['far', {
                                'swiss-qr-code': 'qrcode',
                                'ai-ocr': 'sparkles',
                                'ai-booking': 'book',
                                'auto-crop': 'crop-alt',
                            }[source]]" class="opacity-50" fixed-width />
                            <div>{{ $t('expense.parsing-status.' + source) }}</div>
                            <div class="flex-grow-1 border-bottom border-light"></div>
                            <div class="ml-auto d-grid grid-cover">
                                <Transition name="fade" appear>
                                    <OIcon v-if="!s" key="waiting" :icon="['far', 'spinner-third']" class="text-muted" spin fixed-width />
                                    <OIcon v-else-if="s === 'started'" key="started" :icon="['far', 'spinner']" class="text-notice" spin fixed-width />
                                    <OIcon v-else-if="s === 'success'" key="success" :icon="['far', 'check']" class="text-success" fixed-width />
                                    <OIcon v-else-if="s === 'skipped'" key="skipped" :icon="['far', 'circle']" class="text-muted" fixed-width />
                                    <OIcon v-else-if="s === 'failed'" key="failed" :icon="['far', 'times-circle']" class="text-danger" fixed-width />
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="btn-group border border-lightish p-px gap-px shadow-sm rounded-lg w-100 mb-3 text-nowrap">
                    <OButton
                        icon="receipt"
                        @click="type = 'receipt'"
                        :type="type === 'receipt' ? 'dark' : 'secondary'"
                        class="flex-1"
                        v-tooltip:left="$t('expense.type-receipt-text')"
                    >
                        {{ $t('expense.type-receipt') }}
                    </OButton>
                    <OButton
                        icon="file-invoice"
                        @click="type = 'invoice'"
                        :type="type === 'invoice' ? 'dark' : 'secondary'"
                        class="flex-1"
                        v-tooltip:right="$t('expense.type-invoice-text')"
                    >
                        {{ $t('expense.type-invoice') }}
                    </OButton>
                </div>
                

                <CrudListData v-if="type === 'receipt' && $org.expense_payment_methods_count > 0" resource="expense_payment_method" v-slot="{ items }">
                    <ORadio
                        v-model="payment_method_id"
                        :options="items.map(i => ({
                            value: i.id,
                            label: i.name,
                            icon: i.icon ? i.icon.split(',')[1] : null,
                            iconPrefix: i.icon ? i.icon.split(',')[0] : null,
                        }))"
                        :label="$t('expense.paid-with')"
                        class="custom-radio-group mb-3"
                        style="max-height: 20rem; overflow-y: auto;"
                        required
                        />
                </CrudListData>

                <OFilePreview v-model="file" accept="application/pdf,image/jpeg,image/jpg,image/png"
                    :choose-label="$t('file-upload.choose')" :choose-description="$t('expense.or-take-photo')"
                    :max-file-size-mb="15" @file-too-large="$toast($t('file-upload.file-too-large'), 'danger', {
                        message: $t('file-upload.file-too-large-text', { size: 15 }),
                    })"
                    @input="type === 'invoice' || $org.expense_payment_methods_count === 0 || payment_method_id ? $refs.submitButton.onClick() : null"
                    input-class="py-3 btn-block" />

                <div class="mt-3">
                    <OAlert v-if="!$org.addons.includes('unlimited_expense_parser_quota') && $org.expense_parser_quota <= 0"
                        type="warning">
                        <div>
                            {{ $t('expense.parser-quota-limit-reached', { limit: $org.free_expense_parser_quota }) }}
                        </div>
                        <OButton @click="routerPush('/organization/billing#addons')" type="warning" size="sm" class="mt-2">
                            {{ $t('expense.parser-quota-limit-reached-button') }}
                        </OButton>
                    </OAlert>

                    <OProgress v-else :type="$org.expense_parser_quota > 0
                        ? 'success'
                        : $org.addons.includes('unlimited_expense_parser_quota')
                            ? 'notice'
                            : 'warning'"
                        :value="$org.expense_parser_quota >= 0 ? $org.expense_parser_quota : $org.free_expense_parser_quota"
                        :max-value="$org.free_expense_parser_quota" class="my-1">
                        <div class="d-flex flex-wrap justify-content-between small">
                            <div>
                                <OIcon :icon="['far', 'sparkles']" class="text-muted" /> {{ $t('expense.parser') }}
                            </div>
                            <div v-if="$org.expense_parser_quota >= 0">
                                <span class="font-weight-semibold">{{ $org.expense_parser_quota }}</span><span
                                    class="text-muted">/{{ $t('expense.parser-quota-free', {
                                        free:
                                            $org.free_expense_parser_quota
                                    }) }}</span>
                            </div>
                            <div v-else>
                                <span class="text-muted">{{ $t('expense.parser-quota-paid') }}:</span>
                                <span class="font-weight-semibold">{{ Math.abs($org.expense_parser_quota) }}</span>
                            </div>
                        </div>
                    </OProgress>
                </div>
            </div>
        </template>

        <template #footer="{ close, confirm }">
            <a class="btn btn-link mr-auto" href="/expense/create" v-tooltip="$t('expense.create-manually-text')">{{
                $t('expense.create-manually') }}</a>
            <OButton 
                ref="submitButton" 
                type="primary"
                :action="() => upload(confirm)"
                :disabled="!file || (type === 'receipt' && $org.expense_payment_methods_count > 0 && !payment_method_id)"
                >
                {{ $t('expense.create') }}
            </OButton>
        </template>
    </OModal>
</template>
<script>
import Storage from '~/util/storage'
import CrudListData from '~/components/crud/list-data'
import { track as analyticsTrack } from '~/plugins/analytics'

export default {
    props: ['$t', 'app', 'routerPush', 'sharedFile'],

    components: {
        CrudListData,
    },

    data() {
        return {
            type: Storage.get('last-expense-create-type', 'receipt'),
            file: this.sharedFile || null,
            payment_method_id: null,
            loading: false,
            expense: null,
            status: {
                'swiss-qr-code': null,
                'ai-ocr': null,
                'ai-booking': null,
                'auto-crop': null,
            }
        }
    },

    methods: {
        async upload(confirm) {
            this.loading = true

            const { expense } = await this.$store.dispatch('media/upload', {
                file: this.file,
                endpoint: 'expense/upload',
                options: {
                    data: {
                        name: this.name,
                        type: this.type,
                        payment_method_id: this.type === 'receipt' ? this.payment_method_id : null,
                    }
                }
            })
            
            this.expense = expense

            return new Promise((resolve) => {
                this.app.$echo.private(`expense.${expense.id}`)
                    .listen('.expense.processing-finished', () => {
                        this.app.$echo.leave(`expense.${expense.id}`)
                        analyticsTrack('Expense created', { type: expense.type })

                        setTimeout(() => {
                            resolve()
                            this.loading = false
                            confirm()
                            this.app.$auth.fetchUser()
                        }, 500)
                    })
                    .listen('.expense.processing-update', async ({ source, status }) => {
                        this.status[source] = status || 'success'
                        this.expense = await this.app.$axios.$get(`/expense/${expense.id}`)
                    })
            })
        },

        onClose() {
            if (this.expense) {
                this.app.$echo.leave(`expense.${this.expense.id}`)
            }

            this.$store.dispatch('expense/refetchAll')
            this.$emit('close')
        }
    },

    watch: {
        type(value) {
            Storage.set('last-expense-create-type', value)
        }
    }
}
</script>
