<template>
    <OModal ref="modal" :title="$t('2fa.2fa')">

        <div v-if="recoveryMode">
            <p>{{ $t('2fa.recovery-codes-text') }}</p>
            <form @submit.prevent="challenge()">
                <OInput
                    :label="$t('2fa.recovery-code')"
                    v-model="recoveryCode"
                    placeholder="XXXXXXXXXX-XXXXXXXXXX"
                    required
                    />
            </form>
        </div>
        <div v-else>
            <p>{{ $t('2fa.setup-confirm') }}</p>
            <div class="code-container rounded-lg form-control d-block h-auto p-1" @click="$refs.codeInput.focus()">
                <div class="row no-gutters mr-n1">
                    <div v-for="(digit, index) in codeDigits" :key="index" class="col">
                        <div class="h3 m-0 py-3 rounded text-center font-weight-bold mr-1 border"
                            :class="{
                                'bg-darkish border-darkish text-muted': code.length === index && codeInputFocused,
                            }">
                            <span v-if="digit" class="text-black">{{ digit }}</span>
                            <span v-else-if="code.length === index && codeInputFocused" class="animate-pulse">
                                |
                            </span>
                            <span v-else class="text-muted opacity-50">&bull;</span>
                        </div>
                    </div>
                </div>
                <input
                    ref="codeInput"
                    class="code-input"
                    type="text"
                    inputmode="numeric"
                    maxlength="7"
                    v-model="code"
                    @keydown="onKeydown"
                    @focus="onFocus"
                    @blur="codeInputFocused = false"
                    autocomplete="one-time-code"
                    required
                    >
            </div>

            <h6 class="mt-4 mb-0">{{ $t('2fa.device-lost') }}</h6>
            <OButton @click="recoveryMode = true" class="p-0">{{ $t('2fa.use-recovery-code') }}</OButton>
        </div>

        <template #footer>
            <OButton @click="$refs.modal.close()" type="light">{{ $t('crud.cancel') }}</OButton>
            <OButton
                ref="confirmButton"
                @click="challenge()"
                type="primary"
                :disabled="!canSubmit">
                {{ $t('crud.ok') }}
            </OButton>
        </template>
    </OModal>
</template>
<script>
export default {
    props: ['$t', 'app'],

    data() {
        return {
            error: false,
            code: '',
            codeInputFocused: false,

            recoveryMode: false,
            recoveryCode: '',
        }
    },

    computed: {
        codeDigits() {
            return [null, null, null, null, null, null].map((digit, index) => {
                return this.code.charAt(index) || null
            })
        },

        canSubmit() {
            return (this.code.length >= 6 || this.recoveryCode.length > 0)
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.codeInput.focus()
        })
    },

    methods: {
        onKeydown(event) {
            if (event.key === 'Tab' || (event.key === 'v' && (event.metaKey || event.ctrlKey))) {
                return
            }

            if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
                event.preventDefault()
            }
        },

        onFocus(event) {
            this.codeInputFocused = true
            setTimeout(() => {
                event.target.setSelectionRange(6, 6)
            }, 50)
        },

        challenge() {
            if (!this.canSubmit) {
                return
            }

            this.error = false
            this.app.$axios.$post('../two-factor-challenge', this.recoveryMode ? { recovery_code: this.recoveryCode } : { code: this.code.replace(' ', '') })
                .then(() => {
                    this.$emit('confirm')
                })
                .catch(error => {
                    this.error = true
                    this.$emit('close', error.response.data)
                })
        },
    },

    watch: {
        code(code) {
            if (code.length >= 6) {
                this.challenge()
            }
        }
    }
}
</script>
<style scoped>
.code-container {
    position: relative;
    cursor: text;
}

.code-container:focus-within {
    border-color: var(--darkish);
    box-shadow: 0 0 0 4px var(--light);
}

.code-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
}
</style>