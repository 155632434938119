var render = function render(_c,_vm){return (_vm.props.to)?_c('RouterLink',{staticClass:"dropdown-item d-flex align-items-center",class:[
        _vm.props.active ? 'active' : null,
        _vm.props.disabled ? 'disabled' : null,
        _vm.data.class,
        _vm.data.staticClass
    ],attrs:{"to":_vm.props.to}},[(_vm.props.icon)?_c('OIcon',{staticClass:"mr-2 ml-n1",attrs:{"icon":['far', _vm.props.icon],"fixed-width":""}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.props.kbd)?_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"bg-light rounded px-2 py-1 shortcut shortcut-show ml-3"},[_vm._v(_vm._s(_vm.props.kbd))])]):_vm._e()],2):_c('a',{staticClass:"dropdown-item d-flex align-items-center",class:[
        _vm.props.active ? 'active' : null,
        _vm.props.disabled ? 'disabled' : null,
        _vm.data.class,
        _vm.data.staticClass
    ],attrs:{"role":!_vm.props.href && 'button',"href":_vm.props.href || 'javascript:;',"target":_vm.props.href ? '_blank' : null,"download":_vm.props.download ? 'download' : null},on:{"click":function($event){_vm.listeners['click'] ? _vm.listeners['click']($event) : null}}},[(_vm.props.icon)?_c('OIcon',{staticClass:"mr-2 ml-n1",class:_vm.props.iconPlacement === 'top' ? 'align-self-start mt-1' : null,attrs:{"icon":['far', _vm.props.icon],"fixed-width":""}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.props.kbd)?_c('div',{staticClass:"ml-auto"},[_c('span',{staticClass:"bg-light rounded px-2 py-1 shortcut shortcut-show ml-3"},[_vm._v(_vm._s(_vm.props.kbd))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }