<template functional>
	<nav 
		aria-label="breadcrumb" 
		class="d-none d-md-block"
		:class="[
			data.class,
			data.staticClass,
		]"
		>
		<ol class="breadcrumb">
            <slot />
		</ol>
	</nav>
</template>
<script>
export default {

}
</script>