<template>
    <OModal
        :title="`${$t('offer.offer')} · ${offer.name}`"
        @close="$emit('close')"
        @confirm="$emit('close')">

        <OLoading :loading="loadingState.default">
            <table v-if="offerLocal" class="table">
                <tbody>
                    <tr>
                        <th>{{ $t('offer.number') }}</th>
                        <td>{{ offer.number }}</td>
                    </tr>
                    <tr v-if="offer.company && offer.contact">
                        <th>{{ $t('offer.company') }}</th>
                        <td><a :href="`/company/${offer.company.id}`">{{ offer.company.name }} <span v-if="offer.contact.name">/ {{ offer.contact.name }}</span></a></td>
                    </tr>
                    <tr v-if="(offerLocal.sourced_invoices && offerLocal.sourced_invoices.length > 0)">
                        <th>{{ $t('offer.sourced-invoices') }}</th>
                        <td>
                            <ul class="pl-3 mb-0">
                                <li v-for="invoice in offerLocal.sourced_invoices" :key="invoice.id"><a :href="`/invoice/${invoice.id}`" :title="invoice.name">{{ invoice.number }}</a></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $t('vat.rate') }}</th>
                        <td>{{ offer.vat_rate }}%</td>
                    </tr>
                    <tr>
                        <th>{{ $t('offer.sent_at') }}</th>
                        <td v-if="offer.locked_at">{{ $formatDateTime(offer.locked_at) }}</td>
                        <td v-else>-</td>
                    </tr>
                    <tr>
                        <th>{{ $t('offer.approved_at') }}</th>
                        <td v-if="offer.approved_at">{{ $formatDateTime(offer.approved_at) }}</td>
                        <td v-else>-</td>
                    </tr>
                    <tr v-if="offerLocal.member">
                        <th>{{ $t('crud.assigned-to') }}</th>
                        <td class="text-nowrap">{{ offerLocal.member.name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t('crud.updated-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(offerLocal.updated_at) }} <span v-if="offerLocal.updated_by"> / {{ offerLocal.updated_by.name }}</span></td>
                    </tr>
                    <tr>
                        <th>{{ $t('crud.created-at') }}</th>
                        <td class="text-nowrap">{{ $formatDateTime(offerLocal.created_at) }} <span v-if="offerLocal.created_by"> / {{ offerLocal.created_by.name }}</span></td>
                    </tr>
                    <tr v-if="offer.deleted_at" class="text-danger">
                        <th>{{ $t('crud.deleted-at') }}</th>
                        <td>{{ $formatDateTime(offer.deleted_at) }}</td>
                    </tr>
                </tbody>
            </table>

        
            <o-timeline class="mt-3">
                <o-timeline-item 
                    v-for="(activity, index) in offerLocal.activities" 
                    :key="index"
                    :timestamp="activity.created_at" 
                    :type="activity.message"
                    :class="{ 
                        'text-success': activity.message === 'client-approved',
                        'text-danger': activity.message === 'client-rejected',
                        'text-warning': activity.message === 'sent',
                    }">
                    {{ $t('activity.' + activity.message, activity.message === 'project-assigned' ? { name: activity.metadata.project } : null) }} 
                    <span v-if="activity.user">{{ $t('activity.logged-by') }} {{ activity.user.fullName }}</span>
                </o-timeline-item>
            </o-timeline>
        </OLoading>

    </OModal>
</template>
<script>
import AvatarComponent from '~/components/avatar.vue'
import HasLoadingState from '~/mixins/hasLoadingState'

export default {
    mixins: [HasLoadingState],

    components: {
        AvatarComponent
    },

    props: ['offer', '$t'],

    data() {
        return {
            offerLocal: this.offer
        }
    },

    async created() {
        this.$startLoading()
        this.offerLocal = await this.$store.dispatch('offer/needOne', {
            id: this.offer.id,
            query: {
                include: ['member.user', 'createdBy.user', 'updatedBy.user', 'activities', 'sourcedInvoices'],
            }
        })

        this.$stopLoading()
    }
}
</script>