import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('fiscal_year', {
    include: [],
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    close({ commit }, fiscalYear) {
        return this.$axios.$post(`/fiscal_year/${fiscalYear.id}/close`).then(data => {
            commit('UPDATE_OR_ADD_ITEM', data)

            crudStore.options.onUpdate && crudStore.options.onUpdate.call(this, data, fiscalYear)

            return data
        })
    }
}