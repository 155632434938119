import { track as analyticsTrack } from '~/plugins/analytics'

export const state = () => {
    return {
        isOverlayOpen: false,
    }
}

export const mutations = {
    SET_IS_OVERLAY_OPEN(state, open) {
        state.isOverlayOpen = open
    },
}

export const actions = {
    toggleOverlay({ commit, state }) {
        commit('SET_IS_OVERLAY_OPEN', !state.isOverlayOpen)

        if (state.isOverlayOpen) {
            analyticsTrack('Command palette opened')
        }
    }
}