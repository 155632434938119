<template>
    <OModal
        :title="$t('offer.create-invoice')"
        :confirmText="$t('crud.ok')"
        :cancelText="$t('crud.cancel')"
        @close="$emit('close')"
        @confirm="$emit('confirm', { elements, invoice, splits })"
        size="lg">

        <o-tabs :tabs="[
                { label: $t('invoice.single'), name: 'single' },
                { label: $t('invoice.splits'), name: 'splits' }
            ]"
            @changed="onTabChanged($event)">
            <template #single>
                <div class="row">
                    <div class="col-6 col-md-3">
                        <ODatePicker :label="$t('invoice.issued_at')" v-model="invoice.issued_at" :options="{ altFormat: $t('app.datepicker-format'), minDate: 'today' }" required />
                    </div>
                    <div class="col-6 col-md-3">
                        <ODatePicker :label="$t('invoice.due_at')" v-model="invoice.due_at" :options="{ altFormat: $t('app.datepicker-format'), minDate: invoice.issued_at }" required />
                    </div>
                    <div class="col-12 col-md-6">
                        <OInput :label="$t('invoice.total')" :placeholder="offer.total" @input="updateTotal($event)" :prepend="$org.country.currency_code" />
                    </div>
                </div>

                <ORadio 
                    :label="$t('invoice.elements')" 
                    v-model="elements"
                    :options="[
                        { label: $t('invoice.according-to-offer'), value: 'summary' },
                        { label: $t('invoice.clone-elements'), value: 'detailed' },
                    ]" />
            </template>

            <template #splits>
                <div class="mt-1 mb-3">
                    <div v-for="(split, index) in splits" :key="index" class="row align-items-end mb-3">
                        <div class="col-6 col-md-3">
                            <ODatePicker :label="$t('invoice.issued_at')" v-model="split.issued_at" class="m-0" :options="{ minDate: 'today' }" required></ODatePicker>
                        </div>
                        <div class="col-6 col-md-3">
                            <ODatePicker :label="$t('invoice.due_at')" v-model="split.due_at" class="m-0" :options="{ minDate: split.issued_at }" required></ODatePicker>
                        </div>
                        <div class="col-10 col-md-5 mt-3 mt-md-0">
                            <OInput :label="$t('invoice.total')" v-model="split.total" :prepend="$org.country.currency_code" class="m-0"></OInput>
                        </div>
                        <div class="col-2 col-md-1 text-right">
                            <OButton type="light" icon="times" v-tooltip="$t('crud.delete')" @click="splits.splice(index, 1)" />
                        </div>
                        <div class="col-12 border-top d-md-none my-3"></div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <div class="d-flex" v-if="remainingAmount != 0">
                                <OInput :value="formatCurrency(remainingAmount)" :prepend="$org.country.currency_code" class="m-0 mr-2" :class="{ 'text-danger': remainingAmount < 0 }" disabled />
                                <OButton type="warning" @click="spreadRemaining()">{{ $t('invoice.spread-remaining-amount') }}</OButton>
                            </div>
                            <OBadge v-else icon="check" type="success">{{ $t('invoice.covered') }}</OBadge>
                        </div>
                        <div class="col-md-6 mt-3 mt-md-0 order-md-first">
                            <OButton v-if="remainingAmount > 0" type="darkish" @click="addSplit()" icon="plus">{{ $t('invoice.new-split') }}</OButton>
                        </div>
                    </div>
                </div>
            </template>
        </o-tabs>
        
    </OModal>
</template>
<script>
import { format, add } from 'date-fns'
export default {
    props: ['offer', '$t'],

    data() {
        return {
            elements: 'summary',
            invoice: {
                issued_at: format(new Date(), 'yyyy-MM-dd'),
                due_at: format(add(new Date(), { days: 30 }), 'yyyy-MM-dd')
            },
            splits: []
        }
    },

    methods: {
        updateTotal(value) {
            if (value.length > 0) {
                this.$set(this.invoice, 'total', value)
            } else {
                this.$delete(this.invoice, 'total')
            }
        },
        
        addSplit() {
            this.splits.push({
                issued_at: format(new Date(), 'yyyy-MM-dd'),
                due_at: format(add(new Date(), { days: 30 }), 'yyyy-MM-dd'),
                total: 0,
            })
        },

        formatCurrency(value) {
            if (!value) {
                value = 0
            }
            
            if (typeof value === 'string') {
                value = parseFloat(value)
            }

            return value.toLocaleString('de-CH', { minimumFractionDigits: 2 })
        },

        spreadRemaining() {
            const perSplit = parseFloat((this.remainingAmount / this.splits.length).toFixed(2))
            this.splits.forEach(split => split.total = parseFloat(split.total) + perSplit)
        },

        onTabChanged(tab) {
            if (tab === 'single') {
                this.splits = []
            } else {
                this.addSplit()
            }
        }
    },

    computed: {
        computedTotal() {
            return parseFloat(this.invoice.total || this.offer.total)
        },

        remainingAmount() {
            return parseFloat((this.computedTotal - this.spentAmount).toFixed(2))
        },

        spentAmount() {
            return this.splits.reduce((prev, cur) => {
                return prev + parseFloat(cur.total || 0)
            }, 0)
        }
    }
}
</script>