import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('journal_transaction', {
    include: ['entries.account', 'entries.vatRate', 'document'],
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = crudStore.actions()