<template>
    <OFormGroup
        :label="label"
        :helpText="helpText"
        v-bind="$attrs"
        >
        <div class="btn-group" :class="{ 'd-flex': block }">
            <OButton
                v-for="(option, index) in options"
                :key="option[valueField] || index"
                @click="$emit('input', option[valueField])"
                :icon="option.icon"
                :icon-after="option.iconAfter"
                :type="option[valueField] === value ? activeType : defaultType"
                :disabled="disabled || option.disabled"
                :size="size"
                :class="{ 'flex-1': block }"
                v-tooltip="iconsOnly ? option[labelField] : (option[tooltipField] || null)"
                >
                <slot v-if="!iconsOnly">
                    {{  option[labelField] }}
                </slot>
            </OButton>
        </div>
    </OFormGroup>
</template>
<script>
import OFormGroup from './Group.vue'

export default {
    components: {
        OFormGroup,
    },

    props: {
        value: {
            default: null,
        },
        label: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        labelField: {
            type: String,
            default: 'label'
        },
        valueField: {
            type: String,
            default: 'value'
        },
        tooltipField: {
            type: String,
            default: 'description'
        },
        activeType: {
            type: String,
            default: 'light bg-light'
        },
        defaultType: {
            type: String,
            default: 'light bg-transparent text-muted'
        },
        size: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: String,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        },
        iconsOnly: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        id() {
            return this.name || this.label || parseInt(Math.random() * 1000)
        }
    }
}
</script>
