<template functional>
    <RouterLink v-if="props.to" 
        class="dropdown-item d-flex align-items-center" 
        :class="[
            props.active ? 'active' : null,
            props.disabled ? 'disabled' : null,
            data.class,
            data.staticClass
        ]" 
        :to="props.to">
        <OIcon v-if="props.icon" :icon="['far', props.icon]" fixed-width class="mr-2 ml-n1"></OIcon>
        <slot></slot>
        <div v-if="props.kbd" class="ml-auto">
            <span class="bg-light rounded px-2 py-1 shortcut shortcut-show ml-3">{{ props.kbd }}</span>
        </div>
    </RouterLink>
    <a v-else 
        :role="!props.href && 'button'"
        :href="props.href || 'javascript:;'" 
        :target="props.href ? '_blank' : null" 
        class="dropdown-item d-flex align-items-center"
        @click="listeners['click'] ? listeners['click']($event) : null"
        :download="props.download ? 'download' : null"
        :class="[
            props.active ? 'active' : null,
            props.disabled ? 'disabled' : null,
            data.class,
            data.staticClass
        ]">
        <OIcon v-if="props.icon" :icon="['far', props.icon]" fixed-width class="mr-2 ml-n1" :class="props.iconPlacement === 'top' ? 'align-self-start mt-1' : null"></OIcon>
        <slot></slot>
        <div v-if="props.kbd" class="ml-auto">
            <span class="bg-light rounded px-2 py-1 shortcut shortcut-show ml-3">{{ props.kbd }}</span>
        </div>
    </a>
</template>
<script>
export default {
    props: {
        to: {
            type: [String, Object],
            default: null
        },
        href: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null,
        },
        iconPlacement: {
            type: String,
            default: null,
        },
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        kbd: {
            type: String,
            default: null,
        },
        download: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
