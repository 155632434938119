<template functional>
    <div class="card" 
        :class="[{ 
            'theme-dark': props.dark, 
            'card-invisible': props.invisible, 
            'card-action': props.clickable, 
            'card-stretch': props.fullHeight,
            'card-disabled': props.disabled,
        }, data.class, data.staticClass]"
        :style="[data.style, data.staticStyle]"
        :tabindex="props.clickable ? '0' : null"
        :role="props.clickable && 'button'"
        v-bind="data.attrs"
        v-on="listeners"
        >
        <img v-if="props.image" :src="props.image" class="card-img-top">
        <div v-if="slots().header" class="card card-body px-3 mx-n1 mx-md-0" :class="[{ 'card-sticky': props.stickyHeader }, props.headerClass]">
            <div class="d-flex align-items-center justify-content-end">
                <slot name="header"></slot>
            </div>
        </div>
        <div v-if="props.title"  class="card-header">
            <div class="h5 m-0">{{ props.title }}</div>
            <div class="text-muted mw-prose">{{ props.subtitle }}</div>
        </div>
        <div class="card-body" :class="[props.bodyClass, { 'pb-0': props.noPaddingBottom }]">
            <slot></slot>
        </div>
        <div v-if="slots().footer" class="card-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        bodyClass: {
            type: String,
            default: null,
        },
        headerClass: {
            type: [String, Array, Object],
            default: null,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        noPaddingBottom: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false
        },
        stickyHeader: {
            type: Boolean,
            default: false
        },
        invisible: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: false
        },
        collapsible: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
