<template>
    <CrudListData resource="vat_rate" v-slot="{ items: vat_rates }">
        <OSearch 
            :name="name"
            :label="hideLabel ? null : (label || $t('vat_rate.vat-rate'))" 
            :value="value"
            :select-first="selectFirst"
            :selected-label="option => `${option.rate}% – ${option.name}`"
            @input="event => {
                $emit('input', event)
                $emit('selected', vat_rates.find(c => c.id == event))
            }"
            :options="vat_rates.filter(typeFilter).filter(validFilter).map(i => ({ 
                ...i,
                label: `${i.name} (${i.rate}%)`, 
                value: i.id, 
            }))"
            :disabled="disabled || vat_rates.length == 0"
            :required="required"
            >
            <template #default="{ option }">
                <div class="d-flex align-items-center gap-3">
                    <div>
                        <div>{{ option.name }}</div>
                        <div class="text-muted">{{ option.description }}</div>
                    </div>
                    <div class="ml-auto my-0 font-weight-semibold text-right tabular-nums">{{ option.rate }}%</div>
                </div>
            </template>
        </OSearch>
    </CrudListData>
</template>
<script>
import CrudListData from '~/components/crud/list-data.vue'
import AvatarComponent from '~/components/avatar.vue'
import { isBefore, isAfter, parseISO, isSameDay } from 'date-fns'

export default {
    components: {
        CrudListData,
        AvatarComponent
    },

    props: {
        value: {
            type: [Number, String],
            default: null
        },

        init: {
            type: [Number, String],
            default: null
        },

        label: {
            type: String,
            default: null
        },

        name: {
            type: String,
            default: null
        },

        validFor: {
            type: [Date, String],
            default: null,
        },

        type: {
            type: String,
            default: null,
        },

        required: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        selectFirst: {
            type: Boolean,
            default: true
        },

        hideLabel: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        typeFilter(vat) {
            return !this.type || vat.vat_type === this.type
        },

        validFilter(vat) {
            const validFor = typeof this.validFor === 'string'
                ? parseISO(this.validFor)
                : (this.validFor || new Date())

            const vatValidFrom = parseISO(vat.valid_from)
            const vatValidUntil = parseISO(vat.valid_until)

            return (
                (!vat.valid_from || isAfter(validFor, vatValidFrom) || isSameDay(validFor, vatValidFrom)) &&
                (!vat.valid_until || isBefore(validFor, vatValidUntil) || isSameDay(validFor, vatValidUntil))
            )
        }
    }
}
</script>