export const state = () => {
    return {
        toasts: [],
    }
}

export const mutations = {
    addToast(state, toast) {
        toast.id = new Date().valueOf()
        state.toasts.unshift(toast)
    },

    removeToast(state, toast) {
        const toastIndex = state.toasts.indexOf(toast)
        if (toastIndex) {
            state.toasts.splice(toastIndex, 1)
        } else {
            state.toasts = []
        }
    },
}

export const actions = {
    push({ commit, state }, toast) {
        commit('addToast', toast)
    },

    dismiss({ commit }, toast) {
        commit('removeToast', toast)
    }
}