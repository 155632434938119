<template>
        <NuxtLink v-bind="$attrs" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
            <li class="nav-item" :class="[isActive && 'active', isExactActive && 'exact-active']">
                <a :href="href" @click="navigate" class="nav-link" :aria-current="isActive && 'page'">
                    <OIcon v-if="icon" :icon="[iconPrefix, icon]" fixed-width :class="{ 'mr-1': $slots.default }"></OIcon>
                    <slot />
                </a>
            </li>
        </NuxtLink>
</template>
<script>
export default {
    inheritAttrs: false,
    props: {
        icon: {
            type: String,
            default: null,
        },
        iconPrefix: {
            type: String,
            default: 'far',
        },
    }
}
</script>