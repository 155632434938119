var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OFormGroup',{attrs:{"label":_vm.label === false ? null : (_vm.label || _vm.$t('tag.tags')),"help-text":_vm.helpText}},[_c('OCombobox',{ref:"combobox",attrs:{"options":_vm.tags,"label":"name","multiple":!_vm.single,"taggable":_vm.allowCreate,"loading":_vm.loadingState.default,"create-option":name => ({ 
            name,
            group: name.includes('_') ? { 
                name: name.split('_')[0],
                value: name.split('_')[1],
            } : null,
        }),"value":_vm.normalizedValue,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)},"option:created":_vm.onOptionCreated},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"selected-option-container",fn:function({ option, deselect, multiple, disabled }){return [_c('OTag',{attrs:{"tag":option,"removeable":""},on:{"delete":function($event){return deselect(option)}}})]}},{key:"option",fn:function(option){return [(option.id)?_c('div',{staticClass:"d-flex align-items-center mx-n2"},[_c('OTag',{attrs:{"tag":option}}),_vm._v(" "),_c('a',{staticClass:"ml-auto mr-n2",attrs:{"href":`/tag/${option.id}/edit`},on:{"click":function($event){$event.stopPropagation();}}},[_c('OButton',{directives:[{name:"tooltip",rawName:"v-tooltip:right",value:(_vm.editTagText || _vm.$t('crud.edit')),expression:"editTagText || $t('crud.edit')",arg:"right"}],attrs:{"type":"link","icon":"cog","size":"sm"}})],1)],1):_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('span',[_vm._v(_vm._s(_vm.createTagText || _vm.$t('crud.create')))]),_vm._v(" "),_c('OTag',{attrs:{"tag":option}})],1)]}},{key:"open-indicator",fn:function({ attributes }){return [_c('span',_vm._b({},'span',attributes,false),[_c('OIcon',{attrs:{"icon":['far', 'angle-down']}})],1)]}},{key:"no-options",fn:function({ search, searching, loading }){return [(_vm.tags.length === 0)?_c('span',[_vm._v(_vm._s(_vm.noTagsText || _vm.$t('tag.type-to-create')))]):_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.noTagsText || _vm.$t('tag.no-tags-selectable')))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }