<template>
  <OLoading :loading="loading && !hideLoadingBar">
    <slot
      :items="items"
      :meta="meta"
      :fetchData="fetchData"
      :loading="loadingState"
      :updateItem="updateItem"
      :destroyItem="destroyItem"
      :cloneItem="cloneItem"
      :updateOrder="updateOrder"
      :goToPage="goToPage"
      :selection="selection"
      :isSelected="isSelected"
      :selectItem="selectItem"
      :allowSelection="false"
      :showLink="(item) => $can(`show ${resource}`) ? `/${resource}/${item.id}` : '/'"
      :editLink="(item) => $can(`edit ${resource}`) ? `/${resource}/${item.id}/edit` : '/'"
      :editItem="editItem"
      :storeItem="storeItem"
      :createItem="createItem"
      :isCreatingItem="isCreatingItem"
    />

    <Portal 
      v-if="editingItem" 
      :title="isCreatingItem 
          ? ($te(resource + '.create') ? $t(resource + '.create') : $t('crud.create')) 
          : ($te(resource + '.edit') ? $t(resource + '.edit') : $t('crud.edit'))"
      :prehead="isCreatingItem ? $t(resource + '.' + resource) : (editingItem.name || $t(resource + '.' + resource))"
      v-slot="{ close }"
      @close="editItem(null)"
      >
      <PortalForm
          :init="editingItem" 
          :on-save="(data) => isCreatingItem 
              ? storeItem(data) 
              : updateItem(editingItem, data)" 
          @saved="close()"
          @cancel="close()"
          v-slot="{ item }"
          >
          <slot 
              name="portal" 
              :item="item" 
              :original-item="editingItem" 
              :is-creating="isCreatingItem"
              />
      </PortalForm>
  </Portal>
  </OLoading>
</template>
<script>
import HasLoadingState from '~/mixins/hasLoadingState'
import Portal from '~/components/portal.vue'
import PortalForm from '~/components/portal-form.vue'

export default {
  mixins: [HasLoadingState],

  components: {
    Portal,
    PortalForm,
  },

  props: {
    resource: {
      type: String,
      required: true
    },

    deferLoading: {
      type: Boolean,
      default: false
    },

    reloadOnActivated: {
      type: Boolean,
      default: false
    },

    filters: {
      type: Object,
      default: () => {}
    },

    sortBy: {
      type: String,
      default: null
    },

    include: {
      type: Array,
      default: null
    },

    append: {
      type: Array,
      default: null
    },

    transform: {
      type: Array,
      default: null
    },

    perPage: {
      type: [Number, Boolean]
    },

    hideLoadingBar: {
      type: Boolean,
      default: false
    },

    createInPortal: {
        type: [Boolean, Object],
        default: false
    },
  },

  data() {
    return {
      loading: false,
      page: 1,
      selection: [],
      editingItem: null,
    }
  },

  created() {
    if (!this.deferLoading) {
      this.fetchData()
    }
  },

  activated() {
    if (!this.deferLoading && this.reloadOnActivated) {
      this.fetchData()
    }
  },

  methods: {
    fetchData(query = {}) {
      this.loading = true
      this.$store
        .dispatch(this.resource + '/needAll', {
          page: this.page,
          filters: this.filters,
          sort: this.sortBy,
          perPage: this.perPage,
          include: this.include,
          append: this.append,
          transform: this.transform,
          ...query
        })
        .then(({ data }) => {
          this.loading = false
          this.$emit('loaded', data)
        })
    },

    async destroyItem(item, name = null, confirm = true) {
      if (confirm) {
        await this.$modal({
          title: name || this.$t('crud.delete'),
          message: this.$t('crud.delete-confirm', { name }),
          type: 'danger'
        })
      }

      await this.$store.dispatch(this.resource + '/destroy', item)
      return this.fetchData()
    },

    updateItem(item, changes, notify = false) {
      const change = {
        id: item.id,
        ...changes
      }

      this.$startLoading(item.id)
      return this.$store
        .dispatch(this.resource + '/update', change)
        .then(() => {
          this.$stopLoading(item.id)

          if (notify) {
            this.$store.dispatch('toast/push', {
              title: item.name || this.$t('crud.updated'),
              message: this.$t('crud.updated-text'),
              type: 'success'
            })
          }
        })
    },

    storeItem(item) {
        this.$startLoading()
        return this.$store.dispatch(this.resource + '/store', item).then(data => {
            this.editItem(null)
        }).finally(() => {
            this.$stopLoading()
        })
    },

    cloneItem(item) {
      this.$startLoading(item.id)
      return this.$store.dispatch(this.resource + '/clone', item).then(clone => {
        this.$stopLoading(item.id)
        return clone
      })
    },

    goToPage(page = 1) {
      this.page = page
      this.fetchData()
    },

    async updateOrder(items) {
      await this.$store.dispatch(this.resource + '/sort', items)
    },

    isSelected(item) {
      return false
    },

    selectItem(item) {
      return false
    },

    createItem(data) {
        this.editingItem = data
    },

    editItem(item) {
        this.editingItem = item

        if (!item && this.$route.hash === '#create') {
            this.$router.replace({ hash: null })
        }
    },
  },

  computed: {
    items() {
      return this.$store.state[this.resource].items
    },

    meta() {
      return this.$store.state[this.resource].meta
    },

    isCreatingItem() {
        return this.editingItem 
            && (!this.editingItem.hasOwnProperty('id') || this.editingItem.id === null)
    }
  },

  watch: {
    items() {
      this.$emit('items-changed', this.items)
    }
  }
}
</script>