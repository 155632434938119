var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OModal',{attrs:{"title":_vm.$t('bank_transaction.match-manually'),"size":"lg","icon":"search","allow-overflow":""},on:{"confirm":() => _vm.$emit('confirm', _vm.updatedTransaction)}},[_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap gap-3"},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t('bank_transaction.booking_date')))]),_vm._v(" "),_c('dd',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$formatDate(_vm.transaction.booking_date)))])]),_vm._v(" "),_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t('crud.description')))]),_vm._v(" "),_c('dd',[_vm._v(_vm._s(_vm.transaction.text))])]),_vm._v(" "),_c('dl',{staticClass:"text-lg-right ml-lg-auto text-nowrap"},[_c('dt',[_vm._v(_vm._s(_vm.$t('bank_transaction.amount')))]),_vm._v(" "),_c('dd',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.transaction.currency)+" "+_vm._s(_vm.$formatCurrency(_vm.transaction.amount)))])])]),_vm._v(" "),_c('hr',{staticClass:"mx-n3"}),_vm._v(" "),_c('div',{staticClass:"bg-ultralight p-3 m-n3"},[_c('OTabs',{attrs:{"tabs":[
                { name: 'invoice', label: _vm.$t('invoice.invoices'), icon: 'file-invoice-dollar', show: _vm.transaction.amount >= 0 },
                { name: 'manual', label: _vm.$t('accounting.manual-entry'), icon: 'book', show: _vm.$org.settings.accountingEnabled && _vm.$org.accounts_count > 0 && _vm.bankAccount?.account },
                { name: 'expense_payment', label: _vm.$t('expense.type-invoice'), icon: 'file-invoice-dollar', show: _vm.transaction.amount <= 0 },
                { name: 'expense_receipt', label: _vm.$t('expense.type-receipt'), icon: 'receipt', show: _vm.transaction.amount <= 0 },
                { name: 'customer_transaction', label: _vm.$t('customer_transaction.customer_transaction'), icon: 'users' },
            ]},scopedSlots:_vm._u([{key:"invoice",fn:function(){return [_c('CrudListData',{attrs:{"resource":"invoice","filters":{ status: 'pending' }},scopedSlots:_vm._u([{key:"default",fn:function({ items: invoices }){return [_c('OSearch',{staticClass:"mb-0",attrs:{"placeholder":_vm.$t('crud.search') + ' ...',"options":invoices.map(invoice => ({
                            label: `${invoice.number} / ${invoice.company?.name}`,
                            value: invoice.id,
                            invoice,
                        })),"value":_vm.updatedTransaction.matchable_id},on:{"input":event => {
                            _vm.updatedTransaction.matchable_id = event
                            _vm.updatedTransaction.matchable_type = 'Modules\\Salesforce\\Invoice'
                        }},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return [_c('div',{staticClass:"d-flex gap-3"},[_c('div',[_vm._v(_vm._s(option.label))]),_vm._v(" "),_c('div',{staticClass:"text-right ml-auto tabular-nums text-muted"},[_vm._v("\n                                    "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(option.invoice.total_open))+"\n                                ")])])]}}],null,true)}),_vm._v(" "),(_vm.updatedTransaction.matchable_id)?_c('OAlert',{staticClass:"mt-2 mb-0",attrs:{"type":"warning"}},[_c('PipeThrough',{attrs:{"value":invoices.find(invoice => invoice.id === _vm.updatedTransaction.matchable_id).total_open},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [(_vm.transaction.amount > value)?_c('div',[_vm._v(_vm._s(_vm.$t('reconciliation.amount-larger'))+": "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(_vm.transaction.amount - value)))]):(_vm.transaction.amount < value)?_c('div',[_vm._v(_vm._s(_vm.$t('reconciliation.amount-lower'))+": "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(value - _vm.transaction.amount)))]):_vm._e()]}}],null,true)})],1):_vm._e()]}}])})]},proxy:true},{key:"expense_payment",fn:function(){return [_c('CrudListData',{attrs:{"resource":"expense_payment","filters":{ status: 'pending' },"include":['expense']},scopedSlots:_vm._u([{key:"default",fn:function({ items: expensePayments }){return [_c('OSearch',{staticClass:"mb-0",attrs:{"placeholder":_vm.$t('crud.search') + ' ...',"options":expensePayments.map(payment => ({
                            label: `${payment.expense?.number} / ${payment.expense?.name}`,
                            value: payment.id,
                            payment,
                        })),"value":_vm.updatedTransaction.matchable_id},on:{"input":event => {
                            _vm.updatedTransaction.matchable_id = event
                            _vm.updatedTransaction.matchable_type = 'App\\ExpensePayment'
                        }},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return [_c('div',{staticClass:"d-flex gap-3"},[_c('div',[_c('div',[_vm._v(_vm._s(option.label))]),_vm._v(" "),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t(`expense.bank-payment-types.${option.payment.bank_payment_type}`)))])]),_vm._v(" "),_c('div',{staticClass:"text-right ml-auto tabular-nums text-muted"},[_vm._v("\n                                    "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(option.payment.amount))+"\n                                ")])])]}}],null,true)}),_vm._v(" "),(_vm.updatedTransaction.matchable_id)?_c('OAlert',{staticClass:"mt-2 mb-0",attrs:{"type":"warning"}},[_c('PipeThrough',{attrs:{"value":expensePayments.find(payment => payment.id === _vm.updatedTransaction.matchable_id).amount},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [(Math.abs(_vm.transaction.amount) > value)?_c('div',[_vm._v(_vm._s(_vm.$t('reconciliation.amount-larger'))+": "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(Math.abs(_vm.transaction.amount) - value)))]):_c('div',[_vm._v(_vm._s(_vm.$t('reconciliation.amount-lower'))+": "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(value - Math.abs(_vm.transaction.amount))))])]}}],null,true)})],1):_vm._e()]}}])})]},proxy:true},{key:"expense_receipt",fn:function(){return [_c('CrudListData',{attrs:{"resource":"expense","filters":{ type: 'receipt', total_range: Math.abs(_vm.transaction.amount) + ',' + Math.abs(_vm.transaction.amount) }},scopedSlots:_vm._u([{key:"default",fn:function({ items: expenses }){return [_c('OSearch',{staticClass:"mb-0",attrs:{"placeholder":_vm.$t('crud.search') + ' ...',"options":expenses.map(expense => ({
                            label: `${expense.number} / ${expense.name}`,
                            value: expense.id,
                            expense,
                        })),"value":_vm.updatedTransaction.matchable_id},on:{"input":event => {
                            _vm.updatedTransaction.matchable_id = event
                            _vm.updatedTransaction.matchable_type = 'Modules\\Base\\Expense'
                        }},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return [_c('div',{staticClass:"d-flex gap-3"},[_c('div',[_c('div',[_vm._v(_vm._s(option.label))]),_vm._v(" "),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(option.expense.number))])]),_vm._v(" "),_c('div',{staticClass:"text-right ml-auto tabular-nums text-muted"},[_vm._v("\n                                    "+_vm._s(_vm.$org.country.currency_code)+" "+_vm._s(_vm.$formatCurrency(option.expense.total_with_vat))+"\n                                ")])])]}}],null,true)})]}}])})]},proxy:true},{key:"manual",fn:function(){return [_c('div',{staticClass:"row mb-n3"},[_c('div',{staticClass:"col-6"},[_c('AccountSelect',{attrs:{"label":_vm.$t('account.account'),"type":_vm.transaction.amount >= 0 ? 'revenue' : 'expense',"hidden-type":['active', 'passive', 'revenue', 'expense'],"use-id":"","select-first":"","autofocus":""},model:{value:(_vm.updatedTransaction.account_id),callback:function ($$v) {_vm.$set(_vm.updatedTransaction, "account_id", $$v)},expression:"updatedTransaction.account_id"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('VatSelect',{attrs:{"label":_vm.$t('vat.vat')},model:{value:(_vm.updatedTransaction.vat_rate_id),callback:function ($$v) {_vm.$set(_vm.updatedTransaction, "vat_rate_id", $$v)},expression:"updatedTransaction.vat_rate_id"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('OInput',{attrs:{"label":_vm.$t('crud.description')},model:{value:(_vm.updatedTransaction.text),callback:function ($$v) {_vm.$set(_vm.updatedTransaction, "text", $$v)},expression:"updatedTransaction.text"}})],1)])]},proxy:true},{key:"customer_transaction",fn:function(){return [_c('div',{staticClass:"row mb-n3"},[_c('div',{staticClass:"col-6"},[_c('CompanySelect',{attrs:{"label":_vm.$t('company.company'),"value":_vm.updatedTransaction.matchable_id},on:{"input":event => {
                                _vm.updatedTransaction.matchable_id = event
                                _vm.updatedTransaction.matchable_type = 'Modules\\Client\\Company'
                            }}})],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('OInput',{attrs:{"label":_vm.$t('crud.description')},model:{value:(_vm.updatedTransaction.text),callback:function ($$v) {_vm.$set(_vm.updatedTransaction, "text", $$v)},expression:"updatedTransaction.text"}})],1)])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }