import Vue from 'vue'
import UIComponents from '~/components/ui'

import Logo from '~/components/logo.vue'
import OCombobox from 'vue-select'

Vue.component('StrawblondLogo', Logo)
Vue.component('OCombobox', OCombobox)

Object.keys(UIComponents).forEach(name => {
    Vue.component(name, UIComponents[name])
})