export const state = () => {
    return {
        loading: false,
        accounts: [],
    }
}

export const getters = {
    accountsByType: (state) => (type) => {
        return state.accounts.filter((account) => account.type === type)
    }
}

export const mutations = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts
    },

    SET_LOADING(state, loading) {
        state.loading = loading
    }
}

export const actions = {
    async fetchAccounts({ commit, state }, params = {}) {
        if (state.loading) {
            return
        }

        if (state.accounts.length === 0) {
            commit('SET_LOADING', true)
        }

        const { data } = await this.$axios.$get('account', {
            params
        })
        commit('SET_ACCOUNTS', data)
        commit('SET_LOADING', false)
    }
}