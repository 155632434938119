<template>
    <CrudListData resource="company" :filters="{ is_archived: includeArchived ? null : false }" :per-page="null" v-slot="{ items: companys }">
        <OSearch 
            :label="label || $t('company.company')" 
            :value="value || init"
            :null-option="nullOption"
            @input="$emit('input', $event)"
            :options="companys.map(company => ({ 
                label: company.name, 
                value: company.id,
                ...company
            }))"
            :required="required">
            <template #default="{ option }">
                <div class="d-flex align-items-center" :class="{ 'text-muted': option.is_archived }">
                    <AvatarComponent 
                        :image="option.avatar" 
                        :fallback="option.name"
                        size="sm"
                        class="flex-shrink-0 mr-3" />
                    <div>
                        <div>{{ option.name }}</div>
                        <div v-if="option.type" class="text-muted">{{ option.type.name }}</div>
                    </div>
                    <OBadge v-if="option.is_archived" type="light" class="ml-auto">{{ $t('crud.archived') }}</OBadge>
                </div>
            </template>
        </OSearch>
    </CrudListData>
</template>
<script>
import CrudListData from '~/components/crud/list-data.vue'
import AvatarComponent from '~/components/avatar.vue'

export default {
    components: {
        CrudListData,
        AvatarComponent
    },

    props: {
        value: {
            type: [Number, String],
            default: null
        },

        init: {
            type: [Number, String],
            default: null
        },

        label: {
            type: String,
            default: null
        },

        required: {
            type: Boolean,
            default: false
        },

        includeArchived: {
            type: Boolean,
            default: false
        },

        nullOption: {
            type: String,
            default: null
        },
    }
}
</script>