<template>
    <OFormModal
        :title="$t('payments.create')"
        :subtitle="`${invoice.number} ${invoice.name ? '· ' + invoice.name : ''}`"
        icon="plus"
        @close="$emit('close')"
        @confirm="$emit('confirm', payment)"
        :cancel-text="$t('crud.cancel')"
        :confirm-text="$t('payments.create')"
        confirm-icon="plus"
        allow-overflow
        >
        <div class="row">
            <div class="col-12 col-md-6">
                <ODatePicker
                    :label="$t('crud.date')"
                    v-model="payment.executed_at"
                    :options="{ minDate: invoice.issued_at }"
                    required
                    />
            </div>
            <div class="col-12 col-md-6">
                <OCurrencyInput
                    ref="amountInput"
                    :label="$t('payments.amount')"
                    v-model="payment.amount"
                    required
                    autofocus
                    />
            </div>
            <div v-if="invoice.total_open < payment.amount" class="col-12">
                <OAlert 
                    :title="$t('payments.amount-too-high')"
                    type="info"
                    >
                    {{ $t('payments.amount-too-high-text', {
                        diff: $formatCurrency(payment.amount - invoice.total_open),
                    }) }}
                </OAlert>
            </div>
            <div v-if="$org.bank_accounts_count > 0" class="col-12">
                <BankAccountSelect
                    :label="$t('payments.bank-account')"
                    :help-text="$t('payments.bank-account-text')"
                    v-model="payment.bank_account_id"
                    select-first
                    />
            </div>
            <div class="col-12">
                <OInput
                    :label="$t('payments.message')"
                    v-model="payment.message"
                    :placeholder="`${$t('invoice.invoice')} ${invoice.number}`"
                    />
            </div>
        </div>

        <div v-if="invoice.payments && invoice.payments.length > 0" class="mb-3">
            <h6 class="mb-1">{{ $t('payments.past-payments') }}</h6>
            <div>{{ $org.country.currency_code }} {{ $formatCurrency(invoice.total_paid) }} / <span class="text-muted">{{ $formatCurrency(invoice.total) }}</span></div>
            <OProgress 
                :value="invoice.total_paid"
                :max-value="invoice.total"
                type="info"
                />
        </div>

    </OFormModal>
</template>
<script>
import BankAccountSelect from '~/components/bank-account/select.vue'
export default {
    components: {
    BankAccountSelect,
},

    props: ['invoice', '$t'],

    data() {
        return {
            payment: {
                executed_at: this.$dateForDB('now'),
                amount: this.invoice.total_open,
                message: null,
                bank_account_id: this.invoice.bank_account_id,
            }
        }
    },
}
</script>