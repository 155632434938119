import Vue from 'vue'

Vue.mixin({
    methods: {
        $can(permission) {
            // Need at least one permission
            if (permission.indexOf('|') !== -1) {
                const permissions = permission.split('|')
                return permissions.some(p => this.$store.getters['user/hasPermissionTo'](p))
            }

            // Needs all permissions
            if (permission.indexOf(',') !== -1) {
                const permissions = permission.split(',')
                return permissions.every(p => this.$store.getters['user/hasPermissionTo'](p))
            }

            // Need a single permission
            return this.$store.getters['user/hasPermissionTo'](permission)
        },

        $onPlan(plan) {
            // Allow trialing and legacy plan users
            if (!this.$org || !this.$org.subscription || this.$org.is_on_trial || this.$org.subscription.stripe_plan === 'legacy') {
                return true
            }

            // Need at least one plan
            if (plan.indexOf('|') !== -1) {
                const plans = plan.split('|')
                return plans.some(p => this.$org.subscription.stripe_plan === p)
            }

            // Need a single plan
            return this.$org.subscription.stripe_plan === plan
        },

        $hasAddon(addon) {
            // Allow trialing and legacy plan users
            if (!this.$org || !this.$org.subscription || this.$org.is_on_trial || this.$org.subscription.stripe_plan === 'legacy') {
                return true
            }

            return this.$org.addons.includes(addon)
        }
    }
})