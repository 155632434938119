<template>
    <div class="min-vh-100 bg-white d-flex flex-column justify-content-between">
        <div class="bokeh-bubbles">
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
        </div>

        <div class="row no-gutters flex-grow-1">
            <div class="position-relative col-12 col-lg-6 col-xl-7 d-flex align-items-center py-5 px-3 px-md-5" style="background: black;">
                <img 
                    src="/strawblond-gyst-hero.jpg" 
                    alt="StrawBlond Hero" 
                    class="position-absolute inset-0 w-100 h-100 object-cover" 
                    style="opacity: 15%;"
                    >
                <!-- <video 
                    src="https://player.vimeo.com/progressive_redirect/playback/792236857/rendition/1080p/file.mp4?loc=external&signature=8fbdcb9a0955b3746c8c0d2ca93128614808b8a2aaa03e4a6426d16dc80cc106"
                    class="position-absolute inset-0 w-100 h-100 object-cover" 
                    style="opacity: 25%;"
                    autoplay
                    loop
                    muted
                    >
                </video> -->

                <div class="position-relative mx-auto w-100" style="max-width: 600px;">
                    <StrawblondLogo style="width: 10rem;" class="text-white-real mb-5" full />
                    <div class="h1 text-white-real mb-5">Katapultiere dein Business mit StrawBlond in die Zukunft! <span class="d-none d-lg-inline text-muted opacity-50">Digitale Offerten, QR&#8209;Rechnung, Zeiterfassung, Projekte, Leistungen, Produkte, Ausgaben, Belegscanner, Absenzen, Buchhaltung und vieles mehr.</span></div>
                    <div class="d-lg-none text-muted mt-n5">Digitale Offerten, QR&#8209;Rechnung, Zeiterfassung, Projekte, Leistungen, Produkte, Ausgaben, Belegscanner, Absenzen, Buchhaltung und vieles mehr.</div>
                    
                    <div class="d-none d-lg-block">
                        <div class="h2 text-real-primary mb-0">Ab CHF 19/Monat</div>
                        <div class="small text-muted">Exkl. 8.1% MWST</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-5 d-flex align-items-center py-5 px-3 px-md-4 px-hd-5">
                <div class="mx-auto w-100" style="max-width: 600px;">
                    <Nuxt />
                </div>
            </div>
        </div>
        
        <ToastContainerComponent />
    </div>
</template>
<script>
import ToastContainerComponent from '~/components/toast-container.vue'
export default {
    middleware: ['outdatedBrowser'],

    auth: 'guest',

    components: {
        ToastContainerComponent
    },

    mounted() {
        this.$setupGoogleAnalyticsScript()
        this.$setupLinkedInTrackingScript()
    },
}
</script>