<template>
    <Portal 
        v-if="open" 
        :init-open="true"
        :title="$t('notification.notifications')"
        @close="$emit('close')"
        hide-divider
        >
        <div class="px-3 px-lg-4 mt-n3">
            <ONavTabs borderless class="mx-n3">
                <ONavTabsItem @click="type = 'unread'" :active="type === 'unread'">{{ $t('notification.unread') }} <OBadge type="primary" class="ml-1">{{ $store.state.notification.unread.length }}</OBadge></ONavTabsItem>
                <ONavTabsItem @click="type = 'archived'" :active="type === 'archived'">{{ $t('notification.archived') }}</ONavTabsItem>
            </ONavTabs>
        </div>
        <div class="d-flex flex-column flex-grow-1">
            <div v-if="notifications.length === 0 && !loadingState.default" class="px-3 px-lg-4">
                <p>{{ $t('notification.no-unread') }}</p>
            </div>
            <div v-else class="px-2 mt-n2">
                <OLoading :loading="loadingState.default">
                    <NuxtLink
                        v-for="notification in notifications"
                        :key="notification.id"
                        :to="notification.data.url || $route.fullPath"
                        @click.native="() => onNotificationClick(notification)"
                        class="card card-body border-0 shadow-none card-action reveal-area"
                        >
                        <div class="d-flex gap-3">
                            <div>
                                <div class="d-grid place-items-center border border-lightish rounded-circle" style="width: 2.5rem; height: 2.5rem;">
                                    <OIcon :icon="['far', notification.data.icon || 'bell']" fixed-width />
                                </div>
                            </div>
                            <div>
                                <div 
                                    class="notification-message" 
                                    :class="notification.read_at ? 'text-muted' : ''" 
                                    v-html="notification.data.message">
                                </div>
                                <div
                                    v-if="notification.data.context" 
                                    class="small text-muted border border-lightish p-2 rounded-lg mt-1 mb-2"
                                    v-html="notification.data.context"
                                    >
                                </div>
                                <div class="d-flex align-items-center gap-1 small text-muted mt-1">
                                    <span :title="$formatDateTime(notification.created_at)">{{ $formatDateDistance(notification.created_at) }}</span>
                                    <span> • </span>
                                    <span>{{ notification.data.title }}</span>
                                </div>
                            </div>
                            <OButton
                                type="link"
                                icon="check"
                                class="ml-auto reveal-item"
                                @click.stop.prevent="$store.dispatch('notification/dismiss', notification)"
                                v-tooltip="$t('notification.mark-as-read')"
                                />
                        </div>
                    
                        <Component
                            :is="types[notification.type]"
                            @dismiss="$store.dispatch('notification/dismiss', notification)"
                            :notification="notification"
                            class="mt-2" />
                    </NuxtLink>
                </OLoading>
            </div>
            <div class="portal-footer position-sticky bottom-0 mt-auto py-3 px-3 px-lg-4 d-flex justify-content-end flex-md-row-reverse gap-3">
                <OButton
                    type="secondary"
                    icon="check"
                    block
                    @click.stop="$store.dispatch('notification/dismissAll')"
                    :disabled="notifications.length === 0"
                    >
                    {{ $t('notification.mark-all-as-read') }}
                </OButton>
            </div>
        </div>
    </Portal>
</template>
<script>
import Portal from '~/components/portal.vue'
import InviteUser from './types/inviteUser.vue'
import HasLoadingState from '~/mixins/hasLoadingState'

export default {
    components: {
        Portal,
    },

    mixins: [
        HasLoadingState
    ],

    props: {
        open: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            type: 'unread',
            types: {
                'App\\Notifications\\InviteUser': InviteUser,
            }
        }
    },

    computed: {
        notifications() {
            return this.type === 'unread'
                ? this.$store.state.notification.unread
                : this.$store.state.notification.archived
        }
    },

    methods: {
        onNotificationClick(notification) {
            this.$analyticsTrack('Notification clicked', {
                type: notification.type,
            })
            
            this.$store.dispatch('notification/dismiss', notification);
            this.$emit('close');
        }
    },

    watch: {
        async type(value) {
            this.$startLoading()
            value === 'unread'
                ? await this.$store.dispatch('notification/fetch')
                : await this.$store.dispatch('notification/fetchArchived')

            this.$stopLoading()
        }
    }
}
</script>
<style>
.notification-message span {
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: var(--lightish);
}
</style>