import Storage from '~/util/storage'
import { track as analyticsTrack } from '~/plugins/analytics'

export const getters = {
    user(state, getters, rootState) {
        return rootState.auth.user
    },

    fullName(state, getters, rootState) {
        return `${rootState.auth.user.firstname} ${rootState.auth.user.lastname}`
    },

    hasPermissionTo: (state, getters, rootState) => permission => {
        return rootState.auth.user
            && rootState.auth.user.current_organization
            && rootState.auth.user.current_organization.permissions.includes(permission)
    },
}

export const actions = {
    async forgotPassword({ commit }, email) {
        return await this.$axios.$post('forgot-password', { email })
    },

    async resetPassword({ commit }, data) {
        return await this.$axios.$post('reset-password', data)
    },

    getUser({ commit }) {
        return this.$axios.$get('user').then(data => {
            this.$auth.setUser(data)
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                this.$auth.logout()
                window.location.reload()
            }
        })
    },

    update({ commit }, data) {
        return this.$axios.$patch('user', data).then(data => {
            this.$auth.setUser(data)
        })
    },

    changeOrganization({ commit, dispatch }, organization) {
        this.$leaveChannels(this.$auth)

        return this.$axios.$get('user/change-organization/' + organization.id).then(data => {
            this.$auth.setUser(data)

            dispatch('organization/fetch', data.current_organization.id, { root: true })
            dispatch('toast/push', { 
                title: this.app.i18n.t('organization.changed'),
                message: this.app.i18n.t('organization.changed-text',  { name: data.current_organization.name }),
                type: 'info',
                icon: 'exchange',
            }, { root: true })

            Storage.remove('time-items')
            Storage.remove('expense-items')
            Storage.remove('invoice-items')
            Storage.remove('offer-items')
            Storage.remove('product-items')
            Storage.remove('rate-items')
            Storage.remove('team-items')
            Storage.remove('company-items')
            Storage.remove('last-used-rate')

            analyticsTrack('Organization changed')

            window.location.href = '/'
        })
    },

    leaveOrganization({ commit, dispatch }, organization) {
        return this.$axios.$get('user/leave-organization/' + organization.id).then(data => {
            window.location.href = '/'
        })
    },

    uploadAvatar({ commit, dispatch }, { file, onProgress = null }) {
        return dispatch('media/upload', { file, endpoint: 'user/avatar', onProgress }, { root: true }).then(data => {
            this.$auth.setUser(data)
        })
    },

    resendEmailVerification({ dispatch, state }) {
        return this.$axios.$post('user/resend-verification').then(() => {
            dispatch('toast/push', {
                title: this.app.i18n.t('user.verification-sent'),
                message: this.app.i18n.t('user.verification-sent-text', { email: this.$auth.user.email }),
                type: 'success'
            }, { root: true })
        })
    },
}
